import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";

import { TableRow, TableCell, Tooltip, Typography, Box, useMediaQuery } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";

import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
import FormatedNumber from "../../Common/Formater/FormatedNumber";
import TokenInfos from "../../Common/TokenInfos";

function TableTokenValuationRow({ token }) {
	const { themeContext } = useContext(MyThemeContext);
	const [openInfo, setOpenInfo] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const handleTokenClick = (marketplaceLink) => {
		window.open(marketplaceLink, "_blank");
	};

	// if(token.shortName==="18983 Alcoy")
	// console.log("TableTokenValuationRow", token);

	return (
		<>
			{openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={token} />}
			<TableRow key={token.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
				<TableCell
					align='center'
					sx={{ pl: 0, pr: 0, py: 0.5, cursor: "pointer" }}
					component='th'
					scope='row'
					onClick={() => handleTokenClick(token.marketplaceLink)}
				>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<OpenInNewIcon color={"primary"} />
					</Box>
				</TableCell>
				<TableCell align='center' sx={{ pl: 0, pr: 0, py: 0, cursor: "pointer" }} component='th' scope='row' onClick={() => setOpenInfo(true)}>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
						<AddchartOutlinedIcon color={"primary"} />
					</Box>
				</TableCell>
				<TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Tooltip title={token.fullName}>
						<Typography variant='body2'>{token.shortName}</Typography>
					</Tooltip>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.totalInvestment} color={"primary.main"}  digit={0}/>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.init_price} color={"primary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.init_yield / 100} color={"primary.main"} />
				</TableCell>
				{!isMatch && (
					<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
						<Typography variant='body2'>{token.strInitialLaunchDate}</Typography>
					</TableCell>
				)}
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Typography variant='body2'>{token.strRentStartDate}</Typography>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.tokenPrice} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.annualPercentageYield / 100} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "right" }}>
						<FormatedNumber value={token.rentedUnits} color={"primary.main"}  digit={0}/>/<FormatedNumber value={token.totalUnits} color={"primary.main"}  digit={0}/>
					</Box>
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.valuation} color={"primary.dark"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPrice value={token.rentEarned} color={"primary.dark"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.roc} color={"secondary.main"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.newYield / 100} color={"primary.dark"} />
				</TableCell>
				<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
					<FormatedPercentage value={token.diffYield / 100} color={"secondary.main"} />
				</TableCell>
			</TableRow>
		</>
	);
}

export default TableTokenValuationRow;
