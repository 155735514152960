import React from "react";
import { Typography } from "@mui/material";

const FormatedNumberDigit = ({ value, color = "inherit", variant = "body2", fontWeight = "normal", digit = 2 }) => {
	// const userLocale = navigator.language; // const userLocales = navigator.languages;

	let formatedNumber =
		value !== null
			? !isNaN(value)
				? value.toLocaleString(undefined, { minimumFractionDigits: digit, maximumFractionDigits: digit })
				: "NaN"
			: "N/A";


	return (
		<Typography variant={variant} sx={{ color: color }} style={{ fontWeight: fontWeight }}>
			{formatedNumber}
		</Typography>
	);
};

export default FormatedNumberDigit;
