import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, TextField, Typography } from "@mui/material";

const CONVERSION = 1;

const TokenAmountShort = ({ value, onChange, min, max, step, title }) => {
	const handleChange = (event) => {
		onChange(event);
	};
	const [localTimer, setLocalTimer] = useState(0);

	const handle_setTimeoutValue = (value) => {
		let val = value;
		if (val < min) val = min;
		if (val > max) val = max;
		setLocalTimer(val);
		handleChange(val * CONVERSION);
	};

	useEffect(() => {
		let val = value / CONVERSION;
		if (val < min) val = min;
		if (val > max) val = max;
		setLocalTimer(val);
	}, [max, min, value]);

	return (
		<Box sx={{ m: 0,p:0 }}>

			<TextField
				sx={{ mt: 1 }}
				label={title}
				onChange={(e) => handle_setTimeoutValue(e.target.value)}
				variant='outlined'
				type='number'
				size='small'
				value={localTimer}
				onFocus={(e) => e.target.select()}
				inputProps={{
					min: min,
					max: max,
					step: step,
					style: {
						textAlign: "center",
					},
				}}
			/>
		</Box>
	);
};

TokenAmountShort.propTypes = {
	value: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
};

export default TokenAmountShort;
