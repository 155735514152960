import React, { createContext, useContext, useState } from "react";
import { createTheme } from "@mui/material/styles";
import { SettingsContext } from "./settings-context";

export const MyThemeContext = createContext();

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
	typography: {
		h5: {
			fontSize: "1.3rem",
		},
		h6: {
			fontSize: "1rem",
		},
	},
	components: {
		MuiSelect: {
			styleOverrides: {
				select: {
					padding: "5px 6px",
				},
			},
		},
	},
});

const lightTheme = createTheme({
	palette: {
		mode: "light",
	},
	typography: {
		h5: {
			fontSize: "1.3rem",
		},
		h6: {
			fontSize: "1rem",
		},
	},
	components: {
		MuiSelect: {
			styleOverrides: {
				select: {
					padding: "5px 6px",
				},
			},
		},
	},
});

export const MyThemeProvider = (props) => {
	const { themeState, setThemeState } = useContext(SettingsContext);
	// const [themeState, setThemeState] = useState(false); //localStorage.getItem("pitswap_ThemeContext") ||
	const [showHeader, setShowHeader] = useState(true);

	const [loading, setLoading] = useState(false);

	const themeContext = themeState ? darkTheme : lightTheme;

	// console.log("themeContext", themeContext);

	const value = {
		themeState,
		themeContext,
		setThemeState,
		showHeader,
		setShowHeader,
		loading,
		setLoading,
	};

	return <MyThemeContext.Provider value={value}>{props.children}</MyThemeContext.Provider>;
};
