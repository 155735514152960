import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SettingsContext } from "../../../context/settings-context";

import { Box, MenuItem, Select, Typography } from "@mui/material";

const SelectPropertyType = ({ value, onChange, title }) => {
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const handleChange = (event) => {
		onChange(event);
	};
	const [localSelect, setLocalSelect] = useState(0);

	const handle_setLocalSelect = (value) => {
		setLocalSelect(value);
		handleChange(value);
	};

	useEffect(() => {
		let val = value;
		setLocalSelect(val);
	}, [value]);

	return (
		<Box sx={{ mx: 1, p: 0, minWidth: 600, border: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
			<Typography variant='body2' sx={{ mr: "auto" ,display:'flex', alignItems:'center', justifyContent:'center'}}  >
				{title}
			</Typography>

			<Select sx={{ mt: 0, ml: 0, minWidth: 125 }} value={localSelect} onChange={(e) => handle_setLocalSelect(e.target.value)} size='small'>
				{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
					<MenuItem key={p.index} value={p.index}>
						{p.label}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

SelectPropertyType.propTypes = {
	value: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
};

export default SelectPropertyType;
