import React, { useEffect, useState } from "react";

import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Typography } from "@mui/material";

import UndoIcon from "@mui/icons-material/Undo";
import TablePropertyPriceTokensMapRow from "./TablePropertyPriceTokensMapRow";
import { Box } from "@mui/system";

function TablePropertyPriceTokensMap({ tokensMap, title, menuChange, menuBackChange }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		if (tokensMap.length < page * rowsPerPage) {
			setPage(0);
		}
	}, [tokensMap]);

	// console.log("title", title ,"tokensMap",tokensMap);

	if (!tokensMap) return <></>;
	return (
		<Paper elevation={6} sx={{ p: 1, minWidth: 100, border: menuChange ? 0 : 3, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				{menuChange && (
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component='div'
						count={tokensMap.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 100 }} aria-label='simple table'>
						<TableHead>
							{!menuChange && (
								<TableRow>
									<TableCell
										align='center'
										sx={{ pl: 1, pr: 1, py: 1, cursor: menuBackChange ? "pointer" : "" }}
										onClick={() => (menuBackChange ? menuBackChange(null) : null)}
									>
										{menuBackChange && <UndoIcon />}
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										{title ? title.label : "???"}
									</TableCell>
								</TableRow>
							)}
							{menuChange && (
								<TableRow>
									<TableCell
										align='center'
										sx={{ pl: 1, pr: 1, py: 1, cursor: menuBackChange ? "pointer" : "" }}
										onClick={() => (menuBackChange ? menuBackChange(null) : null)}
									>
										{menuBackChange && <UndoIcon />}
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										{title ? title.label : "???"}
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										Nb
									</TableCell>
									<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
										Minimum Price
									</TableCell>
									<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
										Maximum Price
									</TableCell>
									<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
										Average Price
									</TableCell>
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{tokensMap.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token) => (
								<TablePropertyPriceTokensMapRow token={token} menuChange={menuChange} menuBackChange={menuBackChange} key={token.label} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	);
}

export default TablePropertyPriceTokensMap;
