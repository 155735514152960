import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MtPelerin from "./Referers/MtPelerin";
import BienPreter from "./Referers/BienPreter";
import RentTracker from "./Referers/RentTracker";

const AdPopupReferer = () => {
  const [visible, setVisible] = useState(false);
  const [selectedReferer, setSelectedReferer] = useState(null); // State to hold the randomly selected referer
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Function to show the popup randomly
    const showPopup = () => {
      setVisible(true);
      selectRandomReferer(); // Select a random referer each time the popup is shown
      setTimeout(() => {
        setVisible(false);
        // Reschedule the popup after another random interval
        schedulePopup();
      }, 7500); // Popup stays visible for 7.5 seconds
    };

    // Function to randomly select the referer to display
    const selectRandomReferer = () => {
      const referers = [<MtPelerin key="MtPelerin" />, <BienPreter key="BienPreter" />, <RentTracker key="RentTracker" />];
      const randomIndex = Math.floor(Math.random() * referers.length);
      setSelectedReferer(referers[randomIndex]);
    };

    // Function to schedule the popup appearance randomly
    const schedulePopup = () => {
      const randomDelay = Math.floor(Math.random() * (15 - 2) + 2) * 60 * 1000; // Random interval between 2 and 5 minutes
      timeoutRef.current = setTimeout(showPopup, randomDelay);
    };

    // Schedule the first popup
    schedulePopup();

    // Cleanup when the component unmounts
    return () => clearTimeout(timeoutRef.current);
  }, []);

  // Styles for the popup animation
  const popupStyle = {
    position: "fixed",
    bottom: visible ? "20px" : "-200px",
    right: "20px",
    width: "300px",
    backgroundColor: "#f8f9fa",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    transform: visible ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.5s ease, bottom 0.5s ease",
    zIndex: 1000,
  };

  return (
    <Box sx={popupStyle}>
      {selectedReferer} {/* Render the randomly selected referer */}
    </Box>
  );
};

export default AdPopupReferer;
