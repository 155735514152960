import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Button, Typography } from "@mui/material";
import MinutesTimer from "../Common/MinutesTimer";
// import SecondsTimer from "./SecondsTimer";
import HoursTimer from "../Common/HoursTimer";
import DaysTimer from "../Common/DaysTimer";

function SettingsApiCoinGecko() {
	const { settingsApiCoinGecko, setSettingsApiCoinGecko } = useContext(SettingsContext);

	if (!settingsApiCoinGecko) return <></>;

	const timerUpdateCoinsConvertionChange = (newValue) => {
		setSettingsApiCoinGecko((prevSettings) => ({
			...prevSettings,
			timerUpdateCoinsConvertion: newValue,
		}));
	};
	const timerUpdateCoinsBalanceChange = (newValue) => {
		setSettingsApiCoinGecko((prevSettings) => ({
			...prevSettings,
			timerUpdateCoinsBalance: newValue,
		}));
	};
	const timerHistoryzeCoinsBalanceChange = (newValue) => {
		setSettingsApiCoinGecko((prevSettings) => ({
			...prevSettings,
			timerHistoryzeCoinsBalance: newValue,
		}));
	};
	const timerDeleteCoinsBalanceChange = (newValue) => {
		setSettingsApiCoinGecko((prevSettings) => ({
			...prevSettings,
			timerDeleteCoinsBalance: newValue,
		}));
	};

	return (
		<Box
			sx={{m:1,
				p: 1,
				border: 1,
				borderColor: "primary.main",
				borderRadius: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant='h4' sx={{ my: 1, px: 2, color: "primary.main" }}>
				Manage Token Balance and Conversion Settings
			</Typography>

			<Box
				sx={{
					mx: 1,
					p: 0,
					// minWidth: 350,
					border: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<MinutesTimer
					value={settingsApiCoinGecko.timerUpdateCoinsConvertion}
					onChange={(newValue) => timerUpdateCoinsConvertionChange(newValue)}
					min={15}
					max={120}
					step={1}
					title='Refresh token conversion to USD every'
				/>
				<MinutesTimer
					value={settingsApiCoinGecko.timerUpdateCoinsBalance}
					onChange={(newValue) => timerUpdateCoinsBalanceChange(newValue)}
					min={15}
					max={120}
					step={1}
					title='Refresh token balance every'
				/>
				<HoursTimer
					value={settingsApiCoinGecko.timerHistoryzeCoinsBalance}
					onChange={(newValue) => timerHistoryzeCoinsBalanceChange(newValue)}
					min={2}
					max={24}
					step={1}
					title='Archive token balance every'
				/>
				<DaysTimer
					value={settingsApiCoinGecko.timerDeleteCoinsBalance}
					onChange={(newValue) => timerDeleteCoinsBalanceChange(newValue)}
					min={0}
					max={365}
					step={1}
					title='Delete archive token past'
				/>
			</Box>
		</Box>
	);
}

export default SettingsApiCoinGecko;
