import React, { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "../context/settings-context";
import { useTheme } from "@mui/system";

import Header from "../components/Header/Header";
import LoadingWebSite from "../components/Common/LoadingWebSite";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import SavingsIcon from "@mui/icons-material/Savings";
import BusinessIcon from "@mui/icons-material/Business";

import { blue, grey } from "@mui/material/colors";
import { Avatar, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import InsightsIcon from "@mui/icons-material/Insights";

const TITLE = "Pit's BI - About";

function About() {
	const { settingsLoaded } = useContext(SettingsContext);
	const { FRONT_END_NAME, FRONT_END_VERSION } = useContext(SettingsContext);
	const theme = useTheme();
	const [localStorageUsed, setLocalStorageUsed] = useState(0);
	const [sessionStorageUsed, setSessionStorageUsed] = useState(0);
	const [openLogo, setOpenLogo] = useState(false);
	const [openRent, setOpenRent] = useState(false);
	const anchorRefLogo = useRef(null);
	const anchorRefRent = useRef(null);

	const referalURL_realT = "https://realt.co/ref/Frederic%20Pitot/";
	const referalURL_BienPreter = "https://www.bienpreter.com/parrain/U61F6DDA125AA3";
	const referalURL_MtPelerin = "https://mtpelerin.com/fr/join?rfr=CvZZWr53";
	const referalURL_Community = "https://www.cryptalloc.com/realtlab/index.php?MODL=LINK";
	const referalURL_RentTracker = "https://ehpst.duckdns.org/realt_rent_tracker/";

	const RealTLogo = theme.palette.mode === "dark" ? "/images/RealT_Logo_dark.svg" : "/images/RealT_Logo.svg";
	const BienPreterLogo = theme.palette.mode === "dark" ? "/images/bienpreter_dark.png" : "/images/bienpreter.png";
	const MtPelerinLogo = theme.palette.mode === "dark" ? "/images/mtpelerin.svg" : "/images/mtpelerin.svg";
	const CommunityLogo = theme.palette.mode === "dark" ? "/images/realtcommunity_dark.png" : "/images/realtcommunity.png";
	const RentTrackerLogo = theme.palette.mode === "dark" ? "/images/renttraker_dark.png" : "/images/renttraker.png";

	const handleReferalURLClick = (url) => {
		window.open(url, "_blank");
	};

	useEffect(() => {
		document.title = TITLE;
	}, []);

	useEffect(() => {
		const getStorageUsage = () => {
			const localStorageBytes = JSON.stringify(localStorage).length;
			const sessionStorageBytes = JSON.stringify(sessionStorage).length;
			const localStorageTotal = 5 * 1024 * 1024; // 5 MB by default
			const sessionStorageTotal = 5 * 1024 * 1024; // 5 MB by default
			setLocalStorageUsed(localStorageTotal - localStorageBytes);
			setSessionStorageUsed(sessionStorageTotal - sessionStorageBytes);
		};

		// console.log(window.sessionStorage)

		getStorageUsage();

		// Add event listeners to update storage usage if storage changes
		window.addEventListener("storage", getStorageUsage);

		// Cleanup by removing event listeners
		return () => {
			window.removeEventListener("storage", getStorageUsage);
		};
	}, []);

	return (
		<Box>
			<Header />
			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box>
					{/* Introduction */}
					<Box>
						<Box sx={{ p: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
							<Box sx={{ pt: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
								<Typography variant='h3' sx={{ mx: 5 }} color={blue[700]}>
									{FRONT_END_NAME}
								</Typography>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
							</Box>
							<Typography variant='h6' sx={{ m: 0 }} color={blue[700]}>
								version {FRONT_END_VERSION}
							</Typography>
							<Typography variant='body1' align='center' sx={{ p: 1 }} color={blue[700]}>
								<strong>{FRONT_END_NAME}</strong> is a Business Intelligence platform specialized in optimizing the use of RealT, providing an
								enhanced experience compared to <strong>PitSwap</strong>, <br />
								with powerful tools for data collection, analysis, and visualization, all while ensuring security and flexibility.
							</Typography>
							<Typography variant='body2' sx={{ mt: 0 }} style={{ fontStyle: "italic" }} color={grey[500]}>
								LocalStorage free: {localStorageUsed.toLocaleString()} bytes - sessionStorage free: {sessionStorageUsed.toLocaleString()} bytes
							</Typography>
						</Box>
					</Box>

					{/* Referrals */}
					<Box>
						<Box sx={{ mt: 3, p: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
							<Box sx={{ pt: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
								<Box sx={{ pt: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
									<Typography variant='h3' sx={{ mx: 5 }} color={blue[700]}>
										Pit's BI Referrals
									</Typography>
									<Typography variant='h6' sx={{ mx: 5 }} color={blue[700]}>
										Other investment platforms and partners website
									</Typography>
								</Box>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
							</Box>

							<Box sx={{ pt: 1, display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
								<IconButton onClick={() => handleReferalURLClick(referalURL_RentTracker)}>
									<Avatar
										alt={"RentTrackerLogo"}
										src={RentTrackerLogo}
										fontSize='small'
										sx={{ width: 200, height: 80 }}
										imgProps={{ style: { objectFit: "contain", height: "50%", width: "auto" } }}
									/>
								</IconButton>
								<IconButton onClick={() => handleReferalURLClick(referalURL_BienPreter)}>
									<Avatar
										alt={"BienPreterLogo"}
										src={BienPreterLogo}
										fontSize='small'
										sx={{ width: 200, height: 80 }}
										imgProps={{ style: { objectFit: "contain", height: "50%", width: "auto" } }}
									/>
								</IconButton>
								<IconButton onClick={() => handleReferalURLClick(referalURL_realT)}>
									<Avatar
										alt={"RealTLogo"}
										src={RealTLogo}
										fontSize='small'
										sx={{ width: 200, height: 80 }}
										imgProps={{ style: { objectFit: "contain", height: "95%", width: "auto" } }}
									/>
								</IconButton>
								<IconButton onClick={() => handleReferalURLClick(referalURL_MtPelerin)}>
									<Avatar
										alt={"MtPelerinLogo"}
										src={MtPelerinLogo}
										fontSize='small'
										sx={{ width: 200, height: 80 }}
										imgProps={{ style: { objectFit: "contain", height: "65%", width: "auto" } }}
									/>
								</IconButton>
								<IconButton onClick={() => handleReferalURLClick(referalURL_Community)}>
									<Avatar
										alt={"CommunityLogo"}
										src={CommunityLogo}
										fontSize='small'
										sx={{ width: 200, height: 80 }}
										imgProps={{ style: { objectFit: "contain", height: "80%", width: "auto" } }}
									/>
								</IconButton>
							</Box>
						</Box>
					</Box>

					{/* Version */}
					<Box>
						<Box sx={{ mt: 3, p: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", border: 0 }}>
							<Box sx={{ pt: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
								<Typography variant='h3' color='inherit' sx={{ mx: 5 }} color={blue[700]}>
									Tracking website deliveries
								</Typography>
								<Avatar alt='Logo' src='/logo-pitsbi2.png' sx={{ ml: 1, width: 30, height: 40 }} />
							</Box>
							<Paper elevation={3} sx={{ m: 1, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
								<TableContainer>
									<Table
										sx={{
											minWidth: 600,
											maxWidth: 900,
											background: "background.paper",
											"& thead th": { borderWidth: 1 },
											"& tbody tr:last-child td": { borderBottom: 0 },
										}}
										aria-label='sticky table'
									>
										<TableHead>
											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 1 }}>
													<Typography variant='body1' color='inherit' style={{ fontWeight: "bold" }}>
														Version
													</Typography>
												</TableCell>
												<TableCell align='center' sx={{ p: 0 }}>
													<Typography variant='body1' color='inherit' style={{ fontWeight: "bold" }}>
														Description
													</Typography>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody sx={{ mt: 0 }}>
											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.9.2 beta
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														<strong>Dashboard view</strong> :
														<ul>
															<li>
																Added RWA Tokens and Lend & Borrow RMM Tokens (currently only buttons, features coming soon)
																<ul>
																	<li>
																		<strong>Add/Remove RMM Tokens</strong>: <SavingsIcon sx={{ mx: 1, color: "primary.main" }} /> Click to manage RMM
																		tokens in your asset portfolio
																	</li>
																	<li>
																		<strong>Add/Remove RWA Tokens</strong>: <BusinessIcon sx={{ mx: 1, color: "primary.main" }} /> Click to manage RWA
																		tokens in your asset portfolio
																	</li>
																</ul>
															</li>
														</ul>
														<strong>Market view</strong> : Add Web3 interraction, Wallets can be connected to the App (mobile functionality untested)
														<ul>
															<li>
																Added Web3 interaction, wallets can now be connected to the app (mobile functionality untested)
																<ul>
																	<li>
																		<strong>Purchase</strong>: Sell tokens directly on the website by connecting your wallet
																	</li>
																	<li>
																		<strong>Sales</strong>: Buy tokens directly on the website by connecting your wallet
																	</li>
																</ul>
															</li>
															<li>
																<strong>Wallet Purchase</strong>:
																<ul>																	
																<li>
																Manage the order book of tokens to buy
																</li>
															<li>
																Update the <strong><em>New Yield</em></strong> calculation for Loan realTokens
															</li>
																</ul>
															</li>
															<li>
																<strong>Wallet Sales</strong>: 
																<ul>																	
																<li>
																Manage the order book of tokens to sell
																</li>
															<li>
																Update the <strong><em>New Yield</em></strong> calculation for Loan realTokens
															</li>
																</ul>
															</li>
														</ul>
														<strong>Stats view</strong> :
														<ul>
															<li>
																update "<em>Last 6 months Marketplace Activity</em>"
																<ul>
																	<li>Added total investement per month</li>
																	<li>Added monthly token purchase counts</li>
																	<li>Added a toggle switch to display the number of tokens bought</li>
																</ul>
															</li>
															<li>
																Add "<em>Last 5 years Marketplace Activity</em>"																
															</li>
															<li>Changed library for pie charts to Chart.js</li>
														</ul>
														<strong>Settings view</strong> :
														<ul>
															<li>
																In the Wallets tab, added a setting to define the wallet name. <ul></ul>
															</li>
															<li>
																In the Wallets tab, add the holder wallets address by web3 connexion to the web site (Rabby, Metamask). <ul></ul>
															</li>
														</ul>
														<em>Standardization of links symbols</em> :
														<ul>
															<li>
																<OpenInNewIcon sx={{ mx: 1, color: "primary.main" }} /> Link to open the <strong>realTokens</strong> info on{" "}
																<strong>https:\\realt.co</strong> Website
															</li>
															<li>
																<AddchartOutlinedIcon sx={{ mx: 1, color: "primary.main" }} /> Link to open the <strong>realTokens </strong>{" "}
																statistics in a <strong>popup</strong>
															</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.9.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Upgrade the <strong>MAP view</strong> page in the website, adding 3 filters. Actualy, MAP isn't connected to holder
														wallets
														<ul>
															<li>
																FullName Filter: This filter searches through the API using the fullName property and filters the corresponding
																realTokens. A double-click on a realToken will clear the filter and zoom into the clicked
															</li>
															<li>
																PropertyType Filter: This filter allows you to select a specific propertyType to display, such as showing only single
																family realTokens
															</li>
															<li>
																RentedType Filter: This filter allows you to select a specific rentedType to display, such as showing only Vacant
																realTokens
															</li>
															<li>Combined Filtering: The PropertyType and RentedType filters can be combined for more refined searches</li>
															<li>
																Choose the legend: By clicking on the PropertyType or RentedType buttons, you can define the coloring of the markers
															</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.9.0 beta
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add the <strong>MAP</strong> page and the <strong>API Metrics</strong> views in the website
														<ul>
															<li>
																The MAP page allows users to show all the realToken localisation, actualy, MAP isn't connected to holder wallets
															</li>
															<li>The API Metrics page allows users to show all the holder localisation using my 2 website (Pitswap and Pit's BI)</li>
															<li>Soon more settings for the MAP page and a lot of filters ...</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.8.2
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														<strong>Add the Compound Interest Widget to the Dashboard</strong>
														<ul>
															<li>
																Click on the <strong>Show Insight Icon</strong>, in this position{" "}
																<InsightsIcon sx={{ mx: 1, color: "primary.main" }} /> to display the new chart.
															</li>
															<li>
																<strong>Selecting Active Wallets:</strong> When you select the active wallets, the chart will automatically update to
																display the Compound Interest for the selected wallets.
															</li>
															<li>Soon more settings ...</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.8.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														<strong>Add the Rents Collected Widget to the Dashboard</strong>
														<ul>
															<li>
																Click on the <strong>Show Insight Icon</strong>, in this position{" "}
																<InsightsIcon sx={{ mx: 1, color: "primary.main" }} /> to display the new chart.
															</li>
															<li>
																<strong>Selecting Active Wallets:</strong> When you select the active wallets, the chart will automatically update to
																display the rents collected for the selected wallets.
															</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.8.0
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add <strong>RealToken Information Views</strong> across all web pages:
														<ul>
															<li>
																<strong>Include</strong> a comprehensive view that displays detailed information for each RealToken, along with a
																financial summary covering the entire lifecycle of the RealToken.
															</li>
															<li>
																<strong>Utilize</strong> the icon on the left to open a new view featuring a financial overview of the token,
																represented through four charts:
															</li>
															<ul>
																<li>
																	<em>RealToken Price Evolution</em>
																</li>
																<li>
																	<em>Accumulated Rents (including valuation adjustments) vs Initial Price</em>
																</li>
																<li>
																	<em>RealToken Yield Evolution</em>
																</li>
																<li>
																	<em>RealTime Yield, incorporating the valuation adjustments of the RealToken</em>
																</li>
															</ul>
															<li>
																<strong>Replace</strong> the link on the left, which currently leads to the RealT website description for RealTokens,
																with the token's short name.
															</li>
															<li>
																<strong>Optimize</strong> the refresh rate of all information on the website to reduce infrastructure costs.
															</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.7.0
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Update <strong>Dashboard</strong> web page
														<ul>
															<li>Add column indicate API update since 7 or 30 days</li>
															<li>add up and down arrow when yield change in the last 30 days</li>
															<li>Add a view to list all the API change in the last 30 days</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.5
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Update <strong>Market</strong> web page
														<ul>
															<li>Add column Last Picing to know the number of days since last Token Pricing change</li>
															<li>Change default filter for purchase web page by Ratio Price</li>
															<li>
																Change color for Ratio Price column <strong>to alarm bad offers</strong> (red if under 80% and orange between 80% and
																100%)
															</li>
															<li>
																Show on icon filter if a <strong>filter is Actived</strong>
															</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.4
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add holder wallet position in all of the <strong>Leviswap Pools</strong> (Stats web page)
														<br />
														Design modification for configuring <strong>secondary market offer</strong> filters
														<br />
														<br />
														Correction of an issue in the calculation of the Rents - Yield (forgot some tokens like <strong>
															13835 La Salle
														</strong>{" "}
														because no rented units)
														<br />
														Correction of issues send by <strong>@Phil P</strong> on telegram - bad calculation of detailed Holder Costs (Stats web
														page)
														<ul>
															<li>Add Utilities Costs in detailed Holder Costs (Stats web page)</li>
															<li>Remove Miscellaneous Costs in detailed Holder Costs (Stats web page)</li>
															<li>Enhancing visibility of value on the detailed Holder Costs pie chart</li>
														</ul>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.3
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add <strong>same Avatar</strong> to enabled or diseabled filters.
														<br />
														<i>This filter helps to find the best offer sought by a holder based on their buying criteria </i>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.2
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add <strong>secondary market filter</strong>, one for Sales Offers ans an other one for Purchases Offers.
														<br />
														<i>This filter helps to find the best offer sought by a holder based on their buying criteria </i>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Correction of an issue in the number of token deposit on RMM v3, multi request to receive 100/100. Thanks to{" "}
														<strong>@Jdhas49</strong> to repport this issue.
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.6.0
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add <strong>secondary market</strong>, without pitswap filter but coming soon
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.5.0
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Adding all stats of <strong>Levinswap Pools</strong> to help community holders to find the best one to invest
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4.5
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														After exchanging messages on Telegram, a feature was added to the dashboard allowing for the adjustment of occupancy rates
														for properties held by Realtoken or by Capital Assets (identified by <strong>@Dentrig</strong>)
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4.4
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Settings values was add to the 2 Pie Chart in Dashboard page to select :
														<Box component='ul' sx={{ m: 0 }}>
															<Box component='li'>Number of Properties Type by RealTokens or Capital Assets</Box>
															<Box component='li'>Number of Properties in each city by RealTokens or Capital Assets (Chart added)</Box>
														</Box>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4.3
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														After telegram exchange, settings values was add to the 2 Pie Chart in Stats page to select :
														<Box component='ul' sx={{ m: 0 }}>
															<Box component='li'>Number of Properties Type by RealTokens or Capital Assets</Box>
															<Box component='li'>Number of Properties in each city by RealTokens or Capital Assets</Box>
														</Box>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4.2
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														After telegram exchange, calculation of assset Price change for the Stats page :
														<Box component='ul' sx={{ m: 0 }}>
															<Box component='li'>Before using totalInvestment to calculate doors, sqft or m2 Price</Box>
															<Box component='li'>Now using underlyingAssetPrice + initialMaintenanceReserve + renovationReserve</Box>
														</Box>
														<i>(A tooltips show the calculation price when mouse hover the investement Price)</i>
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add popup to show the dispatch of token amount and the yield (for pools) in the Dashboard
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.4
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add list of realTokens in Holders Wallets to the Dashboard
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.3
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add RMM v3 tokens to the Dashboard
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.2
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Add sort option in columns for Stats page (§2.3. & §3.)
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.1.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Replace 'Actual Price' by 'Current Price' in Stats - Asset Valuation (<strong>@Alexos</strong>)
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.1
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Adding stats for wallets (archive of lend and borrow RMM tokens)
														<br />
														Correction of an issue send by <strong>@xdaiadict (Reimar)</strong> on telegram - Levinswap Pool calculation
														<br />
														Correction of an issue send by <strong>@NN</strong> on telegram - Wallet configuration
													</Typography>
												</TableCell>
											</TableRow>

											<TableRow sx={{ m: 0, p: 1 }}>
												<TableCell align='center' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														0.0
													</Typography>
												</TableCell>
												<TableCell align='left' sx={{ py: 0.5 }}>
													<Typography variant='body2' color='inherit'>
														Delivery 1st version due to a leak of <strong>Remy Jacobson</strong>
													</Typography>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default About;
