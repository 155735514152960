import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Button, Typography } from "@mui/material";
import MinutesTimer from "../Common/MinutesTimer";
// import SecondsTimer from "./SecondsTimer";
import HoursTimer from "../Common/HoursTimer";
import DaysTimer from "../Common/DaysTimer";
import SelectPropertyType from "../Common/SelectPropertyType";

function SettingsStats(props) {
	const { settingsStats, setSettingsStats } = useContext(SettingsContext);

	if (!settingsStats) return <></>;

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};

	return (
		<Box
			sx={{
				m: 1,
				p: 1,
				border: 1,
				borderColor: "primary.main",
				borderRadius: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant='h4' sx={{ my: 1, px: 2, color: "primary.main" }}>
				Manage settings for Stats web page
			</Typography>

			<Box
				sx={{
					mx: 1,
					p: 0,
					// minWidth: 350,
					border: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<SelectPropertyType
					value={settingsStats.selectedPropertyType}
					onChange={(newValue) => selectedPropertyTypeChange(newValue)}
					title='Last saved value for Property Type setting'
				/>
			</Box>
		</Box>
	);
}

export default SettingsStats;
