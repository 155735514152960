import React from "react";

import { Box } from "@mui/material";

import NavigationButtons from "./HolderDashboard/NavigationButtons";

// https://ehpst.duckdns.org/realt_rent_tracker/api/rent_holder/
// Api de Steph pour les rents reçu par wallet

function HolderDashboard() {
	return (
		<Box sx={{ width: 1, p: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<NavigationButtons />
		</Box>
	);
}

export default HolderDashboard;
