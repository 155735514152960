import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, TextField, Typography } from "@mui/material";

const CONVERSION = 1000 * 60;

const MinutesTimer = ({ value, onChange, min, max, step, title }) => {
	const handleChange = (event) => {
		onChange(event);
	};
	const [localTimer, setLocalTimer] = useState(0);

	const handle_setTimeoutValue = (value) => {
		let val = value;
		if (val < min) val = min;
		if (val > max) val = max;
		setLocalTimer(val);
		handleChange(val * CONVERSION);
	};

	useEffect(() => {
		let val = value / CONVERSION;
		if (val < min) val = min;
		if (val > max) val = max;
		setLocalTimer(val);
	}, [max, min, value]);

	return (
		<Box sx={{ mx: 1, p: 0, minWidth: 600, border: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
			<Typography variant='body2' sx={{ mr: "auto" ,display:'flex', alignItems:'center', justifyContent:'center'}}  >
				{title}
			</Typography>

			<TextField
				sx={{ mt: 1, minWidth: 125 }}
				label='time in minute(s)'
				onChange={(e) => handle_setTimeoutValue(e.target.value)}
				variant='outlined'
				type='number'
				size='small'
				value={localTimer}
				inputProps={{
					min: min,
					max: max,
					step: step,
					style: {
						textAlign: "center",
					},
				}}
			/>
		</Box>
	);
};
MinutesTimer.propTypes = {
	value: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
};

export default MinutesTimer;
