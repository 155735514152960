import React from "react";

const logBuffer = [];

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//       super(props);
//       this.state = { hasError: false, error: null, errorInfo: null };
//   }

//   static getDerivedStateFromError(error) {
//       // Met à jour l'état pour afficher une interface de repli
//       return { hasError: true, error };
//   }

//   componentDidCatch(error, errorInfo) {
//       // Log l'erreur et ses détails
//       console.error('Error caught in ErrorBoundary:', error, errorInfo);
//       this.setState({ errorInfo });
//   }

//   render() {
//       if (this.state.hasError) {
//           // Affiche un message d’erreur de repli
//           return (
//               <div style={{ padding: '20px', color: 'red', background: 'lightgray' }}>
//                   <h1>Quelque chose s'est mal passé.</h1>
//                   <details style={{ whiteSpace: 'pre-wrap' }}>
//                       {this.state.error?.toString()}
//                       <br />
//                       {this.state.errorInfo?.componentStack}
//                   </details>
//               </div>
//           );
//       }
//       return this.props.children;
//   }
// }

// export default ErrorBoundary;

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// Log natif de l'erreur avec son stack trace complet
		console.error("Error caught in ErrorBoundary:", error);

		// Affiche les informations React spécifiques
		console.error("Component Stack:", errorInfo.componentStack);

		// Enregistrez ces informations dans l’état ou un outil de surveillance
		this.setState({ error, errorInfo });
	}

	render() {
		if (this.state.hasError) {
			// if (this.state.error?.includes("QuotaExceededError")) {
			// 	const localStorageBytes = JSON.stringify(localStorage).length;
			// 	const localStorageData = Object.keys(localStorage).map((key) => ({
			// 		key,
			// 		size: new Blob([localStorage.getItem(key)]).size,
			// 	}));
			// 	const totalSize = localStorageData.reduce((sum, item) => sum + item.size, 0);
			// 	return (
			// 		<div style={{ padding: "20px", color: "red", background: "lightgray" }}>
			// 			<h1>Something went wrong.</h1>
			// 			<h4>
			// 				Send a screenshoot to{" "}
			// 				<a href='https://t.me/Fredo_realT' target='_blank'>
			// 					Pit's BI Developer
			// 				</a>
			// 			</h4>
			// 			<details style={{ whiteSpace: "pre-wrap" }}>
			// 				<summary>details</summary>
			// 				<strong>Error :</strong> {this.state.error?.toString()}
			// 				<br />
			// 				<strong>LocalStorage Keys and Sizes:</strong>
			// 				<ul>
			// 					{localStorageData.map((item) => (
			// 						<li key={item.key}>
			// 							<strong>{item.key}:</strong> {item.size} bytes
			// 						</li>
			// 					))}
			// 				</ul>
			// 				<strong>Total Size:</strong> {totalSize} bytes
			// 				<br />
			// 				<strong>Stack natif :</strong> <pre>{this.state.error?.stack}</pre>
			// 				<br />
			// 				<strong>React Stack :</strong> <pre>{this.state.errorInfo?.componentStack}</pre>
			// 			</details>
			// 		</div>
			// 	);
			// }
			// else
			{
				return (
					<div style={{ padding: "20px", color: "red", background: "lightgray" }}>
						<h1>Something went wrong.</h1>
						<h4>
							Send a screenshoot to{" "}
							<a href='https://t.me/Fredo_realT' target='_blank'>
								Pit's BI Developer
							</a>
						</h4>
						<details style={{ whiteSpace: "pre-wrap" }}>
							<summary>details</summary>
							<strong>Error :</strong> {this.state.error?.toString()}
							<br />
							<strong>Stack natif :</strong> <pre>{this.state.error?.stack}</pre>
							<br />
							<strong>React Stack :</strong> <pre>{this.state.errorInfo?.componentStack}</pre>
						</details>
					</div>
				);
			}
		}
		// else {
		//     const localStorageBytes = JSON.stringify(localStorage).length;
		//     const localStorageData = Object.keys(localStorage).map((key) => ({
		//         key,
		//         size: new Blob([localStorage.getItem(key)]).size,
		//     }));
		//     const totalSize = localStorageData.reduce((sum, item) => sum + item.size, 0);
		//     return (
		//         <div style={{ padding: "20px", color: "red", background: "lightgray" }}>
		//             <h1>Something went wrong.</h1>
		//             <h4>
		//                 Send a screenshoot to{" "}
		//                 <a href='https://t.me/Fredo_realT' target='_blank'>
		//                     Pit's BI Developer
		//                 </a>
		//             </h4>
		//             <details style={{ whiteSpace: "pre-wrap" }}>
		//                 <summary>details</summary>
		//                 <strong>Error :</strong> {this.state.error?.toString()}
		//                 <br />
		//                 <strong>LocalStorage Keys and Sizes:</strong>
		//                 <ul>
		//                     {localStorageData.map((item) => (
		//                         <li key={item.key}>
		//                             <strong>{item.key}:</strong> {item.size} bytes
		//                         </li>
		//                     ))}
		//                 </ul>
		//                 <strong>Total Size:</strong> {totalSize} bytes
		//                 <br />
		//                 <strong>Stack natif :</strong> <pre>{this.state.error?.stack}</pre>
		//                 <br />
		//                 <strong>React Stack :</strong> <pre>{this.state.errorInfo?.componentStack}</pre>
		//             </details>
		//         </div>
		//     );
		// }

		return this.props.children;
	}
}

export default ErrorBoundary;
