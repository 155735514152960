import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";

import { Box, Typography, Checkbox, Button, Select, Menu, MenuItem, TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
// import WalletsMapView from "./WalletsMapView";
import VisitorsMapView from "./VisitorsMapView";

const TITLE = "Pit's BI - Map";

function generateColoredIconUrl(color) {
	const baseUrl = "./images/maps"; // Base URL de l'icône
	return `${baseUrl}/${color}.png`;
}

function MenuMapView() {
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { settingsWallet, settingsMap, setSettingsMap, balanceWallets, holderWallets, setHolderWallets } = useContext(SettingsContext);
	const [anchorEl, setAnchorEl] = useState(null);

	// console.log("settingsMap", settingsMap);

	const propertyColors = settingsMap.propertyColors;
	const rentedColors = settingsMap.rentedColors;
	const ownedColors = settingsMap.ownedColors;
	const newsColors = settingsMap.newsColors;

	const settingsMapChange = (item, newValue) => {
		// console.log("settingsMapChange",item, newValue)
		setSettingsMap((prevSettings) => ({
			...prevSettings,
			[item]: newValue,
		}));
	};

	const handleCheckboxChange = (index) => {
		const newHolderWallets = [...holderWallets];
		newHolderWallets[index].checked = !newHolderWallets[index].checked;
		setHolderWallets(newHolderWallets);
	};

	useEffect(() => {
		document.title = TITLE;
		if (settingsMap)
			if (!settingsMap.filterByOwnedType && !settingsMap.filterByPropertyType && !settingsMap.filterByRentedType && !settingsMap.filterByNewsType) {
				settingsMapChange("filterByPropertyType", true);
				settingsMapChange("filterByOwnedType", false);
				settingsMapChange("filterByRentedType", false);
				settingsMapChange("filterByNewsType", false);
			}
	}, [settingsMap]);

	const handleClick = (event) => {
		console.log("event", event);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// console.log("holderWallets", holderWallets);

	return (
		<>
			<Box sx={{ height: "calc(100vh - 64px)", display: "flex", flexDirection: "column", border: 0 }}>
				<Box //Box pour faire un bandeau de commande en haut de la MAP
					sx={{
						p: 0.5,
						// minHeight: "57px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%", // Prend toute la largeur de la fenêtre
						border: 0,
					}}
				>
					{/* TextField : Wallets List */}
					{holderWallets && (
						<Box sx={{ ml: 0, display: "flex", flexDirection: "row" }}>
							<Button
								sx={{ ml: 1, width: 120 }}
								variant={holderWallets.some((w) => w.checked) ? "contained" : "outlined"} // Contained if there is at least one checked wallet, otherwise outlined
								onClick={(e) => {
									handleClick(e);
								}}
							>
								{holderWallets
									? (() => {
											const checkedWallets = holderWallets.filter((w) => w.checked);
											const walletCount = checkedWallets.length;

											if (walletCount > 0) {
												return walletCount > 1 ? `${walletCount} Wallets` : `${walletCount} Wallet`;
											} else {
												return "No Wallet";
											}
									  })()
									: "No Wallet"}
							</Button>

							<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
								{holderWallets.map((p, index) => (
									<MenuItem key={p.address} value={p.address}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
											<>
												<Checkbox
													sx={{
														width: 20,
														height: 20,
													}}
													color='primary'
													checked={p.checked}
													onChange={(e) => {
														handleCheckboxChange(index);
														// handleClose(e);
													}}
												/>
												<Box sx={{ ml: 0.5 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.name ? p.name : p.address.slice(0, 4) + "..." + p.address.slice(-4)}
													</Typography>
												</Box>
											</>
										</Box>
									</MenuItem>
								))}
							</Menu>
						</Box>
					)}

					{/* TextField : Find by API fullName */}
					<Box sx={{ ml: 2, display: "flex", flexDirection: "row" }}>
						<TextField
							sx={{ ml: 1, minWidth: 200 }}
							label='Search in API fullName'
							onChange={(e) => settingsMapChange("searchFullName", e.target.value)}
							variant='outlined'
							type='text'
							size='small'
							value={settingsMap.searchFullName ? settingsMap.searchFullName : ""}
							InputProps={{
								endAdornment: settingsMap.searchFullName ? (
									<IconButton onClick={() => settingsMapChange("searchFullName", "")}>
										<ClearIcon />
									</IconButton>
								) : null,
								style: {
									textAlign: "center",
									verticalAlign: "center",
									padding: "0px 0px",
								},
							}}
						/>
					</Box>

					<Box //Box pour faire un bandeau de commande en haut de la MAP
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%", // Prend toute la largeur de la fenêtre
							flexWrap: "wrap", // Permet aux éléments de passer à la ligne
							overflow: "auto", // Ajoute une barre de défilement si nécessaire
						}}
					>
						{/* TextField : Find by Wallets */}
						{holderWallets && (
							<Box sx={{ ml: 5, mt: 0.5, display: "flex", flexDirection: "row" }}>
								<Button
									variant={settingsMap.filterByOwnedType ? "contained" : "outlined"}
									onClick={() => {
										settingsMapChange("filterByOwnedType", !settingsMap.filterByOwnedType);
										settingsMapChange("filterByPropertyType", false);
										settingsMapChange("filterByRentedType", false);
										settingsMapChange("filterByNewsType", false);
									}}
									sx={{
										ml: "auto",
									}}
								>
									Wallets
								</Button>
								<Select
									sx={{ mt: 0, ml: 1, minWidth: 125 }}
									value={settingsMap.selectedOwnedType ? settingsMap.selectedOwnedType : 0}
									onChange={(e) => {
										settingsMapChange("selectedOwnedType", e.target.value);
									}}
									size='small'
								>
									{ownedColors.map((p, index) => (
										<MenuItem key={p.index} value={p.index}>
											<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row" }}>
												{p.index === 0 ? (
													<Box sx={{ ml: 1 }}>
														<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
															{p.label}
														</Typography>
													</Box>
												) : (
													<>
														<Box component='img' alt='imgToken' src={p.img} sx={{ width: 20, height: 20 }} />
														<Box sx={{ ml: 1 }}>
															<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
																{p.label}
															</Typography>
														</Box>
													</>
												)}
											</Box>
										</MenuItem>
									))}
								</Select>
							</Box>
						)}

						{/* TextField : Find by API PropertyType */}
						<Box sx={{ ml: 5, mt: 0.5, display: "flex", flexDirection: "row" }}>
							<Button
								variant={settingsMap.filterByPropertyType ? "contained" : "outlined"}
								onClick={() => {
									settingsMapChange("filterByPropertyType", !settingsMap.filterByPropertyType);
									settingsMapChange("filterByOwnedType", false);
									settingsMapChange("filterByRentedType", false);
									settingsMapChange("filterByNewsType", false);
								}}
								sx={{
									ml: "auto",
								}}
							>
								Property
							</Button>
							<Select
								sx={{ mt: 0, ml: 1, minWidth: 125 }}
								value={settingsMap.selectedPropertyType ? settingsMap.selectedPropertyType : 0}
								onChange={(e) => {
									settingsMapChange("selectedPropertyType", e.target.value);
								}}
								size='small'
							>
								{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
									<MenuItem key={p.index} value={p.index}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row" }}>
											{p.index === 0 ? (
												<Box sx={{ ml: 1 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label}
													</Typography>
												</Box>
											) : (
												<>
													<Box component='img' alt='imgToken' src={generateColoredIconUrl(propertyColors[p.index])} sx={{ width: 20, height: 20 }} />
													<Box sx={{ ml: 1 }}>
														<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
															{p.label}
														</Typography>
													</Box>
												</>
											)}
										</Box>
									</MenuItem>
								))}
							</Select>
						</Box>

						{/* TextField : Find by API RentedType */}
						<Box sx={{ ml: 5, mt: 0.5, display: "flex", flexDirection: "row" }}>
							<Button
								variant={settingsMap.filterByRentedType ? "contained" : "outlined"}
								onClick={() => {
									settingsMapChange("filterByRentedType", !settingsMap.filterByRentedType);
									settingsMapChange("filterByOwnedType", false);
									settingsMapChange("filterByPropertyType", false);
									settingsMapChange("filterByNewsType", false);
								}}
								sx={{
									ml: "auto",
								}}
							>
								Rented
							</Button>
							{/* <Typography variant='h6' sx={{ m: 1, ml: 0, color: "primary.main" }}>
	Rented Type
</Typography> */}
							<Select
								sx={{ mt: 0, ml: 1, minWidth: 125 }}
								value={settingsMap.selectedRentedType ? settingsMap.selectedRentedType : 0}
								onChange={(e) => {
									settingsMapChange("selectedRentedType", e.target.value);
								}}
								size='small'
							>
								{rentedColors.map((p, index) => (
									<MenuItem key={p.index} value={p.index}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row" }}>
											{p.index === 0 ? (
												<Box sx={{ ml: 1 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label}
													</Typography>
												</Box>
											) : (
												<>
													<Box component='img' alt='imgToken' src={p.img} sx={{ width: 20, height: 20 }} />
													<Box sx={{ ml: 1 }}>
														<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
															{p.label}
														</Typography>
													</Box>
												</>
											)}
										</Box>
									</MenuItem>
								))}
							</Select>
						</Box>

						{/* TextField : Find by API NewsType */}
						<Box sx={{ ml: 5, mt: 0.5, display: "flex", flexDirection: "row" }}>
							<Button
								variant={settingsMap.filterByNewsType ? "contained" : "outlined"}
								onClick={() => {
									settingsMapChange("filterByNewsType", !settingsMap.filterByNewsType);
									settingsMapChange("filterByRentedType", false);
									settingsMapChange("filterByOwnedType", false);
									settingsMapChange("filterByPropertyType", false);
								}}
								sx={{
									ml: "auto",
								}}
							>
								News
							</Button>
							<Select
								sx={{ mt: 0, ml: 1, minWidth: 125 }}
								value={settingsMap.selectedNewsType ? settingsMap.selectedNewsType : 0}
								onChange={(e) => {
									settingsMapChange("selectedNewsType", e.target.value);
								}}
								size='small'
							>
								{newsColors.map((p, index) => (
									<MenuItem key={p.index} value={p.index}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row" }}>
											{p.index === 0 ? (
												<Box sx={{ ml: 1 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label}
													</Typography>
												</Box>
											) : (
												<>
													<Box component='img' alt='imgToken' src={p.img} sx={{ width: 20, height: 20 }} />
													<Box sx={{ ml: 1 }}>
														<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
															{p.label}
														</Typography>
													</Box>
												</>
											)}
										</Box>
									</MenuItem>
								))}
							</Select>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						// height: "calc(100vh - 64px - 57px)", // Hauteur totale moins 46 pixels du bandeau
						height: "100%", // Hauteur totale moins 46 pixels du bandeau
						width: "100%", // Prend toute la largeur de la fenêtre
						border: 0,
					}}
				>
					<VisitorsMapView />
				</Box>
			</Box>
		</>
	);
}

export default MenuMapView;
