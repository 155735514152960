import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";
import { Web3Context } from "../../../context/web3-context";
import { RealTokensContext } from "../../../context/realTokens-context";
import Web3 from "web3";

import {
	Box,
	TableCell,
	CircularProgress,
	Button,
	useMediaQuery,
	Avatar,
	Tooltip,
	Checkbox,
	TextField,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import { green, red, purple, blue } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";

import TokenAmountShort from "../../Settings/Common/TokenAmountShort";
import FormatedNumberDays from "../../Common/Formater/FormatedNumberDays";
import TokenInfos from "../../Common/TokenInfos";
import FormatedNumberDigit from "../../Common/Formater/FormatedNumberDigit";

const SIZE_ICON = 20;

function WalletPurchasesOfferRow({ offer, token, index, add = false }) {
	const { web3active, web3functions, web3smartContract } = useContext(Web3Context);
	const { set_MarketSwapCatOfferUpdate, set_MarketYamOfferUpdate } = useContext(RealTokensContext);
	const { settingsMarket, settingsWallet, blockchainsClient, balanceWallets } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);

	const bc = blockchainsClient["Gnosis"];
	const _web3 = new Web3(bc.rpc);
	const yamAddress = web3smartContract.yamAddress;
	const swapcatAddress = web3smartContract.swapcatAddress;
	const yamABI = web3smartContract.yamABI;
	const swapcatABI = web3smartContract.swapcatABI;
	const ERC20ABI = web3smartContract.ERC20ABI;

	const [sc, setSc] = useState(null);
	const [coin, setCoin] = useState(null);
	// const [imgCoin, setImgCoin] = useState(null);
	// const [nameCoin, setNameCoin] = useState(null);
	const [amount, setAmount] = useState(null);
	const [price, setPrice] = useState(null);
	const [newYield, setNewYield] = useState(null);
	const [ratioPrice, setRatioPrice] = useState(null);
	const [tokenToBuy, setTokenToBuy] = useState(null);

	// const [editSc, setEditSc] = useState(null);
	// const [editCoin, setEditCoin] = useState(null);
	// const [editPrice, setEditPrice] = useState(null);
	// const [editAmount, setEditAmount] = useState(null);

	const [isEditable, setIsEditable] = useState(false);
	// const [isSaved, setIsSaved] = useState(true);
	const [toSend, setToSend] = useState(false);
	const [isSended, setIsSended] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);

	const [anchorElSc, setAnchorElSc] = useState(null);
	const [anchorElCoin, setAnchorElCoin] = useState(null);
	const [defaultSc, setDefaultSc] = useState(null);
	const [defaultCoin, setDefaultCoin] = useState(null);
	const [scList, setSCList] = useState([]);
	const [coinList, setCoinList] = useState([]);
	const [filteredCoinList, setFilteredCoinList] = useState([]);
	const [coinBalance, setCoinBalance] = useState([]);

	const [waitDeleteOffer, setWaitDeleteOffer] = useState(false);
	const [waitSendOffer, setWaitSendOffer] = useState(false);
	const [updateAttempts, setUpdateAttempts] = useState(0);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const getAllowance = async (sc, coinAddress) => {
		try {
			if (sc && coinAddress) {
				let spender = web3smartContract.yamAddress;
				if (sc.id === "swapcat") spender = web3smartContract.swapcatAddress;

				const coinContract = new _web3.eth.Contract(ERC20ABI, coinAddress);
				const allowance = Number(await coinContract.methods.allowance(settingsWallet.selectedWallet.toLowerCase(), spender).call());
				const decimals = Number(await coinContract.methods.decimals().call());
				const formattedAllowance = allowance / 10 ** decimals;

				return formattedAllowance;
			}
			return 0;
		} catch (error) {
			console.error("Error in getAllowance:", error);
			return 0;
		}
	};

	useEffect(() => {
		// Initialisation des nouvelles offres
		const selectedBlockchain = settingsWallet.selectedBlockchain;
		const blockchain = blockchainsClient[selectedBlockchain];

		const _coinList = Object.entries(blockchain.coinList).map(([symbol, details]) => ({ symbol, ...details }));
		const _scList = [
			{ label: "YAM", id: "yam", img: "/images/yam.png" },
			{ label: "Swapcat", id: "swapcat", img: "/images/swapcat.png" },
		];
		setCoinList(_coinList);
		setSCList(_scList);

		const defaultCoin = blockchain.coinList.lendWxDAIRMMv3;
		const defaultSc = _scList[0];

		setDefaultSc(defaultSc);
		setDefaultCoin(defaultCoin);
		setTokenToBuy(token.gnosisContract);
	}, []);

	useEffect(() => {
		// Initialisation des nouvelles offres
		const selectedBlockchain = settingsWallet.selectedBlockchain;
		const blockchain = blockchainsClient[selectedBlockchain];
		const selectedWallet = settingsWallet.selectedWallet;
		const balanceWallet = balanceWallets[selectedWallet].coins[selectedBlockchain];

		const _coinBalance = Object.entries(balanceWallet).map(([symbol, details]) => ({ symbol, ...details }));
		const _coinBalanceFiltered = _coinBalance.filter((object) => object.balance * object.usd > 1);
		setCoinBalance(_coinBalanceFiltered);
	}, [balanceWallets]);

	useEffect(() => {
		const selectedBlockchain = settingsWallet.selectedBlockchain;
		const blockchain = blockchainsClient[selectedBlockchain];

		let _coinList = Object.entries(blockchain.coinList).map(([symbol, details]) => ({ symbol, ...details }));

		// console.log("useEffect()", coinList)
		if (coinBalance) {
			_coinList.forEach((coin) => {
				coin.filter = false;
				const filteredCoin = coinBalance.filter((object) => object.symbol === coin.symbol);
				if (filteredCoin.length > 0) {
					// console.log("useEffect()",filteredCoin[0],coin)
					coin.filter = true;
					coin.usd = filteredCoin[0].balance * filteredCoin[0].usd;
				}
			});
			setFilteredCoinList(_coinList);

			if (coin) {
				const _coin = _coinList.find((object) => object.address.toLowerCase() === coin.address.toLowerCase());
				setCoin(_coin);
				// setEditCoin(_coin);
			}
		}
	}, [coinBalance]);

	useEffect(() => {
		setTokenToBuy(token.gnosisContract);
		const initializeOffer = async () => {
			try {
				if (offer && scList && filteredCoinList) {
					if (offer.newOffer) {
						const newOffer = offer.newOffer;

						if (!newOffer.isEditable) {
							setSc(newOffer.sc);
							setCoin(newOffer.coin);
							setPrice(newOffer.price);
							setAmount(newOffer.amount);
							setNewYield(newOffer.newYield);
							setRatioPrice(newOffer.ratioPrice);
							setTokenToBuy(token.gnosisContract);
						}
						if (Boolean(newOffer.isEditable) !== isEditable) setIsEditable(Boolean(newOffer.isEditable));
						if (Boolean(newOffer.toSend) !== toSend) setToSend(Boolean(newOffer.toSend));
						if (Boolean(newOffer.isSended) !== isSended) setIsSended(Boolean(newOffer.isSended));
						if (Boolean(newOffer.isDeleted) !== isDeleted) setIsDeleted(Boolean(newOffer.isDeleted));
						//
					} else if (offer.editOffer && offer.editOffer.editOffer) {
						const editOffer = offer.editOffer.editOffer;
						if (!editOffer.isEditable) {
							setSc(editOffer.sc);
							setCoin(editOffer.coin);
							setPrice(editOffer.price);
							setAmount(editOffer.amount);
							setNewYield(editOffer.newYield);
							setRatioPrice(editOffer.ratioPrice);
							setTokenToBuy(token.gnosisContract);
						}
						if (Boolean(editOffer.isEditable) !== isEditable) setIsEditable(Boolean(editOffer.isEditable));
						if (Boolean(editOffer.toSend) !== toSend) setToSend(Boolean(editOffer.toSend));
						if (Boolean(editOffer.isSended) !== isSended) setIsSended(Boolean(editOffer.isSended));
						if (Boolean(editOffer.isDeleted) !== isDeleted) setIsDeleted(Boolean(editOffer.isDeleted));
					} else {
						const _price = offer.token_value;
						const _ratioPrice = _price / token.tokenPrice;
						const _newYield = token.netRentYearPerToken / _price;
						const _amount = offer.token_amount;

						const _sc = scList.find((object) => object.id === offer.sc);
						const _coin = filteredCoinList.find((object) => object.address.toLowerCase() === offer.token_to_pay.toLowerCase());

						setSc(_sc);
						setCoin(_coin);
						setPrice(_price);
						setAmount(_amount);
						setNewYield(_newYield);
						setRatioPrice(_ratioPrice);
						setTokenToBuy(token.gnosisContract);
						setIsEditable(false);
						setToSend(false);
						setIsSended(true);
						setIsDeleted(false);
					}
				}
			} catch (error) {
				console.error("Error in initializeOffer:", error);
			}
		};

		initializeOffer();
	}, [offer]);

	useEffect(() => {
		if (offer) {
			if (offer.newOffer) {
				// console.log("Gestion Events newOffer ...");
				const _offer = offer.newOffer;
				let changed = false;
				if (_offer.isEditable !== isEditable) {
					changed = true;
					// console.log("isEditable change to", isEditable, _offer.isEditable);
				}
				if (_offer.toSend !== toSend) {
					changed = true;
					// console.log("toSend change to", toSend);
				}
				if (_offer.isSended !== isSended) {
					changed = true;
					// console.log("isSended change to", isSended);
				}
				if (_offer.isDeleted !== isDeleted) {
					changed = true;
					// console.log("isDeleted change to", isDeleted);
				}

				if (changed) {
					// console.log("Save newOffer");
					saveNewOffers();
				}
			}
			if (offer.editOffer) {
				// console.log("Gestion Events editOffer ...", token.shortName);
				const _offer = offer.editOffer.editOffer;
				let changed = false;
				if (_offer.isEditable !== isEditable) {
					changed = true;
					// console.log("isEditable change to", isEditable);
				}
				if (_offer.toSend !== toSend) {
					changed = true;
					// console.log("toSend change to", toSend);
				}
				if (_offer.isSended !== isSended) {
					changed = true;
					// console.log("isSended change to", isSended);
				}
				if (_offer.isDeleted !== isDeleted) {
					changed = true;
					// console.log("isDeleted change to", isDeleted);
				}

				// console.log("test", !isEditable, toSend, offer.token_value !== price, offer.token_amount !== amount);
				// console.log("test", isEditable, toSend, isSended, isDeleted);
				if (!isSended) {
					if (!isEditable && toSend && offer.token_value === price && offer.token_amount === amount) {
						setToSend(false);
					}
					if (!isEditable && !toSend && (offer.token_value !== price || offer.token_amount !== amount)) {
						setToSend(true);
					}
				} else {
					if (!isEditable) {
						setToSend(false);
					}
				}

				if (changed) {
					// console.log("Save editOffer");
					saveEditOffers();
				}
			}
		}
	}, [isEditable, toSend, isSended, isDeleted]);

	useEffect(() => {
		if (isDeleted && offer) {
			if (offer.newOffer) {
				const timerId = setInterval(() => removeOffers(), 1000);
				return () => clearInterval(timerId);
			} else if (offer.editOffer) {
				const timerId = setInterval(() => removeOffers(), 1000);
				return () => clearInterval(timerId);
			} else {
				const updateOffer = async () => {
					try {
						if (offer.sc === "yam") {
							await set_MarketYamOfferUpdate(offer.id_offer, false);
						} else if (offer.sc === "swapcat") {
							await set_MarketSwapCatOfferUpdate(offer.id_offer, false);
						}
						setUpdateAttempts((prev) => prev + 1);
					} catch (error) {
						console.error("Error updating offer:", error);
					}
				};

				const intervalId = setInterval(() => {
					if (updateAttempts < 10) {
						updateOffer();
					} else {
						clearInterval(intervalId);
						// Reset `isDeleted` si nécessaire
						// setIsDeleted(false);
					}
				}, 2000);

				return () => clearInterval(intervalId); // Nettoyage du timer au démontage
			}
		}
	}, [isDeleted, offer, updateAttempts]);

	const createNewOffers = async () => {
		let _price = token.tokenPrice;
		let _ratioPrice = 1;
		let _newYield = token.annualPercentYield;

		if (settingsMarket.WalletPurchaseOffers.useRealTPrice) {
			_ratioPrice = 0.97 - 0.05 * (1 - token.rentedUnits / token.totalUnits);
		} else {
			_ratioPrice = 1 + settingsMarket.WalletPurchaseOffers.usePremium / 100;
		}
		_price = _price * _ratioPrice;
		_newYield = token.netRentYearPerToken / _price;

		const _sc = settingsMarket.WalletPurchaseOffers.useSc ? settingsMarket.WalletPurchaseOffers.useSc : defaultSc;
		const _coin = settingsMarket.WalletPurchaseOffers.useCoin ? settingsMarket.WalletPurchaseOffers.useCoin : defaultCoin;

		const _allowance = _coin ? await getAllowance(_sc, _coin.address) : 0;
		const _amount = Math.round((_allowance / _price) * 100) / 100;
		// console.log("create newOffer", _allowance, _amount, _price);

		let addOffer = {
			sc: _sc,
			coin: _coin,
			amount: _amount,
			price: _price,
			newYield: _newYield,
			ratioPrice: _ratioPrice,
			tokenToBuy:token.gnosisContract,// tokenToBuy,
			isEditable: false,
			toSend: true,
			isSended: false,
			isDeleted: false,
			shortName: token.shortName,
		};

		// console.log("create newOffer", token.shortName, addOffer);
		token.handleAddOffers(0, token.shortName, addOffer);
	};

	const createEditOffers = () => {
		let editOffer = {
			sc: sc,
			coin: coin,
			amount: amount,
			price: price,
			newYield: newYield,
			ratioPrice: ratioPrice,
			tokenToBuy: tokenToBuy,
			isEditable: true,
			toSend: false,
			isSended: false,
			isDeleted: false,
			shortName: token.shortName,
		};

		// console.log("create editOffer", offer.id_offer, token.shortName, editOffer);
		token.handleEditOffers(0, offer.id_offer, token.shortName, editOffer);
	};

	const saveNewOffers = () => {
		let saveOffer = {
			...offer.newOffer,
		};

		// Mettre à jour uniquement si la valeur a changé
		if (saveOffer.amount !== amount) saveOffer.amount = amount;
		if (saveOffer.price !== price) saveOffer.price = price;
		if (saveOffer.newYield !== newYield) saveOffer.newYield = newYield;
		if (saveOffer.ratioPrice !== ratioPrice) saveOffer.ratioPrice = ratioPrice;
		if (saveOffer.isEditable !== isEditable) saveOffer.isEditable = isEditable;
		if (saveOffer.toSend !== toSend) saveOffer.toSend = toSend;
		if (saveOffer.isSended !== isSended) saveOffer.isSended = isSended;
		if (saveOffer.isDeleted !== isDeleted) saveOffer.isDeleted = isDeleted;

		if (offer.id && price && amount) {
			// console.log("Save newOffer", offer.id, offer.shortName, saveOffer);
			token.handleAddOffers(offer.id, offer.shortName, saveOffer);
		}
	};

	const saveEditOffers = () => {
		// console.log("saveEditOffers", offer, token);
		let editOffer = { ...offer.editOffer.editOffer };

		// Mettre à jour uniquement si la valeur a changé
		if (editOffer.amount !== amount) editOffer.amount = amount;
		if (editOffer.price !== price) editOffer.price = price;
		if (editOffer.newYield !== newYield) editOffer.newYield = newYield;
		if (editOffer.ratioPrice !== ratioPrice) editOffer.ratioPrice = ratioPrice;
		if (editOffer.isEditable !== isEditable) editOffer.isEditable = isEditable;
		if (editOffer.toSend !== toSend) editOffer.toSend = toSend;
		if (editOffer.isSended !== isSended) editOffer.isSended = isSended;
		if (editOffer.isDeleted !== isDeleted) editOffer.isDeleted = isDeleted;

		if (offer.id && price && amount) {
			// console.log("Save editOffer", offer.editOffer.id, offer.editOffer.id_offer, offer.editOffer.shortName, editOffer);
			token.handleEditOffers(offer.editOffer.id, offer.editOffer.id_offer, offer.editOffer.shortName, editOffer);
		}
	};

	//---------------------------------------------------------------
	//-- handle functions pour la gestion des envois blockchain    --
	//---------------------------------------------------------------

	const removeOffers = async () => {
		const callback = async (result) => {
			if (result) {
				// console.log("Delete realToken offer successfully:", result);
				if (!offer.editOffer) setIsDeleted(true);
			} else {
				if (offer.sc === "yam") await set_MarketYamOfferUpdate(offer.id_offer);
				if (offer.sc === "swapcat") await set_MarketSwapCatOfferUpdate(offer.id_offer);
				setIsDeleted(true);
			}
			setWaitDeleteOffer(false);
		};

		setWaitDeleteOffer(true);
		if (offer.newOffer) {
			// console.log("removeOffers: newOffer n°", offer.id);
			token.handleRemoveOffers(offer.id);
			callback(`local id ${offer.id}`);
		} else {
			if (offer.editOffer) {
				// console.log("removeOffers: editOffer n°", offer.editOffer.id, offer);
				token.handleRemoveOffers(offer.editOffer.id);
				callback(`local id ${offer.editOffer.id}`);
			} else {
				if (offer.id_offer) {
					// console.log("removeOffers: ", offer.sc, "n°", offer.id_offer, offer);
					if (offer.sc === "yam") {
						await web3functions.deleteYam(offer.id_offer, callback);
					}
					if (offer.sc === "swapcat") {
						await web3functions.deleteSwapCat(offer.id_offer, callback);
					}
				}
			}
		}
	};

	const createOrModifyOffer = async () => {
		const callback = async (result) => {
			if (result) {
				console.log("Create or Modifiy Offer successfully:", result);
				// removeOffers();
				setToSend(false);
				setIsDeleted(true);
				setIsSended(true);
			}
			setToSend(true);
			setWaitSendOffer(false);
		};

		setWaitSendOffer(true);
		if (offer.newOffer) {
			// console.log("createOrModifyOffer: newOffer n°", offer.newOffer); //, token);
			const _offertoken = offer.newOffer.coin.address;
			const _buyertoken = offer.newOffer.tokenToBuy;
			const _buyerholder = "0x0000000000000000000000000000000000000000";
			const _price = 1 / price;
			const _amount = amount * price;
			const _offerid = offer.id_offer ? offer.id_offer : 0;
			// console.log("createOrModifyOffer: newOffer n°", offer, token);
			// console.log("createOrModifyOffer: newOffer n°", _offertoken, _buyertoken, _buyerholder, _price, _offerid);

			if (offer.newOffer.sc.id === "yam") {
				await web3functions.createYam(_offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback);
			}
			if (offer.newOffer.sc.id === "swapcat") {
				await web3functions.createSwapcat(_offertoken, _buyertoken, _price, _offerid, callback);
			}
		} else {
			if (offer.editOffer) {
				// console.log("createOrModifyOffer: editOffer n°", offer.editOffer.id, offer);
				const _offertoken = offer.editOffer.editOffer.coin.address;
				const _buyertoken = offer.editOffer.editOffer.tokenToBuy;
				const _buyerholder = "0x0000000000000000000000000000000000000000";
				const _price = 1 / price;
				const _amount = amount * price;
				const _offerid = offer.id_offer ? offer.id_offer : 0;
				// console.log("createOrModifyOffer: editOffer n°", _price, editCoin.toUSD, editPrice);
				// console.log("createOrModifyOffer: editOffer n°", _offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid);

				if (offer.editOffer.editOffer.sc.id === "yam") {
					await web3functions.createYam(_offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback);
				}
				if (offer.editOffer.editOffer.sc.id === "swapcat") {
					await web3functions.createSwapcat(_offertoken, _buyertoken, _price, _offerid, callback);
				}
			}
		}
	};

	//---------------------------------------------------------------
	//-- handle functions pour la gestions des boutons d'action    --
	//---------------------------------------------------------------

	const handleEditOffers = () => {
		if (!isEditable) {
			if (offer.id_offer) {
				if (!offer.editOffer) createEditOffers(true, false, false);
			}
		}
		setIsEditable(true);
	};

	const handleSaveOffers = () => {
		setIsEditable(false);
	};

	const handleSendOffers = () => {
		createOrModifyOffer();
	};

	const handleRemoveOffers = () => {
		if (offer.newOffer) setIsDeleted(true);
		else if (offer.editOffer) setIsDeleted(true);
		else removeOffers();
	};

	//

	const _borderRadius = 15;
	let _backgroundColor = "transparent";
	if (offer) {
		if (offer.newOffer) _backgroundColor = red[50];
		else if (offer.editOffer) _backgroundColor = green[50];
	}

	let _backgroundColorEdit = "transparent";
	if (offer) {
		if (offer.newOffer) _backgroundColorEdit = red[200];
		else if (offer.editOffer) _backgroundColorEdit = green[200];
	}

	// if(offer) console.log(token.shortName, sc, coin, amount, price, newYield, ratioPrice);

	const logo_pitsbi_sizeW = 20;
	const logo_pitsbi_sizeH = 25;
	if (offer)
		return (
			<>
				<TableCell align='center' sx={{ p: 0, py: 0.5 }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						{/* <Checkbox
							checked={offer.checked}
							sx={{ ml: 0.5, padding: 0 }}
							onChange={() => token.handleCheckedOffers(offer.id_offer, !offer.checked)}
						/> */}
					</Box>
				</TableCell>

				{/* Offer Smartcontract */}
				<TableCell align='center' sx={{ p: 0, py: 0.5, backgroundColor: isEditable && !offer.editOffer ? _backgroundColorEdit : "transparent" }}>
					{isEditable && !offer.editOffer ? (
						<Box sx={{ ml: 0 }}>
							<Button
								sx={{ m: 0, px: 0, minWidth: 35 }}
								variant='outlined'
								onClick={(e) => {
									setAnchorElSc(e.currentTarget);
								}}
							>
								{sc ? (
									<Avatar
										alt='coin'
										src={sc === "swapcat" ? "/images/swapcat.png" : "/images/yam.png"}
										sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}
									/>
								) : (
									<Avatar
										alt='coin'
										// src={"/images/yam.png"}
										sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}
									>
										?
									</Avatar>
								)}
							</Button>

							<Menu anchorEl={anchorElSc} open={Boolean(anchorElSc)} onClose={() => setAnchorElSc(null)}>
								{scList.map((object, index) => (
									<MenuItem
										key={object.id}
										value={object.id}
										onClick={(e) => {
											console.log("object", object);
											// setSc(object.id);
											setSc(object.id);
											setAnchorElSc(null);
										}}
									>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
											<>
												<Avatar key={object.name} alt={object.name} src={object.img} sx={{ ml: 1, width: 20, height: 20, borderRadius: 0 }} />

												<Box sx={{ ml: 1 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{object.label}
													</Typography>
												</Box>
											</>
										</Box>
									</MenuItem>
								))}
							</Menu>
						</Box>
					) : (
						<Box
							sx={{
								py: 0.5,
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								borderTopLeftRadius: _borderRadius,
								borderBottomLeftRadius: _borderRadius,
								backgroundColor: _backgroundColor,
							}}
						>
							<Avatar alt='coin' src={sc ? sc.img : ""} sx={{ ml: 1, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }} />
						</Box>
					)}
				</TableCell>

				{/* Offer Coin to buy Token */}
				<TableCell align='center' sx={{ p: 0, py: 0.5, backgroundColor: isEditable && !offer.editOffer ? _backgroundColorEdit : "transparent" }}>
					{isEditable && !offer.editOffer ? (
						<Box sx={{ ml: 0 }}>
							<Button
								sx={{ m: 0, px: 0, minWidth: 35 }}
								variant='outlined'
								onClick={(e) => {
									setAnchorElCoin(e.currentTarget);
								}}
							>
								{coin ? (
									<Avatar alt='coin' src={coin.imgToken} sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }} />
								) : (
									<Avatar alt='coin' sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}>
										?
									</Avatar>
								)}
							</Button>

							<Menu anchorEl={anchorElCoin} open={Boolean(anchorElCoin)} onClose={() => setAnchorElCoin(null)}>
								{filteredCoinList
									.filter((object) => object.filter === true)
									.map((object, index) => (
										<MenuItem
											key={object.id}
											value={object.address.toLowerCase()}
											onClick={(e) => {
												console.log("object", object);
												setCoin(object);
												setAnchorElCoin(null);
											}}
										>
											<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
												<>
													<Avatar key={object.name} alt={object.name} src={object.imgToken} sx={{ ml: 0, width: 20, height: 20, borderRadius: 0 }} />

													<Box sx={{ ml: 1 }}>
														<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
															{object.name} ({object.usd.toFixed(2)} $)
														</Typography>
													</Box>
												</>
											</Box>
										</MenuItem>
									))}
							</Menu>
						</Box>
					) : (
						<Tooltip title={coin ? coin.name : "?"}>
							<Box
								sx={{
									py: 0.5,
									width: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: _backgroundColor,
								}}
							>
								<Avatar alt='coin' src={coin ? coin.imgToken : ""} sx={{ m: 0, width: SIZE_ICON, height: SIZE_ICON }} />
							</Box>
						</Tooltip>
					)}
				</TableCell>

				{/* Offer Amount */}
				<TableCell align='right' sx={{ p: 0, py: 0.5, backgroundColor: isEditable ? _backgroundColorEdit : "transparent" }}>
					<Box
						sx={{
							py: 0.5,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: isEditable ? "center" : "right",
							backgroundColor: isEditable ? _backgroundColorEdit : _backgroundColor,
						}}
					>
						{isEditable ? (
							<TextField
								sx={{
									m: 0,
									p: 0,
									maxWidth: 120,
								}}
								variant='outlined'
								size='small'
								type='number'
								value={isEditable ? parseFloat(amount ? amount.toFixed(4) : 0) : amount}
								onChange={(e) => {
									const newAmount = parseFloat(e.target.value) || 0;
									setAmount(newAmount);
								}}
								inputProps={{
									max: 999,
									step: 0.1,
									style: {
										textAlign: "center",
									},
								}}
							/>
						) : (
							<FormatedNumberDigit value={amount} color={"primary.main"} digit={2} />
						)}
					</Box>
				</TableCell>

				{/* Offer Price */}
				<TableCell align='right' sx={{ p: 0, py: 0.5, backgroundColor: isEditable ? _backgroundColorEdit : "transparent" }}>
					<Tooltip title={coin ? `${(price * (coin.toUSD ? coin.toUSD : 1)).toFixed(2)} $` : `Wait conversion ....`}>
						<Box
							sx={{
								py: 0.5,
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: isEditable ? "center" : "right",
								backgroundColor: isEditable ? _backgroundColorEdit : _backgroundColor,
							}}
						>
							{isEditable ? (
								<TextField
									sx={{
										m: 0,
										p: 0,
										maxWidth: 120,
									}}
									variant='outlined'
									size='small'
									type='number'
									value={isEditable ? parseFloat(price.toFixed(4)) : price}
									onChange={(e) => {
										const newPrice = parseFloat(e.target.value) || 0;
										setPrice(newPrice);
										// setEditPrice(newPrice);
										setNewYield(token.netRentYearPerToken / newPrice);
										setRatioPrice(newPrice / token.tokenPrice);
									}}
									inputProps={{
										max: 999,
										step: 0.1,
										style: {
											textAlign: "center",
										},
									}}
								/>
							) : (
								<FormatedNumberDigit value={price} color={"primary.main"} digit={2} />
							)}
						</Box>
					</Tooltip>
				</TableCell>

				{/* Offer New Yield */}
				<TableCell align='right' sx={{ p: 0, py: 0.5 }}>
					<Box
						sx={{
							py: 0.5,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "right",
							backgroundColor: _backgroundColor,
						}}
					>
						<FormatedPercentage value={newYield} color={"primary.main"} />
					</Box>
				</TableCell>

				{/* Offer Ratio Price */}
				<TableCell align='right' sx={{ p: 0, py: 0.5 }}>
					<Box
						sx={{
							py: 0.5,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "right",
							backgroundColor: _backgroundColor,
						}}
					>
						<FormatedPercentage value={ratioPrice} color={"primary.main"} />
					</Box>
				</TableCell>

				{/* Offer Actions */}
				<TableCell align='center' sx={{ p: 0, pr: 1 }}>
					<Box
						sx={{
							py: 0.5,
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							borderTopRightRadius: _borderRadius,
							borderBottomRightRadius: _borderRadius,
							backgroundColor: _backgroundColor,
						}}
					>
						{!isEditable && <EditIcon sx={{ ml: 2, fontSize: SIZE_ICON, color: blue[600], cursor: "pointer" }} onClick={handleEditOffers} />}

						{isEditable && (
							<CheckCircleOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON, color: blue[600], cursor: "pointer" }} onClick={handleSaveOffers} />
						)}

						{!isEditable && add && (
							<AddCircleOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON, color: blue[600], cursor: "pointer" }} onClick={() => createNewOffers()} />
						)}

						{waitSendOffer ? (
							<CircularProgress sx={{ ml: 2, mr: 1, color: green[600] }} size='1rem' />
						) : (
							!isSended &&
							!isEditable &&
							toSend &&
							(web3active ? (
								<SendIcon sx={{ ml: 2, fontSize: SIZE_ICON, color: green[600], cursor: "pointer" }} onClick={handleSendOffers} />
							) : (
								<Avatar
									alt='Logo'
									src='/logo-pitsbi2.png'
									sx={{ ml: 2, p: 0, width: logo_pitsbi_sizeW, height: logo_pitsbi_sizeH, borderRadius: 0, border: 0, cursor: "pointer" }}
									onClick={() => web3functions.connect()}
								/>
							))
						)}

						{waitDeleteOffer ? (
							<CircularProgress sx={{ ml: 2, mr: 1, color: red[700] }} size='1rem' />
						) : web3active ? (
							<DeleteOutlineIcon
								sx={{ ml: 2, mr: 1, fontSize: SIZE_ICON, color: isDeleted ? green[600] : red[700], cursor: "pointer" }}
								onClick={handleRemoveOffers}
							/>
						) : (
							<Avatar
								alt='Logo'
								src='/logo-pitsbi2.png'
								sx={{ ml: 2, p: 0, width: logo_pitsbi_sizeW, height: logo_pitsbi_sizeH, borderRadius: 0, border: 0, cursor: "pointer" }}
								onClick={() => web3functions.connect()}
							/>
						)}
					</Box>
				</TableCell>
			</>
		);

	return (
		<>
			<TableCell align='center' sx={{ p: 0 }} colSpan={7}></TableCell>

			<TableCell align='center' sx={{ p: 0, pr: 1 }}>
				<AddCircleOutlineIcon sx={{ ml: 2, fontSize: SIZE_ICON, color: blue[600], cursor: "pointer" }} onClick={() => createNewOffers()} />
			</TableCell>
		</>
	);
}

export default WalletPurchasesOfferRow;
