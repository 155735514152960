import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Button, Typography } from "@mui/material";
import MinutesTimer from "../Common/MinutesTimer";
// import SecondsTimer from "./SecondsTimer";
import HoursTimer from "../Common/HoursTimer";
import DaysTimer from "../Common/DaysTimer";

function SettingsApiPitsBI(props) {
	const { settingsWallet, setSettingsWallet } = useContext(SettingsContext);

	if (!settingsWallet) return <></>;

	const timerApiUpdateChange = (newValue) => {
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			timerApiUpdate: newValue,
		}));
	};
	const timeoutApiGetChange = (newValue) => {
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			timeoutApiGet: newValue,
		}));
	};

	return (
		<Box
			sx={{
				m: 1,
				p: 1,
				border: 1,
				borderColor: "primary.main",
				borderRadius: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant='h4' sx={{ my: 1, px: 2, color: "primary.main" }}>
				Manage Pit' BI Api update Settings
			</Typography>

			<Box
				sx={{
					mx: 1,
					p: 0,
					border: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<MinutesTimer
					value={settingsWallet.timerApiUpdate}
					onChange={(newValue) => timerApiUpdateChange(newValue)}
					min={1}
					max={60}
					step={1}
					title="Refresh Pit's BI API to update Stats value every"
				/>
				<MinutesTimer
					value={settingsWallet.timeoutApiGet}
					onChange={(newValue) => timeoutApiGetChange(newValue)}
					min={1}
					max={24}
					step={1}
					title='Verify updates on the community API every'
				/>

				<HoursTimer
					value={settingsWallet.timerUpdateWhiteListTokens}
					onChange={(newValue) => {
						setSettingsWallet((prevSettings) => ({
							...prevSettings,
							timerUpdateWhiteListTokens: newValue,
						}));
					}}
					min={12}
					max={48}
					step={1}
					title='Verify WhiteList on the @ehpst API every'
				/>
			</Box>
		</Box>
	);
}

export default SettingsApiPitsBI;
