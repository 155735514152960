import React, { useState, useEffect, useContext } from "react";
// import { MyThemeContext } from "../../context/theme-context";
import { SettingsContext } from "../../context/settings-context";
// import { SnackbarContext } from "../../context/customsnackbar-context";
import { Link } from "react-router-dom";

import { Box, Typography, Menu, MenuItem, ListItemIcon, Tooltip, Avatar, Divider, TextField } from "@mui/material";
import { Checkbox, TableRow, TableCell, IconButton, TableContainer, TableBody, ListItemButton } from "@mui/material";
import Settings from "@mui/icons-material/Settings";

import WalletsMenuRow from "./WalletsMenuRow";

function WalletsMenu(props) {
	const { blockchainsClient, settingsWallet, setSettingsWallet, holderWallets } = useContext(SettingsContext);
	// const { openSnackbar } = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.open) {
			setOpen(true);
		}
	}, [props.open]);

	const handleClose = () => {
		setOpen(false);
		props.menuClose();
	};

	const handleSettingChange = (key, value) => {
		setSettingsWallet((prev) => ({ ...prev, [key]: value }));
	};

	const selectedWalletHideChange = (newValue) => {
		// console.log("selectedWalletHideChange", newValue);
		// console.log("newValue", newValue, settingsWallet);
		setSettingsWallet((prevSettings) => ({
			...prevSettings,
			selectedWalletHideCoins: newValue,
		}));
	};

	if (!blockchainsClient || !settingsWallet) return null;

	const coins = blockchainsClient[settingsWallet.selectedBlockchain]?.coinList;

	if (!coins) return <></>;
	return (
		<Menu
			id='account-menu'
			open={open}
			onClose={handleClose}
			transformOrigin={{ horizontal: "right", vertical: "top" }}
			anchorOrigin={{ horizontal: "right", vertical: "top" }}
			sx={{ marginTop: 5, p: 5 }}
		>
			<Box sx={{ mx:1 , display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				{/* Choix de la Blockchain */}
				<TextField
					sx={{ mt: 0.5, width: 1 }}
					select
					size='small'
					id='selectedBlockchain'
					value={settingsWallet.selectedBlockchain}
					label='Selected Blockchain'
					variant='outlined'
					onChange={(e) => handleSettingChange("selectedBlockchain", e.target.value)}
				>
					{Object.entries(blockchainsClient).map(([key, blockchain]) => (
						<MenuItem value={key} key={key}>
							<Box sx={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
									<Avatar alt={blockchain.name} src={blockchain.image} sx={{ width: 20, height: 20 }} />
								<Typography sx={{ ml: 2 }} variant="body2">
									{blockchain.name}
								</Typography>
							</Box>
						</MenuItem>
					))}
				</TextField>

        {/* Choix du Wallet */}
				{holderWallets && (
					<TextField
						sx={{ mt: 1,  width: 1 }}
						select
						// fullWidth
						size='small'
						id='selectedWallet'
						value={settingsWallet.selectedWallet ? settingsWallet.selectedWallet : ""}
						label='Selected Wallet'
						variant='outlined'
						onChange={(e) => handleSettingChange("selectedWallet", e.target.value)}
					>
						<MenuItem value={""}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Typography variant='body2'>No wallet Selected</Typography>
							</Box>
						</MenuItem>
						{holderWallets &&
							holderWallets.map((wallet) => (
								<MenuItem key={wallet.address} value={wallet.address}>
									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2'>
									{wallet.address.slice(0, 6)}...{wallet.address.slice(-4)}
									{wallet.name ? ` (${wallet.name})` : ""}
										</Typography>
									</Box>
								</MenuItem>
							))}
					</TextField>
				)}

				{/* Option de Masquage des soldes vides */}
				<MenuItem sx={{ mb: 0, width: 1, border: 0 }}>
						<Checkbox
							// fontSize='small'
							checked={settingsWallet.selectedWalletHideCoins}
							sx={{ m: 0, p: 0, "& .MuiSvgIcon-root": { fontSize: 20 } }}
							onChange={() => handleSettingChange("selectedWalletHideCoins", !settingsWallet.selectedWalletHideCoins)}
							/>
					<Typography sx={{ ml: 1 }} variant='body2' onClick={() => selectedWalletHideChange(!settingsWallet.selectedWalletHideCoins)}>
						{settingsWallet.selectedWalletHideCoins ? "Empty balances hide" : "Hide empty balances"}
					</Typography>
				</MenuItem>

{/* Liste des Coins */}
				<TableContainer sx={{ p: 0 }}>
					<Divider />
					<TableBody sx={{ p: 0 }}>
						{coins &&
							Object.values(coins)
								.filter((coin) => (settingsWallet.selectedWalletHideCoins ? coin.show > 0 : coin.show >= 0))
								.map((coin, index) => <WalletsMenuRow key={index} coin={coin} />)}
					</TableBody>
				</TableContainer>

				<Divider sx={{ width: 1 }} />

{/* Paramètres des Wallets */}
				<MenuItem sx={{  width: 1, border: 0 }} onClick={handleClose}>
					<ListItemButton sx={{ m: 0, p: 0 }} component={Link} to={"/settings?tab=2"}>
							<Settings fontSize='small' />
						<Typography sx={{ml:1}} variant='body2'>Wallets Settings</Typography>
					</ListItemButton>
				</MenuItem>
			</Box>
		</Menu>
	);
}

export default WalletsMenu;
