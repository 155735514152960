import React from "react";
import { Box } from "@mui/material";
import SettingsApiCoinGecko from "./Settings/SettingsApiCoinGecko";
import SettingsRealToken from "./Settings/SettingsRealToken";
import SettingsWallet from "./Settings/SettingsWallet";
import SettingsApiPitsBI from "./Settings/SettingsApiPitsBI";

function Common() {
	return (
		<Box sx={{ p: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<SettingsWallet />
			<SettingsApiPitsBI />
			<SettingsApiCoinGecko />
			<SettingsRealToken />
		</Box>
	);
}

export default Common;
