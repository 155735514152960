import React from "react";

import { TableRow, TableCell } from "@mui/material";

import ReadMoreIcon from "@mui/icons-material/ReadMore";
import UndoIcon from "@mui/icons-material/Undo";
import FormatedPrice from "../../Common/Formater/FormatedPrice";

function TablePropertyPriceTokensMapRow({ token, menuChange, menuBackChange }) {
	// console.log("TablePropertyPriceTokensMapRow", token);

	if (!menuChange) return <></>;
	if (token.label === "All Countries") return <></>;
	return (
		<TableRow key={token.label} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
			{menuChange && (
				<TableCell sx={{ px: 1, py: 1, cursor: "pointer" }} align='center' component='th' scope='row' onClick={() => menuChange(token.id)}>
					<ReadMoreIcon />
				</TableCell>
			)}
			{!menuChange && (
				<TableCell sx={{ px: 1, py: 1, cursor: "pointer" }} align='center' component='th' scope='row' onClick={() => menuBackChange(null)}>
					<UndoIcon />
				</TableCell>
			)}
			<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
				{token.label}
			</TableCell>
			<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
				{token.count}
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={token.minPrice} color={"primary.main"} />
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={token.maxPrice} color={"primary.main"} />
			</TableCell>
			<TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
				<FormatedPrice value={token.avgPrice} color={"secondary.main"} />
			</TableCell>
		</TableRow>
	);
}

export default TablePropertyPriceTokensMapRow;
