import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Création du contexte Snackbar
export const SnackbarContext = createContext();

// Provider Snackbar
export const SnackbarProvider = ({ children }) => {
	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: "",
		variant: "info",
		timeout: 4000,
	});

	const openSnackbar = (message, variant, timeout = 4000) => {
		setSnackbarState({ open: true, message, variant });
	};

	const closeSnackbar = () => {
		setSnackbarState({ ...snackbarState, open: false });
	};

	return (
		<SnackbarContext.Provider value={{ ...snackbarState, openSnackbar, closeSnackbar }}>
			{children}
			<CustomSnackbar />
		</SnackbarContext.Provider>
	);
};

// CustomSnackbar Component
const CustomSnackbar = () => {
	const { open, message, variant, closeSnackbar, timeout = 4000 } = useContext(SnackbarContext);

	return (
		<Snackbar open={open} autoHideDuration={timeout} onClose={closeSnackbar}>
			<Alert onClose={closeSnackbar} severity={variant} sx={{ width: "100%" }}>
				{message}
			</Alert>
		</Snackbar>
	);
};

// Utilisation dans un composant
const SomeComponent = () => {
	const { openSnackbar } = useContext(SnackbarContext);

	return <button onClick={() => openSnackbar("Message de test", "success")}>Ouvrir Snackbar</button>;
};
