import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";
import { Web3Context } from "../../../context/web3-context";
import { useTheme } from "@mui/system";
import Web3 from "web3";

import {
	Box,
	Avatar,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Tooltip,
	useMediaQuery,
	TextField,
	Button,
	Menu,
	MenuItem,
	Checkbox,
	IconButton,
	Badge,
	CircularProgress,
} from "@mui/material";
import { green, blue, purple } from "@mui/material/colors";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";

import WalletPurchasesOffersBody from "./WalletPurchasesOffersBody";
import TokenAmountShort from "../../Settings/Common/TokenAmountShort";
import RadioSettingShort from "../../Settings/Common/RadioSettingShort";
import MenuItemAllowanceCoin from "../Common/MenuItemAllowanceCoin";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function WalletPurchasesOffersHead({ offers, cityList, coinsList, newOrModifyOffers, setNewOrModifyOffers }) {
	const { web3active, web3functions, web3smartContract } = useContext(Web3Context);
	const { settingsMarket, setSettingsMarket, settingsWallet, blockchainsClient, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [selectedFullName, setSelectedFullName] = useState(() => {
		return sessionStorage.getItem("WalletPurchaseOffers_selectedFullName") || "";
	});
	const [filter, setFilter] = useState(false);
	const [selectedCity, setSelectedCity] = useState([]);
	const [selectedCoins, setSelectedCoins] = useState([]);
	const [selectedPropertyType, setSelectedPropertyType] = useState([]);
	const [anchorElCities, setAnchorElCities] = useState(null);
	const [anchorElCoins, setAnchorElCoins] = useState(null);
	const [anchorElPropertyType, setAnchorElPropertyType] = useState(null);

	const [anchorElSc, setAnchorElSc] = useState(null);
	const [anchorElCoin, setAnchorElCoin] = useState(null);
	const [scList, setSCList] = useState([]);
	const [coinList, setCoinList] = useState([]);

	const [newOrModifyOffersToSendCount, setNewOrModifyOffersToSendCount] = useState(0);
	const [waitSendOffers, setWaitSendOffers] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const [sortBy, setSortBy] = useState(settingsMarket?.WalletPurchaseOffers?.sortBy || { field: "nbOffers", order: "asc", type: "number" });
	const theme = useTheme();

	const bc = blockchainsClient["Gnosis"];
	const _web3 = new Web3(bc.rpc);
	const ERC20ABI = web3smartContract.ERC20ABI;

	const handleClickCities = (event) => {
		setAnchorElCities(event.currentTarget);
	};

	const handleCloseCities = () => {
		setAnchorElCities(null);
	};

	const handleClickCoins = (event) => {
		setAnchorElCoins(event.currentTarget);
	};

	const handleCloseCoins = () => {
		setAnchorElCoins(null);
	};

	const handleClickPropertyType = (event) => {
		setAnchorElPropertyType(event.currentTarget);
	};

	const handleClosePropertyType = () => {
		setAnchorElPropertyType(null);
	};

	const handleSort = (field, type) => {
		setSortBy((prevSortBy) => ({
			field,
			order: prevSortBy.field === field && prevSortBy.order === "desc" ? "asc" : "desc",
			type,
		}));
	};

// 	useEffect(() => {
//     if (!settingsMarket.WalletPurchaseOffers) {
//         let updatedSettings = {
//             ...settingsMarket,
//             WalletPurchaseOffers: { unselectPropertyType: [],
// 							unselectedCity:[],
// 							unselectedCoins: [], },
//         };
//         setSettingsMarket(updatedSettings);
//     }
// }, [settingsMarket]);

	useEffect(() => {
		sessionStorage.setItem("WalletPurchaseOffers_selectedFullName", selectedFullName);
	}, [selectedFullName]);

	useEffect(() => {
		const newOrModifyOffersToSendCount =
			newOrModifyOffers?.filter(
				(offer) => offer?.newOffer?.toSend && offer.newOffer?.sc?.id === "yam" && offer.holder_address === settingsWallet?.selectedWallet
			).length || 0;

		setNewOrModifyOffersToSendCount(newOrModifyOffersToSendCount);
	}, [newOrModifyOffers, settingsWallet]);

	const handleSendOffers = async () => {
		const callback = async (result) => {
			if (result) {
				console.log("Create or Modifiy Offers successfully:", result);
				setNewOrModifyOffers([]);
			}
			// setToSend(true);
			setWaitSendOffers(false);
		};

		setWaitSendOffers(true);
		let _offerTokens = [];
		let _buyerTokens = [];
		let _buyers = [];
		let _prices = [];
		let _amounts = [];
		const newOrModifyOffersToSend = newOrModifyOffers?.filter(
			(offer) => offer?.newOffer?.toSend && offer.newOffer?.sc?.id === "yam" && offer.holder_address === settingsWallet?.selectedWallet
		);
		newOrModifyOffersToSend.forEach((offer) => {
			// console.log("newOffer:", offer.id, offer);
			_offerTokens.push(offer.newOffer.coin.address);
			_buyerTokens.push(offer.newOffer.tokenToBuy);
			_buyers.push("0x0000000000000000000000000000000000000000");
			_prices.push(1 / offer.newOffer.price);
			_amounts.push(offer.newOffer.amount * offer.newOffer.price);
		});
		// console.log("newOffer:", _offerTokens, _buyerTokens, _buyers, _prices, _amounts);
		await web3functions.createYamBatch(_offerTokens, _buyerTokens, _buyers, _prices, _amounts, callback);
	};
	useEffect(() => {
				console.log("init useEffect");
    // Initialisation des propriétés nécessaires dans settingsMarket
    let updatedSettings = {
        ...settingsMarket,
        WalletPurchaseOffers: {
            ...settingsMarket?.WalletPurchaseOffers, // Initialisation sécurisée
            unselectPropertyType: settingsMarket?.WalletPurchaseOffers?.unselectPropertyType || [],
            unselectedCity: settingsMarket?.WalletPurchaseOffers?.unselectedCity || [],
            unselectedCoins: settingsMarket?.WalletPurchaseOffers?.unselectedCoins || [],
        },
    };

    // Définir les constantes nécessaires
    const selectedBlockchain = settingsWallet.selectedBlockchain;
    const blockchain = blockchainsClient[selectedBlockchain];
    const defaultCoin = blockchain.coinList.lendWxDAIRMMv3;

    const coinList = Object.entries(blockchain.coinList).map(([symbol, details]) => ({
        symbol,
        ...details,
    }));
    const scList = [
        { label: "YAM", id: "yam", img: "/images/yam.png" },
        { label: "Swapcat", id: "swapcat", img: "/images/swapcat.png" },
    ];
    setCoinList(coinList);
    setSCList(scList);

    // Comparaison des tableaux (fonction utilitaire)
    function areArraysEqual(array1, array2) {
        if (array1.length !== array2.length) return false;
        return array1.every((element, index) => JSON.stringify(element) === JSON.stringify(array2[index]));
    }

    // Initialisation de propertyType
    if (
        !updatedSettings.propertyType ||
        !areArraysEqual(
            updatedSettings.propertyType,
            DEFAULT_PROPERTY_TYPE.slice(1).filter((p) => !p.label.startsWith("Text_"))
        )
    ) {
        updatedSettings.propertyType = DEFAULT_PROPERTY_TYPE.slice(1).filter((p) => !p.label.startsWith("Text_"));
        updatedSettings.SaleOffers = {
					...updatedSettings.SaleOffers,
					unselectPropertyType: [],
					unselectedCoins: [],
					unselectedCity:[],
					showPosition:true,
					showRented:true,
					showRentStart:true,
			};
			updatedSettings.WalletPurchaseOffers = {
				...updatedSettings.WalletPurchaseOffers,
				unselectPropertyType: [],
				unselectedCoins: [],
				unselectedCity:[],
				showPosition:true,
				showRented:true,
				showRentStart:true,
		};
    }

    // Initialisation de selectedPropertyType
    if (!updatedSettings.WalletPurchaseOffers.selectedPropertyType) {
        updatedSettings.WalletPurchaseOffers.selectedPropertyType = DEFAULT_PROPERTY_TYPE[0].label;
    }

    // Initialisation de WalletPurchaseOffers si certains paramètres sont absents
    if (!updatedSettings.WalletPurchaseOffers.useSc) {
        updatedSettings.WalletPurchaseOffers = {
            ...updatedSettings.WalletPurchaseOffers,
            useRealTPrice: true,
            usePremium: 5,
            useCoin: defaultCoin,
            useSc: scList[0],
        };
    }

    // Gestion des propriétés sélectionnées
    const types = updatedSettings.propertyType.map((type) => ({
        ...type,
        checked: !updatedSettings.WalletPurchaseOffers.unselectPropertyType.includes(type.index),
    }));
		if(	JSON.stringify(selectedPropertyType)!==JSON.stringify(types))     setSelectedPropertyType(types);

    // Gestion des villes sélectionnées
    const cities = cityList.map((city) => ({
        ...city,
        checked: !updatedSettings.WalletPurchaseOffers.unselectedCity.includes(city.label),
    }));
    if(	JSON.stringify(selectedCity)!==JSON.stringify(cities)) setSelectedCity(cities);

    // Gestion des pièces sélectionnées
    const coins = coinsList.map((coin) => ({
        ...coin,
        checked: !updatedSettings.WalletPurchaseOffers.unselectedCoins.includes(coin.label),
    }));
    if(	JSON.stringify(selectedCoins)!==JSON.stringify(coins))setSelectedCoins(coins);
		
    // Mise à jour finale des paramètres
    if(	JSON.stringify(settingsMarket)!==JSON.stringify(updatedSettings))     setSettingsMarket(updatedSettings);
}, [cityList, coinsList, settingsMarket, settingsWallet]);

	const handleSelectAllCities = (checked) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (checked) {
			settings.WalletPurchaseOffers.unselectedCity = [];
			handleCloseCities(null);
		} else {
			settings.WalletPurchaseOffers.unselectedCity = [];
			selectedCity.forEach((city) => {
				settings.WalletPurchaseOffers.unselectedCity = [...(settings.WalletPurchaseOffers.unselectedCity || []), city.label];
			});
		}
		setSettingsMarket(settings);
	};

	const handleCityChange = (unselectCity) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (!unselectCity.checked && settings.WalletPurchaseOffers.unselectedCity) {
			settings.WalletPurchaseOffers.unselectedCity = settings.WalletPurchaseOffers.unselectedCity.filter((city) => city !== unselectCity.label);
		}
		if (unselectCity.checked || !settings.WalletPurchaseOffers.unselectedCity) {
			settings.WalletPurchaseOffers.unselectedCity = [...(settings.WalletPurchaseOffers.unselectedCity || []), unselectCity.label];
		}
		setSettingsMarket(settings);
	};

	const handleSelectAllCoins = (checked) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (checked) {
			settings.WalletPurchaseOffers.unselectedCoins = [];
			handleCloseCoins(null);
		} else {
			settings.WalletPurchaseOffers.unselectedCoins = [];
			selectedCoins.forEach((coin) => {
				settings.WalletPurchaseOffers.unselectedCoins = [...(settings.WalletPurchaseOffers.unselectedCoins || []), coin.label];
			});
		}
		setSettingsMarket(settings);
	};

	const handleCoinsChange = (unselectCoin) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (!unselectCoin.checked && settings.WalletPurchaseOffers.unselectedCoins) {
			settings.WalletPurchaseOffers.unselectedCoins = settings.WalletPurchaseOffers.unselectedCoins.filter((coin) => coin !== unselectCoin.label);
		}
		if (unselectCoin.checked || !settings.WalletPurchaseOffers.unselectedCoins) {
			settings.WalletPurchaseOffers.unselectedCoins = [...(settings.WalletPurchaseOffers.unselectedCoins || []), unselectCoin.label];
		}
		setSettingsMarket(settings);
	};

	const handleSelectAllPropertyType = (checked) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (checked) {
			settings.WalletPurchaseOffers.unselectPropertyType = [];
			handleClosePropertyType(null);
		} else {
			settings.WalletPurchaseOffers.unselectPropertyType = [];
			selectedPropertyType.forEach((type) => {
				settings.WalletPurchaseOffers.unselectPropertyType = [...(settings.WalletPurchaseOffers.unselectPropertyType || []), type.index];
			});
		}
		setSettingsMarket(settings);
	};

	const handlePropertyTypeChange = (unselectPropertyType) => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		if (!unselectPropertyType.checked && settings.WalletPurchaseOffers.unselectPropertyType) {
			console.log(
				"filter",
				settings.WalletPurchaseOffers.unselectedCity.filter((type) => type !== unselectPropertyType.label)
			);
			settings.WalletPurchaseOffers.unselectPropertyType = settings.WalletPurchaseOffers.unselectPropertyType.filter(
				(type) => type !== unselectPropertyType.index
			);
		}
		if (unselectPropertyType.checked || !settings.WalletPurchaseOffers.unselectPropertyType) {
			settings.WalletPurchaseOffers.unselectPropertyType = [
				...(settings.WalletPurchaseOffers.unselectPropertyType || []),
				unselectPropertyType.index,
			];
		}
		console.log(unselectPropertyType, settings.WalletPurchaseOffers.unselectPropertyType);
		setSettingsMarket(settings);
	};

	let offersList = offers ? [...offers] : [];
	if (selectedFullName) {
		// console.log(selectedFullName.toLowerCase(), offers);
		offersList = offers.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));
	}

	const sortedOffers = [...offersList].sort((a, b) => {
		const order = sortBy.order === "asc" ? 1 : -1;
		if (sortBy.type === "number") return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
		if (sortBy.type === "text") return order * a[sortBy.field].localeCompare(b[sortBy.field]);
		else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
	});

	// console.log( "sortedOffers", sortedOffers);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	useEffect(() => {
		let settings = JSON.parse(JSON.stringify(settingsMarket));
		settings.WalletPurchaseOffers.sortBy = sortBy;
		setSettingsMarket(settings);
	}, [sortBy]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	if (!offers) return null;
	if (sortedOffers.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);

	const filterActive =
		settingsMarket?.WalletPurchaseOffers?.isPosition ||
		settingsMarket?.WalletPurchaseOffers?.isOfferAmount ||
		settingsMarket?.WalletPurchaseOffers?.isRatioPrice ||
		settingsMarket?.WalletPurchaseOffers?.isRealtPrice ||
		settingsMarket?.WalletPurchaseOffers?.isRealtYield ||
		settingsMarket?.WalletPurchaseOffers?.isNewPrice ||
		settingsMarket?.WalletPurchaseOffers?.isNewYield ||
		settingsMarket?.WalletPurchaseOffers?.isRented ||
		settingsMarket?.WalletPurchaseOffers?.isLastPricingDays ||
		settingsMarket?.WalletPurchaseOffers?.isRentStart ||
		settingsMarket?.WalletPurchaseOffers?.isnotRentStart ||
		settingsMarket?.WalletPurchaseOffers?.isWhiteList;

	const SIZE_ICON = 20;

	// console.log("cityList")
	// console.table(cityList)
	// console.log("coinsList")
	// console.table(settingsMarket.WalletPurchaseOffers.useRealTPrice)

	return (
		<>
			<Box
				sx={{
					width: 1,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 4,
				}}
			>
				<Box sx={{ width: "auto", my: 0, mx: 0, border: 0, display: "flex", justifyContent: "start", alignItems: "center" }}>
					<Box>
						<Tooltip title={filter ? "Hide Filter Settings" : "Show Filter Settings"}>
							<FilterAltIcon
								sx={{ ml: 0, fontSize: 40 }}
								color={filterActive ? "secondary" : filter ? "primary" : "disabled"}
								onClick={() => {
									setFilter(!filter);
								}}
							/>
						</Tooltip>
					</Box>

					<TextField
						sx={{ mt: 0, ml: 1, minWidth: 250 }}
						label='Search in API fullName or holder address'
						onChange={(e) => setSelectedFullName(e.target.value)}
						variant='outlined'
						type='text'
						size='small'
						value={selectedFullName}
						onFocus={(e) => e.target.select()}
						InputProps={{
							// Utilisez "InputProps" avec un "P" majuscule
							endAdornment: selectedFullName ? ( // Condition modifiée pour une meilleure clarté
								<IconButton onClick={() => setSelectedFullName("")}>
									<ClearIcon />
								</IconButton>
							) : null,
							style: {
								// Les styles doivent aussi être déplacés ici si spécifiques aux composants d'input
								textAlign: "center",
								verticalAlign: "center",
								padding: "0px 0px",
							},
						}}
					/>

					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
						<Box>
							<Tooltip title={settingsMarket.WalletPurchaseOffers.showPosition ? "Hide Column Position" : "Show Column Position"}>
								<Avatar
									sx={{ bgcolor: settingsMarket.WalletPurchaseOffers.showPosition ? green[500] : "disabled", ml: 2, width: 35, height: 35 }}
									onClick={() => {
										let settings = JSON.parse(JSON.stringify(settingsMarket));
										settings.WalletPurchaseOffers.showPosition = !settingsMarket.WalletPurchaseOffers.showPosition;
										setSettingsMarket(settings);
									}}
								>
									P
								</Avatar>
							</Tooltip>
						</Box>

						<Box>
							<Tooltip title={settingsMarket.WalletPurchaseOffers.showRented ? "Hide Column Rented" : "Show Column Rented"}>
								<Avatar
									sx={{ bgcolor: settingsMarket.WalletPurchaseOffers.showRented ? green[500] : "disabled", ml: 1, width: 35, height: 35 }}
									onClick={() => {
										let settings = JSON.parse(JSON.stringify(settingsMarket));
										settings.WalletPurchaseOffers.showRented = !settingsMarket.WalletPurchaseOffers.showRented;
										setSettingsMarket(settings);
									}}
								>
									R
								</Avatar>
							</Tooltip>
						</Box>

						<Box>
							<Tooltip title={settingsMarket.WalletPurchaseOffers.showRentStart ? "Hide Column Rent Start" : "Show Column Rent Start"}>
								<Avatar
									sx={{ bgcolor: settingsMarket.WalletPurchaseOffers.showRentStart ? green[500] : "disabled", ml: 1, width: 35, height: 35 }}
									onClick={() => {
										let settings = JSON.parse(JSON.stringify(settingsMarket));
										settings.WalletPurchaseOffers.showRentStart = !settingsMarket.WalletPurchaseOffers.showRentStart;
										setSettingsMarket(settings);
									}}
								>
									RS
								</Avatar>
							</Tooltip>
						</Box>

						{/* <Box>
							<Tooltip title={settingsMarket.WalletPurchaseOffers.isWhiteList ? "Show All realToken" : "Show only Whitelist RealToken"}>
								<Avatar
									sx={{ bgcolor: settingsMarket.WalletPurchaseOffers.isWhiteList ? green[500] : "disabled", ml: 1, width: 35, height: 35 }}
									onClick={() => {
										let settings = JSON.parse(JSON.stringify(settingsMarket));
										settings.WalletPurchaseOffers.isWhiteList = !settingsMarket.WalletPurchaseOffers.isWhiteList;
										setSettingsMarket(settings);
									}}
								>
									WL
								</Avatar>
							</Tooltip>
						</Box> */}
					</Box>

					<Box sx={{ my: isMatch ? 1 : 0, display: "flex", flexDirection: isMatch ? "column" : "row", alignItems: "center", justifyContent: "left" }}>
						{/* Selection Cities */}
						{/* <Box sx={{ ml: 0, display: "flex", flexDirection: "row" }}>
							<Button
								sx={{ ml: 1, width: 120 }}
								variant={selectedCity.every((w) => w.checked) ? "outlined" : "contained"} // Contained if all cities are checked, otherwise outlined
								onClick={(e) => {
									handleClickCities(e);
								}}
							>
								{selectedCity
									? (() => {
											const checkedCities = selectedCity.filter((w) => w.checked);
											const cityCount = checkedCities.length;

											if (settingsMarket) {
												if (settingsMarket.WalletPurchaseOffers)
													if (settingsMarket.WalletPurchaseOffers.unselectedCity?.length === 0) return "All Cities";
											} else {
												return "No City";
											}

											if (cityCount >= 0) {
												return cityCount > 1 ? `${cityCount}/${selectedCity.length} Cities` : `${cityCount}/${selectedCity.length} City`;
											}
									  })()
									: "No City"}
							</Button>

							<Menu anchorEl={anchorElCities} open={Boolean(anchorElCities)} onClose={handleCloseCities}>
								<MenuItem
									onClick={() => {
										// Check if all cities are selected
										const allChecked = selectedCity.every((city) => city.checked);
										// Toggle all cities: if all are checked, uncheck all; otherwise, check all
										handleSelectAllCities(!allChecked);
									}}
								>
									<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<Checkbox
											sx={{ width: 20, height: 20 }}
											color='primary'
											checked={selectedCity.every((city) => city.checked)} // True if all cities are checked
											indeterminate={
												selectedCity.some((city) => city.checked) && !selectedCity.every((city) => city.checked) // Indeterminate if some, but not all, are checked
											}
										/>
										<Typography variant='body2' sx={{ ml: 0.5 }}>
											All Cities
										</Typography>
									</Box>
								</MenuItem>
								{selectedCity.map((p, index) => (
									<MenuItem key={p.id} value={p.city}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
											<>
												<Checkbox
													sx={{
														width: 20,
														height: 20,
													}}
													color='primary'
													checked={p.checked}
													onChange={(e) => {
														handleCityChange({ label: p.label, checked: p.checked, id: p.id, count: p.count });
														// handleClose(e);
													}}
												/>
												<Box sx={{ ml: 0.5 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label} ({p.count})
													</Typography>
												</Box>
											</>
										</Box>
									</MenuItem>
								))}
							</Menu>
						</Box> */}

						{/* Selection Property Type */}
						<Box sx={{ ml: 0, display: "flex", flexDirection: "row" }}>
							<Button
								sx={{ ml: 1, width: 120 }}
								variant={selectedPropertyType.every((w) => w.checked) ? "outlined" : "contained"} // Contained if there is at least one checked wallet, otherwise outlined
								onClick={(e) => {
									handleClickPropertyType(e);
								}}
							>
								{selectedPropertyType
									? (() => {
											const checkedPropertyType = selectedPropertyType.filter((w) => w.checked);
											const propertyTypeCount = checkedPropertyType.length;

											if (settingsMarket) {
												if (settingsMarket.WalletPurchaseOffers)
													if (settingsMarket.WalletPurchaseOffers.unselectPropertyType?.length === 0) return "All Types";
											} else {
												return "No Type";
											}

											if (propertyTypeCount >= 0) {
												return propertyTypeCount > 1
													? `${propertyTypeCount}/${selectedPropertyType.length} Types`
													: `${propertyTypeCount}/${selectedPropertyType.length} Type`;
											}
									  })()
									: "No Type"}
							</Button>

							<Menu anchorEl={anchorElPropertyType} open={Boolean(anchorElPropertyType)} onClose={handleClosePropertyType}>
								<MenuItem
									onClick={() => {
										// Check if all cities are selected
										const allChecked = selectedPropertyType.every((type) => type.checked);
										// Toggle all cities: if all are checked, uncheck all; otherwise, check all
										handleSelectAllPropertyType(!allChecked);
									}}
								>
									<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<Checkbox
											sx={{ width: 20, height: 20 }}
											color='primary'
											checked={selectedPropertyType.every((type) => type.checked)} // True if all cities are checked
											indeterminate={
												selectedPropertyType.some((type) => type.checked) && !selectedPropertyType.every((type) => type.checked) // Indeterminate if some, but not all, are checked
											}
										/>
										<Typography variant='body2' sx={{ ml: 0.5 }}>
											All Property Type
										</Typography>
									</Box>
								</MenuItem>
								{selectedPropertyType.map((p, index) => (
									<MenuItem key={p.index} value={p.index}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
											<>
												<Checkbox
													sx={{
														width: 20,
														height: 20,
													}}
													color='primary'
													checked={p.checked}
													onChange={(e) => {
														handlePropertyTypeChange({ label: p.label, checked: p.checked, index: p.index });
														// handleClose(e);
													}}
												/>
												<Box sx={{ ml: 0.5 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label}
													</Typography>
												</Box>
											</>
										</Box>
									</MenuItem>
								))}
							</Menu>
						</Box>

						{/* Selection Coins */}
						<Box sx={{ ml: 0, display: "flex", flexDirection: "row" }}>
							<Button
								sx={{ ml: 1, width: 120 }}
								variant={selectedCoins.every((w) => w.checked) ? "outlined" : "contained"} // Contained if all cities are checked, otherwise outlined
								onClick={(e) => {
									handleClickCoins(e);
								}}
							>
								{selectedCoins
									? (() => {
											const checkedCoins = selectedCoins.filter((w) => w.checked);
											const coinCount = checkedCoins.length;

											if (settingsMarket) {
												if (settingsMarket.WalletPurchaseOffers)
													if (settingsMarket.WalletPurchaseOffers.unselectedCoins?.length === 0) return "All Coins";
											} else {
												return "No Coins";
											}

											if (coinCount >= 0) {
												return coinCount > 1 ? `${coinCount}/${selectedCoins.length} Coins` : `${coinCount}/${selectedCoins.length} Coin`;
											}
									  })()
									: "No Coin"}
							</Button>

							<Menu anchorEl={anchorElCoins} open={Boolean(anchorElCoins)} onClose={handleCloseCoins}>
								<MenuItem
									onClick={() => {
										// Check if all cities are selected
										const allChecked = selectedCoins.every((coin) => coin.checked);
										// Toggle all cities: if all are checked, uncheck all; otherwise, check all
										handleSelectAllCoins(!allChecked);
									}}
								>
									<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<Checkbox
											sx={{ width: 20, height: 20 }}
											color='primary'
											checked={selectedCoins.every((coin) => coin.checked)} // True if all cities are checked
											indeterminate={
												selectedCoins.some((coin) => coin.checked) && !selectedCoins.every((coin) => coin.checked) // Indeterminate if some, but not all, are checked
											}
										/>
										<Typography variant='body2' sx={{ ml: 0.5 }}>
											All Coins
										</Typography>
									</Box>
								</MenuItem>
								{selectedCoins.map((p, index) => (
									<MenuItem key={p.id} value={p.id}>
										<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
											<>
												<Checkbox
													sx={{
														width: 20,
														height: 20,
													}}
													color='primary'
													checked={p.checked}
													onChange={(e) => {
														handleCoinsChange({ label: p.label, checked: p.checked, id: p.id, count: p.count });
														// handleClose(e);
													}}
												/>
												<Avatar key={p.name} alt={p.name} src={p.img} sx={{ mx: 1, width: 20, height: 20 }} />

												<Box sx={{ ml: 0.5 }}>
													<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
														{p.label} ({p.count})
													</Typography>
												</Box>
											</>
										</Box>
									</MenuItem>
								))}
							</Menu>
						</Box>
					</Box>

					{isMatch ? (
						<TablePagination
							sx={{
								ml: "auto",
								borderRadius: 2,
								".MuiTablePagination-toolbar": {
									minHeight: "auto",
									padding: "0 0px",
								},
								".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
									display: "none",
								},
								".MuiTablePagination-select, .MuiTablePagination-selectIcon": {
									height: "auto",
									padding: "0 0px",
								},
								".MuiTablePagination-actions": {
									marginLeft: "0px",
								},
							}}
							rowsPerPageOptions={[10, 15, 25, 50, 100, 500]}
							component='div'
							count={sortedOffers.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					) : (
						<TablePagination
							sx={{ ml: "auto", borderRadius: 2 }}
							rowsPerPageOptions={[10, 15, 25, 50, 100, 500]}
							component='div'
							count={sortedOffers.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Box>

				<Paper elevation={3} sx={{ m: 0, width: 1, overflow: "hidden" }}>
					<TableContainer sx={{ mt: 0 }}>
						<Table sx={{ background: "background.paper" }} aria-label='sticky table'>
							<TableHead>
								<TableRow
									sx={{
										borderBottom: `2px solid ${theme.palette.primary.main}`,
										backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
										"&:last-child th": { borderBottom: 0 },
									}}
								>
									<TableCell
										align='center'
										sx={{ pl: 1, pr: 0, py: 1 }}
										colSpan={
											6 +
											(settingsMarket.WalletPurchaseOffers.showPosition ? 1 : 0) +
											(settingsMarket.WalletPurchaseOffers.showRentStart ? 1 : 0) +
											(settingsMarket.WalletPurchaseOffers.showRented ? 1 : 0)
										}
									>
										Holder Wallet
									</TableCell>

									<TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, backgroundColor: purple[300] }} colSpan={8}>
										<Box sx={{ ml: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
											<Tooltip title='Set defaut Smartcontract for new offer' placement='top'>
												<Box sx={{ ml: 0 }}>
													<Button
														sx={{ m: 0, px: 0, minWidth: 35 }}
														variant='outlined'
														onClick={(e) => {
															setAnchorElSc(e.currentTarget);
														}}
													>
														{settingsMarket.WalletPurchaseOffers.useSc ? (
															<Avatar
																alt='sc'
																src={settingsMarket.WalletPurchaseOffers.useSc.img}
																sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}
															/>
														) : (
															<Avatar alt='sc' sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, bgcolor: "error.main" }}>
																?
															</Avatar>
														)}
													</Button>

													<Menu anchorEl={anchorElSc} open={Boolean(anchorElSc)} onClose={() => setAnchorElSc(null)}>
														{scList.map((object, index) => (
															<MenuItem
																key={object.id}
																value={object.id}
																onClick={(e) => {
																	// console.log("object", object);
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.useSc = object;
																	setSettingsMarket(settings);
																	setAnchorElSc(null);
																}}
															>
																<Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
																	<>
																		<Avatar
																			key={object.name}
																			alt={object.name}
																			src={object.img}
																			sx={{ ml: 0, width: 20, height: 20, borderRadius: 0 }}
																		/>

																		<Box sx={{ ml: 1 }}>
																			<Typography variant='body2' sx={{ m: 0, color: "inherit" }}>
																				{object.label}
																			</Typography>
																		</Box>
																	</>
																</Box>
															</MenuItem>
														))}
													</Menu>
												</Box>
											</Tooltip>

											<Tooltip title='Set defaut Coin for new offer and the allowance (or amount to buy)' placement='top'>
												<Box sx={{ ml: 1 }}>
													<Button
														sx={{ m: 0, px: 0, minWidth: 35 }}
														variant='outlined'
														onClick={(e) => {
															setAnchorElCoin(e.currentTarget);
														}}
													>
														{settingsMarket.WalletPurchaseOffers.useCoin ? (
															<Avatar
																alt='coin'
																src={settingsMarket.WalletPurchaseOffers.useCoin.imgToken}
																sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}
															/>
														) : (
															<Avatar alt='coin' sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, bgcolor: "error.main" }}>
																?
															</Avatar>
														)}
													</Button>

													<Menu anchorEl={anchorElCoin} open={Boolean(anchorElCoin)} onClose={() => setAnchorElCoin(null)}>
														{coinList.map((object, index) => (
															<MenuItemAllowanceCoin key={index} coin={object} handleCloseMenu={setAnchorElCoin} />
														))}
													</Menu>
												</Box>
											</Tooltip>

											<Button
												sx={{ m: 0, ml: 1, px: 0, minWidth: 35, cursor: "not-allowed" }}
												variant='outlined'
												// onClick={(e) => {
												// 	setAnchorElSc(e.currentTarget);
												// }}
											>
												<SettingsIcon
													sx={{ ml: 0, fontSize: SIZE_ICON, color: blue[700], cursor: "not-allowed", opacity: 1 }}
													// onClick={handleSendOffers}
												/>
											</Button>

											<Typography variant='body2' style={{ fontWeight: "bold" }} sx={{ ml: "auto", flexGrow: 1 }}>
												Holder Offers Purchases
											</Typography>

											<Tooltip title='Set defaut Price for new offer (in %), RealT price if checked' placement='top'>
												<Box sx={{ ml: "auto", width: "110px", display: "flex", flexDirection: "row", alignItems: "center" }}>
													<Box sx={{ display: "flex", alignItems: "center" }}>
														<Checkbox
															checked={settingsMarket.WalletPurchaseOffers.useRealTPrice}
															sx={{ padding: 0, margin: 0 }}
															onChange={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.useRealTPrice = !settingsMarket.WalletPurchaseOffers.useRealTPrice;
																setSettingsMarket(settings);
															}}
														/>
													</Box>

													<Box sx={{ m: 0, p: 0, display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
														{settingsMarket.WalletPurchaseOffers.useRealTPrice ? (
															<Typography variant='body2'>RealT Price</Typography>
														) : (
															<>
																<TextField
																	sx={{ m: 0, p: 0 }}
																	// label='Pourcentage personnalisé'
																	variant='standard'
																	size='small'
																	type='number'
																	value={settingsMarket.WalletPurchaseOffers.usePremium || 0}
																	// onChange={handlePercentageChange}
																	onChange={(e) => {
																		let settings = JSON.parse(JSON.stringify(settingsMarket));
																		console.log("usePremium", e.target.value);
																		settings.WalletPurchaseOffers.usePremium = e.target.value;
																		setSettingsMarket(settings);
																	}}
																	inputProps={{
																		min: -100,
																		max: 100,
																		style: {
																			textAlign: "center",
																		},
																	}}
																/>
															</>
														)}
													</Box>
												</Box>
											</Tooltip>
										</Box>
									</TableCell>
								</TableRow>

								<TableRow
									sx={{
										borderBottom: `2px solid ${theme.palette.primary.main}`,
										backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
										"&:last-child th": { borderBottom: 0 },
									}}
								>
									{/* RealT */}
									<TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }} colSpan={2}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											RealT
										</Typography>
									</TableCell>

									{/* Token Name */}
									<TableCell
										align='left'
										sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("fullName", "text")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Token Name
											</Typography>
											{sortBy.field === "fullName" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>

									{/* Position */}
									{settingsMarket.WalletPurchaseOffers.showPosition && (
										<TableCell
											align='left'
											sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}
											onClick={() => handleSort("position", "number")}
										>
											<Tooltip title='Selected Wallet Position (All Holder Wallets Positions)' enterDelay={1500}>
												<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
													<Typography variant='body2' style={{ fontWeight: "bold" }}>
														Position
													</Typography>
													{sortBy.field === "position" &&
														(sortBy.order === "asc" ? (
															<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
														) : (
															<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
														))}
												</Box>
											</Tooltip>
										</TableCell>
									)}

									{/* RealT Price */}
									<TableCell
										align='left'
										sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("tokenPrice", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												RealT Price
											</Typography>
											{sortBy.field === "tokenPrice" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>

									{/* RealT Yield */}
									<TableCell
										align='left'
										sx={{
											pl: 1,
											pr: 0,
											py: 1,
											"&:hover": { cursor: "pointer" },
										}}
										onClick={() => handleSort("annualPercentageYield", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												RealT Yield
											</Typography>
											{sortBy.field === "annualPercentageYield" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>

									{/* Rented */}
									{settingsMarket.WalletPurchaseOffers.showRented && (
										<TableCell
											align='left'
											sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}
											onClick={() => handleSort("token_rented", "number")}
										>
											<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
												<Typography variant='body2' style={{ fontWeight: "bold" }}>
													Rented
												</Typography>
												{sortBy.field === "token_rented" &&
													(sortBy.order === "asc" ? (
														<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													) : (
														<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													))}
											</Box>
										</TableCell>
									)}

									{/* Rent Start */}
									{settingsMarket.WalletPurchaseOffers.showRentStart && (
										<TableCell
											align='left'
											sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}
											onClick={() => handleSort("rentStartDate", "text")}
										>
											<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
												<Typography variant='body2' style={{ fontWeight: "bold" }}>
													Rent Start
												</Typography>
												{sortBy.field === "rentStartDate" &&
													(sortBy.order === "asc" ? (
														<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													) : (
														<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
													))}
											</Box>
										</TableCell>
									)}

									{/* Nb */}
									<TableCell
										align='left'
										sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
										onClick={() => handleSort("nbOffers", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Nb
											</Typography>
											{sortBy.field === "nbOffers" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))}
										</Box>
									</TableCell>

									{/* Checkbox */}
									<TableCell
										align='center'
										sx={{ pl: 0, pr: 0, py: 1, "&:hover": { cursor: "pointer" }, backgroundColor: purple[300] }}
										// onClick={() => handleSort("token_amount", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
											{/* <Checkbox checked={false} sx={{ ml: 0.5, padding: 0 }} /> */}
										</Box>
									</TableCell>

									{/* Smartcontract */}
									<TableCell
										align='center'
										sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}
										// onClick={() => handleSort("token_amount", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												SC
											</Typography>
										</Box>
									</TableCell>

									{/* Market Coin */}
									<TableCell
										align='left'
										sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}
										// onClick={() => handleSort("token_amount", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Coin
											</Typography>
										</Box>
									</TableCell>

									{/* Offer Amount */}
									<TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Offer Amount
											</Typography>
										</Box>
									</TableCell>

									{/* Offer Price */}
									<TableCell sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Offer Price
											</Typography>
										</Box>
									</TableCell>

									{/* New Yield */}
									<TableCell sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												New Yield
											</Typography>
										</Box>
									</TableCell>

									{/* Ratio Price */}
									<TableCell
										sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}
										onClick={() => handleSort("ratioPrice", "number")}
									>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Ratio Price
											</Typography>
											{/* {sortBy.field === "ratioPrice" &&
												(sortBy.order === "asc" ? (
													<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												) : (
													<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
												))} */}
										</Box>
									</TableCell>

									{/* Actions */}
									<TableCell sx={{ pl: 1, pr: 1, py: 0, "&:hover": { cursor: "not-allowed" }, backgroundColor: purple[300] }}>
										<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
											<Typography variant='body2' style={{ fontWeight: "bold" }}>
												Actions
											</Typography>
											{newOrModifyOffersToSendCount > 0 && (
												<Tooltip title='Send Grouped offers, only Yam offers'>
													<Badge
														sx={{ ml: 0.25, mt: 0, fontSize: 20 }} //cursor: "not-allowed"
														badgeContent={newOrModifyOffersToSendCount}
														anchorOrigin={{
															vertical: "top",
															horizontal: "right",
														}}
														componentsProps={{
															badge: {
																sx: {
																	transform: "translate(30px, 0)", // Décalage de 10px vers la droite
																},
															},
														}}
														color='primary'
													>
														{waitSendOffers ? (
															<CircularProgress sx={{ ml: 2, mr: 1, color: green[600] }} size='1rem' />
														) : (
															<SendIcon sx={{ ml: 2, fontSize: 22, color: blue[700], cursor: "pointer" }} onClick={handleSendOffers} />
														)}
													</Badge>
												</Tooltip>
											)}
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>

							{filter && (
								<TableHead>
									<TableRow
										sx={{
											borderBottom: `2px solid ${theme.palette.primary.main}`,
											backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
											"&:last-child th": { borderBottom: 0 },
										}}
									>
										<TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }} colSpan={3}></TableCell>

										{/* Holder Position */}
										{settingsMarket.WalletPurchaseOffers.showPosition && (
											<TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
												<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
													<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
														<FilterAltIcon
															sx={{ ml: 0, fontSize: 30 }}
															color={settingsMarket.WalletPurchaseOffers.isPosition ? "secondary" : "disabled"}
															onClick={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.isPosition = !settingsMarket.WalletPurchaseOffers.isPosition;
																setSettingsMarket(settings);
															}}
														/>
														<TuneOutlinedIcon
															sx={{ ml: 2, fontSize: 30 }}
															color={settingsMarket.WalletPurchaseOffers.isPositionMenu ? "secondary" : "disabled"}
															onClick={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.isPositionMenu = !settingsMarket.WalletPurchaseOffers.isPositionMenu;
																setSettingsMarket(settings);
															}}
														/>
													</Box>
													{settingsMarket.WalletPurchaseOffers.isPositionMenu && (
														<>
															<TokenAmountShort
																value={settingsMarket.WalletPurchaseOffers.minPosition}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.minPosition = newValue;
																	if (
																		settings.WalletPurchaseOffers.maxPosition < settings.WalletPurchaseOffers.minPosition ||
																		!settings.WalletPurchaseOffers.maxPosition
																	)
																		settings.WalletPurchaseOffers.maxPosition = settings.WalletPurchaseOffers.minPosition + 0.1;
																	setSettingsMarket(settings);
																}}
																min={0}
																max={1000}
																step={0.01}
																title='Min'
															/>
															<TokenAmountShort
																value={settingsMarket.WalletPurchaseOffers.maxPosition}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.maxPosition = newValue;
																	if (
																		settings.WalletPurchaseOffers.maxPosition < settings.WalletPurchaseOffers.minPosition ||
																		!settings.WalletPurchaseOffers.maxPosition
																	)
																		settings.WalletPurchaseOffers.maxPosition = settings.WalletPurchaseOffers.minPosition;
																	setSettingsMarket(settings);
																}}
																min={0}
																max={1000}
																step={0.01}
																title='Max'
															/>
														</>
													)}
												</Box>
											</TableCell>
										)}

										{/* RealT Price */}
										<TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRealtPrice ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRealtPrice = !settingsMarket.WalletPurchaseOffers.isRealtPrice;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRealtPriceMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRealtPriceMenu = !settingsMarket.WalletPurchaseOffers.isRealtPriceMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isRealtPriceMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minRealtPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minRealtPrice = newValue;
																if (
																	settings.WalletPurchaseOffers.maxRealtPrice < settings.WalletPurchaseOffers.minRealtPrice ||
																	!settings.WalletPurchaseOffers.maxRealtPrice
																)
																	settings.WalletPurchaseOffers.maxRealtPrice = settings.WalletPurchaseOffers.minRealtPrice + 0.1;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxRealtPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxRealtPrice = newValue;
																if (
																	settings.WalletPurchaseOffers.maxRealtPrice < settings.WalletPurchaseOffers.minRealtPrice ||
																	!settings.WalletPurchaseOffers.maxRealtPrice
																)
																	settings.WalletPurchaseOffers.maxRealtPrice = settings.WalletPurchaseOffers.minRealtPrice;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell>

										{/* RealT Yield */}
										<TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRealtYield ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRealtYield = !settingsMarket.WalletPurchaseOffers.isRealtYield;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRealtYieldMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRealtYieldMenu = !settingsMarket.WalletPurchaseOffers.isRealtYieldMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isRealtYieldMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minRealtYield}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minRealtYield = newValue;
																if (
																	settings.WalletPurchaseOffers.maxRealtYield < settings.WalletPurchaseOffers.minRealtYield ||
																	!settings.WalletPurchaseOffers.maxRealtYield
																)
																	settings.WalletPurchaseOffers.maxRealtYield = settings.WalletPurchaseOffers.minRealtYield + 0.1;
																setSettingsMarket(settings);
															}}
															min={0}
															max={100}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxRealtYield}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxRealtYield = newValue;
																if (
																	settings.WalletPurchaseOffers.maxRealtYield < settings.WalletPurchaseOffers.minRealtYield ||
																	!settings.WalletPurchaseOffers.maxRealtPrice
																)
																	settings.WalletPurchaseOffers.maxRealtYield = settings.WalletPurchaseOffers.minRealtYield;
																setSettingsMarket(settings);
															}}
															min={0}
															max={100}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell>

										{/* Rented */}
										{settingsMarket.WalletPurchaseOffers.showRented && (
											<TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
												<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
													<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
														<FilterAltIcon
															sx={{ ml: 0, fontSize: 30 }}
															color={settingsMarket.WalletPurchaseOffers.isRented ? "secondary" : "disabled"}
															onClick={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.isRented = !settingsMarket.WalletPurchaseOffers.isRented;
																setSettingsMarket(settings);
															}}
														/>
														<TuneOutlinedIcon
															sx={{ ml: 2, fontSize: 30 }}
															color={settingsMarket.WalletPurchaseOffers.isRentedMenu ? "secondary" : "disabled"}
															onClick={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.isRentedMenu = !settingsMarket.WalletPurchaseOffers.isRentedMenu;
																setSettingsMarket(settings);
															}}
														/>
													</Box>
													{settingsMarket.WalletPurchaseOffers.isRentedMenu && (
														<>
															<TokenAmountShort
																value={settingsMarket.WalletPurchaseOffers.minRented}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.minRented = newValue;
																	setSettingsMarket(settings);
																	if (
																		settings.WalletPurchaseOffers.maxRented < settings.WalletPurchaseOffers.minRented ||
																		!settings.WalletPurchaseOffers.maxRented
																	)
																		settings.WalletPurchaseOffers.maxRented = settings.WalletPurchaseOffers.minRented + 0.1;
																}}
																min={0}
																max={100}
																step={0.01}
																title='Min'
															/>
															<TokenAmountShort
																value={settingsMarket.WalletPurchaseOffers.maxRented}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.maxRented = newValue;
																	setSettingsMarket(settings);
																	if (
																		settings.WalletPurchaseOffers.maxRented < settings.WalletPurchaseOffers.minRented ||
																		!settings.WalletPurchaseOffers.maxRented
																	)
																		settings.WalletPurchaseOffers.maxRented = settings.WalletPurchaseOffers.minRented;
																}}
																min={0}
																max={100}
																step={0.01}
																title='Max'
															/>
														</>
													)}
												</Box>
											</TableCell>
										)}

										{/* Rent Start */}
										{settingsMarket.WalletPurchaseOffers.showRentStart && (
											<TableCell sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
												<Box sx={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "right" }}>
													<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
														<TuneOutlinedIcon
															sx={{ ml: 2, fontSize: 30 }}
															color={settingsMarket.WalletPurchaseOffers.isRentStartMenu ? "secondary" : "disabled"}
															onClick={() => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.isRentStartMenu = !settingsMarket.WalletPurchaseOffers.isRentStartMenu;
																setSettingsMarket(settings);
															}}
														/>
													</Box>
													{settingsMarket.WalletPurchaseOffers.isRentStartMenu && (
														<>
															<RadioSettingShort
																value={settingsMarket.WalletPurchaseOffers.isRentStart ? settingsMarket.WalletPurchaseOffers.isRentStart : false}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.isRentStart = newValue;
																	settings.WalletPurchaseOffers.isnotRentStart = false;
																	setSettingsMarket(settings);
																}}
																title={"<= " + new Date().toISOString().slice(0, 10)}
															/>
															<RadioSettingShort
																value={
																	settingsMarket.WalletPurchaseOffers.isnotRentStart ? settingsMarket.WalletPurchaseOffers.isnotRentStart : false
																}
																onChange={(newValue) => {
																	let settings = JSON.parse(JSON.stringify(settingsMarket));
																	settings.WalletPurchaseOffers.isRentStart = false;
																	settings.WalletPurchaseOffers.isnotRentStart = newValue;
																	setSettingsMarket(settings);
																}}
																title={">= " + new Date().toISOString().slice(0, 10)}
															/>
														</>
													)}
												</Box>
											</TableCell>
										)}

										{/* Nb, checkBox, SC, Coin */}
										<TableCell sx={{ pl: 1, pr: 0, py: 1 }} colSpan={8}></TableCell>

										{/* Offer Amount */}
										{/* <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isOfferAmount ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isOfferAmount = !settingsMarket.WalletPurchaseOffers.isOfferAmount;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isOfferAmountMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isOfferAmountMenu = !settingsMarket.WalletPurchaseOffers.isOfferAmountMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isOfferAmountMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minOfferAmount}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minOfferAmount = newValue;
																if (
																	settings.WalletPurchaseOffers.maxOfferAmount < settings.WalletPurchaseOffers.minOfferAmount ||
																	!settings.WalletPurchaseOffers.maxOfferAmount
																)
																	settings.WalletPurchaseOffers.maxOfferAmount = settings.WalletPurchaseOffers.minOfferAmount + 0.1;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxOfferAmount}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxOfferAmount = newValue;
																if (
																	settings.WalletPurchaseOffers.maxOfferAmount < settings.WalletPurchaseOffers.minOfferAmount ||
																	!settings.WalletPurchaseOffers.maxOfferAmount
																)
																	settings.WalletPurchaseOffers.maxOfferAmount = settings.WalletPurchaseOffers.minOfferAmount;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell> */}

										{/* Offer Price */}
										{/* <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isNewPrice ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isNewPrice = !settingsMarket.WalletPurchaseOffers.isNewPrice;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isNewPriceMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isNewPriceMenu = !settingsMarket.WalletPurchaseOffers.isNewPriceMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isNewPriceMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minNewPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minNewPrice = newValue;
																if (
																	settings.WalletPurchaseOffers.maxNewPrice < settings.WalletPurchaseOffers.minNewPrice ||
																	!settings.WalletPurchaseOffers.maxNewPrice
																)
																	settings.WalletPurchaseOffers.maxNewPrice = settings.WalletPurchaseOffers.minNewPrice + 0.1;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxNewPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxNewPrice = newValue;
																if (
																	settings.WalletPurchaseOffers.maxNewPrice < settings.WalletPurchaseOffers.minNewPrice ||
																	!settings.WalletPurchaseOffers.maxNewPrice
																)
																	settings.WalletPurchaseOffers.maxNewPrice = settings.WalletPurchaseOffers.minNewPrice;
																setSettingsMarket(settings);
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell> */}

										{/* New Yield */}
										{/* <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isNewYield ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isNewYield = !settingsMarket.WalletPurchaseOffers.isNewYield;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isNewYieldMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isNewYieldMenu = !settingsMarket.WalletPurchaseOffers.isNewYieldMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isNewYieldMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minNewYield}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minNewYield = newValue;
																if (
																	settings.WalletPurchaseOffers.maxNewYield < settings.WalletPurchaseOffers.minNewYield ||
																	!settings.WalletPurchaseOffers.maxNewYield
																)
																	settings.WalletPurchaseOffers.maxNewYield = settings.WalletPurchaseOffers.minNewYield + 0.1;
																setSettingsMarket(settings);
															}}
															min={0}
															max={100}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxNewYield}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxNewYield = newValue;
																if (
																	settings.WalletPurchaseOffers.maxNewYield < settings.WalletPurchaseOffers.minNewYield ||
																	!settings.WalletPurchaseOffers.maxNewYield
																)
																	settings.WalletPurchaseOffers.maxNewYield = settings.WalletPurchaseOffers.minNewYield;
																setSettingsMarket(settings);
															}}
															min={0}
															max={100}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell> */}

										{/* Ratio Price */}
										{/* <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, "&:hover": { cursor: "pointer" } }}>
											<Box sx={{ flexDirection: "column", alignItems: "center", justifyContent: "right" }}>
												<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right" }}>
													<FilterAltIcon
														sx={{ ml: 0, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRatioPrice ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRatioPrice = !settingsMarket.WalletPurchaseOffers.isRatioPrice;
															setSettingsMarket(settings);
														}}
													/>
													<TuneOutlinedIcon
														sx={{ ml: 2, fontSize: 30 }}
														color={settingsMarket.WalletPurchaseOffers.isRatioPriceMenu ? "secondary" : "disabled"}
														onClick={() => {
															let settings = JSON.parse(JSON.stringify(settingsMarket));
															settings.WalletPurchaseOffers.isRatioPriceMenu = !settingsMarket.WalletPurchaseOffers.isRatioPriceMenu;
															setSettingsMarket(settings);
														}}
													/>
												</Box>
												{settingsMarket.WalletPurchaseOffers.isRatioPriceMenu && (
													<>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.minRatioPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.minRatioPrice = newValue;
																setSettingsMarket(settings);
																if (
																	settings.WalletPurchaseOffers.maxRatioPrice < settings.WalletPurchaseOffers.minRatioPrice ||
																	!settings.WalletPurchaseOffers.maxRatioPrice
																)
																	settings.WalletPurchaseOffers.maxRatioPrice = settings.WalletPurchaseOffers.minRatioPrice + 0.1;
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Min'
														/>
														<TokenAmountShort
															value={settingsMarket.WalletPurchaseOffers.maxRatioPrice}
															onChange={(newValue) => {
																let settings = JSON.parse(JSON.stringify(settingsMarket));
																settings.WalletPurchaseOffers.maxRatioPrice = newValue;
																setSettingsMarket(settings);
																if (
																	settings.WalletPurchaseOffers.maxRatioPrice < settings.WalletPurchaseOffers.minRatioPrice ||
																	!settings.WalletPurchaseOffers.maxRatioPrice
																)
																	settings.WalletPurchaseOffers.maxRatioPrice = settings.WalletPurchaseOffers.minRatioPrice;
															}}
															min={0}
															max={1000}
															step={0.01}
															title='Max'
														/>
													</>
												)}
											</Box>
										</TableCell> */}

										<TableCell sx={{ pl: 1, pr: 1, py: 1 }} colSpan={1}></TableCell>
									</TableRow>
								</TableHead>
							)}

							<TableBody sx={{ p: 0 }}>
								{sortedOffers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token, index) => (
									// {sortedOffers.slice(0, 4).map((token, index) => (
									<WalletPurchasesOffersBody key={"WalletPurchasesOffersBody-" + (index + 1)} token={token} index={index} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Box>
		</>
	);
}

export default WalletPurchasesOffersHead;
