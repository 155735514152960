import React, { useContext, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";
import { useTheme } from "@mui/system";

import {
	Box,
	Slide,
	Dialog,
	Button,
	Typography,
	useMediaQuery,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red, blue, purple, orange } from "@mui/material/colors";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatedNumber from "./Formater/FormatedNumber";
import FormatedPrice from "./Formater/FormatedPrice";
import FormatedPercentage from "./Formater/FormatedPercentage";
import FormatedNumberDays from "./Formater/FormatedNumberDays";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

function TokenNews({ open, setOpen, token, chains }) {
	const { settingsMarket } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [timerId, setTimerId] = useState(null);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));
	const [sortBy, setSortBy] = useState({ field: "strRentStartDate", order: "desc", type: "text" });
	const theme = useTheme();

	const handleSort = (field, type) => {
		setSortBy((prevSortBy) => ({
			field,
			order: prevSortBy.field === field && prevSortBy.order === "desc" ? "asc" : "desc",
			type,
		}));
	};

	let rowTitle = 180;
	let rowValue = 130;
	let rowSeparation = 20;
	let imgSize = 190;
	let keySize = 200;
	let dateSize = 100;
	let valueSize = 390;

	if (isMatch) {
		rowTitle = 160;
		rowValue = 80;
		imgSize = 159;
		rowSeparation = 10;
		valueSize = 240;
	}

	const handleClose = () => {
		setOpen(false);
	};

	if (!open) return <></>;
	if (!token) return <></>;
	if (!token.update30.length > 0 && !token.update7.length > 0) return <></>;

	// console.log("token", isMatch, token);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			sx={{
				m: 0,
				width: 1,
				"& .MuiDialog-paper": {
					width: isMatch ? 800 : 1100,
					maxWidth: "none",
					border: 5,
					color: blue[600],
					borderRadius: 0,
				},
			}}
		>
			<DialogTitle sx={{ mt: 1, mx: 1, p: 0, border: 0 }}>
				<Box sx={{ border: 2, color: blue[600], display: "flex", flexDirection: "row", alignItems: "top", justifyContent: "top" }}>
					<Box component='img' alt='imgToken' src={token.imageLink[0]} sx={{ m: 0, width: imgSize, height: imgSize }} />
					<Box sx={{ m: 1, p: 0, border: 0 }}>
						<Box sx={{ pb: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
							<Box
								sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
								onClick={() => window.open(token.marketplaceLink, "_blank")}
							>
								<OpenInNewIcon />
							</Box>
							<Typography
								variant={isMatch ? "h6" : "h5"}
								sx={{ ml: 1, color: blue[600] }}
								onClick={() => window.open(token.marketplaceLink, "_blank")}
							>
								{token.fullName}
							</Typography>
						</Box>

						<Box sx={{ ml: 2, mt: 0, display: "flex", flexDirection: "row" }}>
							<Box sx={{ ml: 0, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='h6' sx={{ color: blue[600] }}>
									{"Expected Income"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPercentage variant='h6'  fontWeight ="bold" value={token.annualPercentageYield / 100} color={blue[600]} />
							</Box>
							<Box sx={{ ml: rowSeparation, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='h6' sx={{ color: blue[600] }}>
									{"Initial Income"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPercentage variant='h6' fontWeight ="bold" value={token.historic.init_yield / 100} color={blue[600]} />
							</Box>
						</Box>

						{!isMatch && (
							<Box sx={{ ml: 2, mt: 1, display: "flex", flexDirection: "row" }}>
								<Box sx={{ ml: 0, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{"Income Start Date"}
									</Typography>
								</Box>
								<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{token.rentStartDate.date.slice(0, 10)}
									</Typography>
								</Box>
								<Box sx={{ ml: rowSeparation, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{"Last Valuation Date"}
									</Typography>
								</Box>
								<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
									<Typography variant='body2' sx={{ color: blue[600] }}>
										{token.historic.prices[token.historic.prices.length - 1].timsync.slice(0, 10)}
									</Typography>
								</Box>
							</Box>
						)}

						<Box sx={{ ml: 2, mt: 1, display: "flex", flexDirection: "row" }}>
							<Box sx={{ ml: 0, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Year income per Token"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPrice variant='body2' value={token.netRentYearPerToken} color={blue[600]} />
							</Box>
							<Box sx={{ ml: rowSeparation, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Initial income"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPrice variant='body2' value={(token.historic.init_price * token.historic.init_yield) / 100} color={blue[600]} />
							</Box>
						</Box>

						<Box sx={{ ml: 2, mt: 1, display: "flex", flexDirection: "row" }}>
							<Box sx={{ ml: 0, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Price"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPrice variant='body2' value={token.tokenPrice} color={blue[600]} />
							</Box>
							<Box sx={{ ml: rowSeparation, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Initial token Price"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedPrice variant='body2' value={token.historic.init_price} color={blue[600]} />
							</Box>
						</Box>

						<Box sx={{ ml: 2, mt: 1, display: "flex", flexDirection: "row" }}>
							<Box sx={{ ml: 0, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Holder Tokens"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{token.amount}
								</Typography>
							</Box>
							<Box sx={{ ml: rowSeparation, width: rowTitle, display: "flex", alignItems: "center", justifyContent: "start" }}>
								<Typography variant='body2' sx={{ color: blue[600] }}>
									{"Total Tokens"}
								</Typography>
							</Box>
							<Box sx={{ ml: 0, width: rowValue, display: "flex", alignItems: "center", justifyContent: "end" }}>
								<FormatedNumber variant='body2' value={token.totalTokens} color={blue[600]} />
							</Box>
						</Box>
					</Box>
				</Box>
				<TableContainer sx={{ mt: 2 }}>
					<Table sx={{ width: 1, background: "background.paper" }} aria-label='sticky table'>
						<TableHead>
							<TableRow
								sx={{
									borderBottom: `2px solid ${theme.palette.primary.main}`,
									backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
									"&:last-child th": { borderBottom: 0 },
								}}
							>
								<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: dateSize, minWidth: dateSize }}>
									<Typography variant='body2' style={{ fontWeight: "bold" }}>
										Date
									</Typography>
								</TableCell>
								<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: keySize, minWidth: keySize }}>
									<Typography variant='body2' style={{ fontWeight: "bold" }}>
										Key
									</Typography>
								</TableCell>
								<TableCell align='left' sx={{ pl: 0, pr: 1, py: 1, width: valueSize, minWidth: valueSize }}>
									<Typography variant='body2' style={{ fontWeight: "bold" }}>
										Old Value
									</Typography>
								</TableCell>
								<TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, width: "auto" }}>
									<Typography variant='body2' style={{ fontWeight: "bold" }}>
										New Value
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
			</DialogTitle>
			<DialogContent sx={{ mx: 1, p: 0, border: 1 }}>
				<Box sx={{ px: 0, border: 0, color: blue[600], display: "flex", flexDirection: "row", alignItems: "top", justifyContent: "top" }}>
					<TableContainer sx={{ mt: 0 }}>
						<Table sx={{ width: 1, background: "background.paper" }} aria-label='sticky table'>
							{/* <TableHead>
								<TableRow
									sx={{
										borderBottom: `2px solid ${theme.palette.primary.main}`,
										backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
										"&:last-child th": { borderBottom: 0 },
									}}
								>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Key
										</Typography>
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Old Value
										</Typography>
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											New Value
										</Typography>
									</TableCell>
									<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1, width: 100 }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Date
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead> */}
							{token.update30.length > 0 ?(<TableBody>
								{token.update30.map((t) => (
									<TableRow>
										<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: dateSize, minWidth: dateSize }}>
											<Typography variant='body2'>{t.timsync.slice(0, 10)}</Typography>
										</TableCell>
										<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: keySize, minWidth: keySize }}>
											<Typography variant='body2'>{t.key}</Typography>
										</TableCell>
										<TableCell align='left' sx={{ pl: 0, pr: 1, py: 1, width: valueSize, minWidth: valueSize, maxWidth: valueSize }}>
											<Typography variant='body2'>{t.old_value}</Typography>
										</TableCell>
										<TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, width: "auto" }}>
											<Typography variant='body2'>{t.new_value}</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>):(<TableBody>
								{token.update7.map((t) => (
									<TableRow>
										<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: dateSize, minWidth: dateSize }}>
											<Typography variant='body2'>{t.timsync.slice(0, 10)}</Typography>
										</TableCell>
										<TableCell align='center' sx={{ pl: 0, pr: 1, py: 1, width: keySize, minWidth: keySize }}>
											<Typography variant='body2'>{t.key}</Typography>
										</TableCell>
										<TableCell align='left' sx={{ pl: 0, pr: 1, py: 1, width: valueSize, minWidth: valueSize, maxWidth: valueSize }}>
											<Typography variant='body2'>{t.old_value}</Typography>
										</TableCell>
										<TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, width: "auto" }}>
											<Typography variant='body2'>{t.new_value}</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>)}
							
						</Table>
					</TableContainer>
				</Box>

				<DialogContentText id='alert-dialog-slide-description' sx={{ m: 1, border: 3, color: blue[500] }}>
					{/* <Box sx={{ ml: 0, width: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
						<Button onClick={handleClose}>Close</Button>
					</Box> */}
				</DialogContentText>
			</DialogContent>
			{/* <Box sx={{ ml: 0, width: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
				<Button onClick={handleClose}>Close</Button>
			</Box> */}
			{/* <DialogActions sx={{ m: 1, border: 3, color: red[500] }}> */}
			{/* <Button onClick={handleClose}>Close</Button> */}
			{/* </DialogActions> */}
		</Dialog>
	);
}

export default TokenNews;
