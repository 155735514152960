import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MyThemeContext } from "../../context/theme-context";
import { Box, Typography, Menu, MenuItem, ListItemButton, ListItemIcon, Tooltip, Divider } from "@mui/material";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";

import Settings from "@mui/icons-material/Settings";

function SettingsMenu(props) {
	const { themeState, setThemeState } = useContext(MyThemeContext);

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		props.menuClose();
	};

	useEffect(() => {
		if (props.open) {
			setOpen(true);
		}
	}, [props]);

	return (
		<Box>
			<Menu
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "top" }}
				sx={{ marginTop: 5 }}
			>
				<Tooltip title={themeState ? "Change to Light Theme" : "Change to Dark Theme"}>
					<MenuItem onClick={() => setThemeState(!themeState)}>
						<ListItemIcon>
							{themeState && <LightModeRoundedIcon />}
							{!themeState && <DarkModeRoundedIcon />}
						</ListItemIcon>
						<Typography variant='body2'>Change Theme</Typography>
					</MenuItem>
				</Tooltip>
				<Divider />
				<Tooltip title='Modify all settings'>
					<MenuItem onClick={handleClose}>
						<ListItemButton component={Link} to={"/settings"}>
							<ListItemIcon>
								<Settings fontSize='small' />
							</ListItemIcon>
							<Typography variant='body2'>All Settings</Typography>
						</ListItemButton>
					</MenuItem>
				</Tooltip>
			</Menu>
		</Box>
	);
}

export default SettingsMenu;
