import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Typography, Paper, Select, MenuItem } from "@mui/material";

import TablePropertyPriceTokensMap from "./TablePropertyPriceTokensMap";
import TablePropertyPriceSectorsMap from "./TablePropertyPriceSectorsMap";

function TablePropertyPrice() {
	const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
	const [selectedCountry, setSelectedCountry] = useState(0);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedSector, setSelectedSector] = useState(null);
	const [selectedCalculType, setSelectedCalculType] = useState("Door");
	const calculType = ["Door", "sqft", "m2"];

	useEffect(() => {
		if (selectedCountry === null) {
			setSelectedCountry(0);
		}
		if (selectedCountry === 0) {
			setSelectedSector(null);
			setSelectedCity(null);
		}
	}, [selectedCountry]);
	useEffect(() => {
		if (selectedCity === null) {
			setSelectedSector(null);
		}
		if (selectedCity === 0) {
			setSelectedSector(null);
		}
	}, [selectedCity]);

	if (!realTokens) return <></>;
	if (settingsStats.selectedPropertyType === null) return <></>;
	if (!DEFAULT_PROPERTY_TYPE) return <></>;
	if (realTokens.list.length === 0) return <></>;
	let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];
	if (settingsStats.selectedPropertyType > 0) tokens = tokens.filter((data) => data.propertyType === settingsStats.selectedPropertyType);

	const CountryList = tokens
		.reduce(
			(acc, token, index) => {
				const parts = token.fullName.split(",").map((part) => part.trim());
				let country;

				if (parts.length === 3 || (parts.length === 4 && !isNaN(parts[3]))) {
					if (parts.length === 4 && !isNaN(parts[3])) {
						// console.log("Create CountryList warning: ", token.fullName);
					}
					country = "USA";
				} else if (parts.length === 4 && isNaN(parts[3])) {
					country = parts[3];
				} else {
					console.log("Create CountryList error: ", token.fullName);
					country = "Error";
				}
				// Find an existing entry for the country or create a new one
				let countryEntry = acc.find((entry) => entry.label === country);
				if (!countryEntry) {
					countryEntry = { id: acc.length, count: 0, label: country, tokens: [] };
					acc.push(countryEntry);
				}
				// Increment the count and add the token
				countryEntry.count += 1;
				countryEntry.tokens.push(token);
				return acc;
			},
			[{ id: 0, count: 0, label: "All Countries", tokens: [] }]
		)
		.sort((a, b) => {
			if (a.id > 0 && b.id > 0) return a.label.localeCompare(b.label);
			return false;
		});
	CountryList.forEach((item) => {
		if (item.id > 0) {
			CountryList[0].count += item.count;
			CountryList[0].tokens.push(...item.tokens);
		}
	});

	const cityTokens = CountryList[0] ? CountryList[0].tokens : tokens;
	const CityList = cityTokens
		.reduce(
			(acc, token) => {
				const parts = token.fullName.split(",").map((part) => part.trim());
				let city, countryLabel;

				if (parts.length === 3 || parts.length === 4) {
					city = parts[1];
					countryLabel = parts.length === 3 ? "USA" : parts[3]; // Determine the country label
				} else {
					console.log("Create CityList error: ", token.fullName);
					city = "Error";
					countryLabel = "Error";
				}
				// Find the corresponding country ID from CountryList
				const countryEntryFromCountryList = CountryList.find((entry) => entry.label === countryLabel);
				const countryId = countryEntryFromCountryList ? countryEntryFromCountryList.id : -1; // -1 or another identifier for error handling
				// Find an existing entry for the city or create a new one
				let cityEntry = acc.find((entry) => entry.label === city);
				if (!cityEntry) {
					cityEntry = { id: acc.length, count: 0, label: city, countryId: countryId, tokens: [] }; //countryIds: [] };
					acc.push(cityEntry);
				}
				// Increment the count and add the country ID
				cityEntry.count += 1;
				cityEntry.tokens.push(token);
				return acc;
			},
			[{ id: 0, count: 0, label: "All Cities", countryId: 0, tokens: [] }]
		)
		.sort((a, b) => {
			if (a.id > 0 && b.id > 0) return a.label.localeCompare(b.label);
			return false;
		})
		.filter((c) => {
			if (c.id > 0) return selectedCountry > 0 ? c.countryId === selectedCountry : true;
			return c;
		});
	CityList.forEach((item) => {
		if (item.id > 0) {
			CityList[0].count += item.count;
			CityList[0].tokens.push(...item.tokens);
		}
	});

	const sectorTokens = CityList[0] ? CityList[0].tokens : tokens;
	const SectorList = sectorTokens
		.reduce(
			(acc, token) => {
				const parts = token.fullName.split(",").map((part) => part.trim());
				let sector, cityLabel;

				if (parts.length === 3 || (parts.length === 4 && !isNaN(parts[3]))) {
					sector = parts[2];
					if (parts.length === 4 && !isNaN(parts[3])) {
						sector = parts[2] + ", " + parts[3];
					}
					cityLabel = parts[1];
				} else if (parts.length === 4 && isNaN(parts[3])) {
					sector = parts[2];
					cityLabel = parts[1];
				} else {
					console.log("Create SectorList error: ", token.fullName);
					sector = "Error";
					cityLabel = "Error";
				}
				// Find the corresponding country ID from CountryList
				const sectorEntryFromCityList = CityList.find((entry) => entry.label === cityLabel);
				const cityId = sectorEntryFromCityList ? sectorEntryFromCityList.id : -1; // -1 or another identifier for error handling
				// Find an existing entry for the city or create a new one
				// console.log("debug SectorList: ", sector, cityLabel, "fullName", token.fullName);

				let sectorEntry = acc.find((entry) => entry.label === sector);
				if (!sectorEntry) {
					sectorEntry = { id: acc.length, count: 0, label: sector, cityId: cityId, tokens: [] }; //countryIds: [] };
					acc.push(sectorEntry);
				}
				// Increment the count and add the country ID
				sectorEntry.count += 1;
				sectorEntry.tokens.push(token);
				return acc;
			},
			[{ id: 0, count: 0, label: "All Sectors", cityId: 0, tokens: [] }]
		)
		.sort((a, b) => {
			if (a.id > 0 && b.id > 0) return a.label.localeCompare(b.label);
			return false;
		})
		.filter((c) => {
			if (c.id > 0) return selectedCity > 0 ? c.cityId === selectedCity : true;
			return c;
		});
	SectorList.forEach((item) => {
		if (item.id > 0) {
			SectorList[0].count += item.count;
			SectorList[0].tokens.push(...item.tokens);
		}
	});
	// console.log("CountryList", selectedCountry, CountryList[0]);
	// console.log("CityList", selectedCity, CityList[0]);
	// console.log("SectorList", selectedSector, SectorList[0]);

	let tokensMap = [];

	if (selectedCountry === 0) tokensMap = CountryList;
	else {
		tokensMap = CityList;
		if (selectedCity !== null)
			if (selectedCity >= 0) tokensMap = SectorList;
			else if (selectedSector !== null) if (selectedSector >= 0) tokensMap = SectorList.filter((s) => s.id === selectedSector);
	}

	let tokenMapTitre = CountryList.filter((s) => s.id === 0)[0];
	if (selectedCountry > 0 && selectedCity !== null && selectedSector !== null) tokenMapTitre = SectorList.filter((s) => s.id === selectedSector)[0];
	else if (selectedCountry > 0 && selectedCity !== null) tokenMapTitre = CityList.filter((s) => s.id === selectedCity)[0];
	else if (selectedCountry > 0) tokenMapTitre = CountryList.filter((s) => s.id === selectedCountry)[0];

	tokensMap.map((tokenMap) => {
		let price = 0;
		let door = 0;
		let sqft = 0;
		tokenMap.tokens.forEach((token) => {
			price += token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve;
			door += token.totalUnits;
			sqft += token.squareFeet;
			// if (!token.squareFeet) console.log("sqft error", token.fullName, token);
		});
		if (selectedCalculType === "Door") tokenMap.avgPrice = parseInt(price / door);
		if (selectedCalculType === "sqft" || selectedCalculType === "m2") tokenMap.avgPrice = price / sqft;
		tokenMap.minPrice = tokenMap.avgPrice;
		tokenMap.maxPrice = tokenMap.avgPrice;
		tokenMap.tokens.forEach((token) => {
			if (selectedCalculType === "Door") {
				if (tokenMap.minPrice > (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits)
					tokenMap.minPrice = (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits;
				if (tokenMap.maxPrice < (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits)
					tokenMap.maxPrice = (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits;
			}
			if (selectedCalculType === "sqft" || selectedCalculType === "m2") {
				if (tokenMap.minPrice > (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet)
					tokenMap.minPrice = (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet;
				if (tokenMap.maxPrice < (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet)
					tokenMap.maxPrice = (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet;
			}
		});
		if (selectedCalculType === "m2") {
			tokenMap.avgPrice = tokenMap.avgPrice / 0.092903;
			tokenMap.minPrice = tokenMap.minPrice / 0.092903;
			tokenMap.maxPrice = tokenMap.maxPrice / 0.092903;
		}
		return tokenMap;
	});

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};

	if (!CountryList[0]) return <></>;
	if (!CityList[0]) return <></>;
	if (!SectorList[0]) return <></>;

	// console.log("TablePrice",  tokensMap);

	return (
		<Paper elevation={3} sx={{ mt: 1, px: 2, minWidth: 500, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ width: 1, display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Property Type
				</Typography>
				<Select
					sx={{ mt: 0, ml: 0, minWidth: 125 }}
					value={settingsStats.selectedPropertyType}
					onChange={(e) => selectedPropertyTypeChange(e.target.value)}
					size='small'
				>
					{DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith("Text_")).map((p) => (
						<MenuItem key={p.index} value={p.index}>
							{p.label}
						</MenuItem>
					))}
				</Select>

				<Typography variant='h5' sx={{ m: 1, ml: 2, color: "primary.main" }}>
					by
				</Typography>
				<Select sx={{ mt: 0, ml: 0, minWidth: 80 }} value={selectedCalculType} onChange={(e) => setSelectedCalculType(e.target.value)} size='small'>
					{calculType.map((calcul) => (
						<MenuItem key={calcul} value={calcul}>
							{calcul}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 2, color: "primary.main" }}>
					in
				</Typography>
				<Select sx={{ mt: 0, ml: 0, minWidth: 125 }} value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} size='small'>
					{CountryList.map((p) => (
						<MenuItem key={p.id} value={p.id}>
							{p.label}
						</MenuItem>
					))}
				</Select>
				<Box
					sx={{
						flexWrap: "wrap",
						mt: 1,
						ml: 8,
						p: 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{selectedSector !== null && (
						<TablePropertyPriceTokensMap
							tokensMap={tokensMap}
							title={tokenMapTitre}
							menuChange={
								selectedSector !== null ? null : selectedCity !== null ? setSelectedSector : selectedCountry ? setSelectedCity : setSelectedCountry
							}
							menuBackChange={
								selectedSector !== null
									? setSelectedSector
									: selectedCity !== null
									? setSelectedCity
									: selectedCountry > 0
									? setSelectedCountry
									: null
							}
						/>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					flexWrap: "wrap",
					m: 1,
					p: 0,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 4,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{selectedSector === null && (
					<TablePropertyPriceTokensMap
						tokensMap={tokensMap}
						title={tokenMapTitre}
						menuChange={
							selectedSector !== null ? null : selectedCity !== null ? setSelectedSector : selectedCountry ? setSelectedCity : setSelectedCountry
						}
						menuBackChange={
							selectedSector !== null ? setSelectedSector : selectedCity !== null ? setSelectedCity : selectedCountry > 0 ? setSelectedCountry : null
						}
					/>
				)}
			</Box>
			<Box
				sx={{
					flexWrap: "wrap",
					m: 1,
					p: 0,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 4,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{selectedSector != null && (
					<TablePropertyPriceSectorsMap
						tokensMap={tokensMap.filter((t) => t.id === selectedSector)}
						parent={tokenMapTitre}
						selectedCalculType={selectedCalculType}
					/>
				)}
			</Box>
		</Paper>
	);
}

export default TablePropertyPrice;
