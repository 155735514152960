import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { Web3Context } from "../../../context/web3-context";
import Web3 from "web3";

import { Typography,  Box, Avatar, TextField, Tooltip, CircularProgress } from "@mui/material";
import { green, red, purple, blue } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import FormatedNumber from "../../Common/Formater/FormatedNumberDigit";

const MenuItemAllowanceCoin = ({ index, coin, handleCloseMenu }) => {
	const { web3active, web3functions, web3smartContract } = useContext(Web3Context);
	const { settingsMarket, setSettingsMarket, blockchainsClient, settingsWallet } = useContext(SettingsContext);

	const excludeCoins = ["xDAI", "borowWxDAIRMM", "borowWxDAIRMMv3", "borowUSDCRMMv3", "lendWxDAIRMM", "RWA", "SOON"];
	// const excludeCoins = [
	// 	"xDAI",
	// 	"sDAI",
	// 	"borowWxDAIRMM",
	// 	"borowWxDAIRMMv3",
	// 	"borowUSDCRMMv3",
	// 	"lendWxDAIRMM",
	// 	"RWA",
	// 	"SOON",
	// 	"MATIC",
	// 	"MPS",
	// 	"BORG",
	// 	"WBTC",
	// 	"WETH",
	// 	"wstETH",
	// 	"EURe",
	// 	"USDT",
	// ];

	const [allowance, setAllowance] = useState(null);
	const [editAllowance, setEditAllowance] = useState(null);
	const [balance, setBalance] = useState(null);
	const [digit, setDigit] = useState(18);
	const [isEdit, setIsEdit] = useState(false);
	const [waitTransaction, setWaitTransaction] = useState(false);

	const bc = blockchainsClient["Gnosis"];
	const _web3 = new Web3(bc.rpc);
	const ERC20ABI = web3smartContract.ERC20ABI;

	useEffect(() => {
		const getAllowanceCoin = async (coinAddress) => {
			try {
				if (settingsMarket?.WalletPurchaseOffers?.useSc && !excludeCoins.includes(coin.name)) {
					let spender = web3smartContract.yamAddress;
					if (settingsMarket.WalletPurchaseOffers.useSc.id === "swapcat") spender = web3smartContract.swapcatAddress;

					const coinContract = new _web3.eth.Contract(ERC20ABI, coinAddress);
					const allowance = Number(await coinContract.methods.allowance(settingsWallet.selectedWallet.toLowerCase(), spender).call());
					const balance = Number(await coinContract.methods.balanceOf(settingsWallet.selectedWallet.toLowerCase()).call());
					const decimals = Number(await coinContract.methods.decimals().call());
					const formattedAllowance = allowance / 10 ** decimals;
					const formattedBalance = balance / 10 ** decimals;

					// console.log(coin.name, formattedAllowance, decimals);

					setAllowance(formattedAllowance);
					setEditAllowance(formattedAllowance);
					setBalance(formattedBalance);
					setDigit(decimals);
				}
			} catch (error) {
				console.log(coin.name, error);
			}
		};

		getAllowanceCoin(coin.address);

		const intervalId = setInterval(() => {
			// console.log(coin.name,allowance)
			if (allowance === null) {
				getAllowanceCoin(coin.address);
			} else {
				clearInterval(intervalId);
			}
		}, 15000);

		return () => clearInterval(intervalId);
	}, [settingsMarket.WalletPurchaseOffers.useSc]);

	const setAllowanceCoin = async (coinAddress) => {
		const callback = (result) => {
			if (result) {
				console.log("New allowance set successfully:", result);
				setIsEdit(false);
				handleCloseMenu(null);
			}
			setWaitTransaction(false);
		};

		setWaitTransaction(true);
		const sc = settingsMarket.WalletPurchaseOffers.useSc;
		if (sc.id === "yam") {
			await web3functions.tokenApprove(coinAddress, editAllowance, web3smartContract.yamAddress, callback);
		}
		if (sc.id === "swapcat") {
			await web3functions.tokenApprove(coinAddress, editAllowance, web3smartContract.swapcatAddress, callback);
		}
	};

	if (excludeCoins.includes(coin.name)) return null;
	if (!balance>0) return null;
	
	const SIZE_ICON = 20;

	const logo_pitsbi_sizeW = 20;
	const logo_pitsbi_sizeH = 25;

	return (
		// <MenuItem key={coin.id} value={coin.address}>
		<Box sx={{ m: 0, px: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
			<>
				<Box sx={{ m: 0, py: 0.2, minWidth: 200, display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
					<Avatar
						key={coin.name}
						alt={coin.name}
						src={coin.imgToken}
						sx={{ mr: 1, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }}
						onClick={() => {
							setIsEdit(!isEdit);
							console.log("object", coin);
							setSettingsMarket((prevSettings) => ({
								...prevSettings,
								WalletPurchaseOffers: {
									...prevSettings.WalletPurchaseOffers,
									useCoin: coin,
								},
							}));
							handleCloseMenu(null);
						}}
					/>

					{isEdit ? (
						<Tooltip title={`Wallet Amount: ${balance}`}>
							<TextField
								value={editAllowance}
								onFocus={(e) => e.target.select()}
								onChange={(e) => {
									// console.log("onChange", e.target.value);
									if (!isNaN(e.target.value)) setEditAllowance(e.target.value);
								}}
								onKeyDown={(e) => {
									e.stopPropagation(); // Prevent menu navigation on keydown when editing
								}}
								sx={{ width: 100 }}
								// variant='outlined'
								variant='standard'
								type='number'
								size='small'
								inputProps={{
									max: 9999,
									// step: 0.1,
									style: {
										textAlign: "center",
									},
								}}
							/>
						</Tooltip>
					) : (<Tooltip title={`Wallet Amount: ${balance}`}>
						<Box
							onClick={() => {
								// setIsEdit(!isEdit);
								console.log("object", coin);
								setSettingsMarket((prevSettings) => ({
									...prevSettings,
									WalletPurchaseOffers: {
										...prevSettings.WalletPurchaseOffers,
										useCoin: coin,
									},
								}));
							}}
						>
							{allowance === null ? (
								<CircularProgress sx={{ m: 0, px: 0, fontSize: SIZE_ICON, color: red[700] }} size='1rem' />
							) : (
								
									<FormatedNumber value={allowance} color={"primary.main"} digit={2} />
								
							)}
						</Box></Tooltip>
					)}

					{isEdit ? (
						<Typography variant='body2' sx={{ ml: 1, color: "inherit" }}>
							{coin.name.length > 9 ? `${coin.name.slice(0, 6)}...` : `${coin.name}`}
						</Typography>
					) : (
						<Typography variant='body2' sx={{ ml: 1, color: "inherit" }}>
							{coin.name}
						</Typography>
					)}

					{/* </Box> */}
				</Box>
				<Box sx={{ ml: "auto" }}>
					{isEdit ? (
						waitTransaction ? (
							<CircularProgress sx={{ m: 0, px: 0, fontSize: SIZE_ICON, color: green[600] }} size='1rem' />
						) : web3active ? (
							<CheckCircleOutlineIcon
								sx={{ ml: "auto", fontSize: SIZE_ICON, color: green[600], cursor: "pointer" }}
								onClick={() => {
									setAllowanceCoin(coin.address);
								}}
							/>
						) : (
							<Avatar
								alt='Logo'
								src='/logo-pitsbi2.png'
								sx={{ m: 0, p: 0, width: logo_pitsbi_sizeW, height: logo_pitsbi_sizeH, borderRadius: 0, border: 0,cursor: "pointer" }}
								onClick={() => web3functions.connect()}
							/>
						)
					) : (
						<EditIcon
							sx={{ fontSize: SIZE_ICON, color: blue[600], cursor: "pointer" }}
							onClick={() => {
								setIsEdit(!isEdit);
								console.log("object", coin);
								setSettingsMarket((prevSettings) => ({
									...prevSettings,
									WalletPurchaseOffers: {
										...prevSettings.WalletPurchaseOffers,
										useCoin: coin,
									},
								}));
							}}
						/>
					)}
				</Box>
			</>
		</Box>
		// </MenuItem>
	);
};

export default MenuItemAllowanceCoin;
