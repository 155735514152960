import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { SettingsContext } from "../../../context/settings-context";
import { Select, MenuItem, Box, Typography, Paper } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// Enregistrer les éléments nécessaires pour le graphique en secteurs
Chart.register(ArcElement, Tooltip, Legend);

function PiePropertyType() {
	const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
	const [tokens, setTokens] = useState(null);
	const [propertyCount, setPropertyCount] = useState(null);
	const [chartData, setChartData] = useState(null);

	// Accéder au thème MUI
	const theme = useTheme();


	useEffect(() => {
		if (realTokens) {
			let tokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
			];
			setTokens(tokens);
		}
	}, [realTokens]);

	useEffect(() => {
		if (tokens) {
			const propertyTypes_nonOrdered = Array.from(new Set(tokens.map((data) => parseInt(data.propertyType)))).sort((a, b) => a - b);
			const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));
			let propertyCount = propertyTypes.map((type) => ({
				realtokens: tokens.filter((data) => DEFAULT_PROPERTY_TYPE[data.propertyType] === type).length,
				capital: tokens.reduce((acc, data) => acc + (DEFAULT_PROPERTY_TYPE[data.propertyType] === type ? data.totalInvestment : 0 || 0), 0),
			}));
			setPropertyCount(propertyCount);
		}
	}, [tokens]);

	useEffect(() => {
		if (propertyCount && tokens) {
			const propertyTypes_nonOrdered = Array.from(new Set(tokens.map((data) => parseInt(data.propertyType)))).sort((a, b) => a - b);
			const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));

			const totalProperties = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0 || 0), 0));
			const totalCapital = parseInt(tokens.reduce((acc, data) => acc + (data.totalInvestment > 0 ? data.totalInvestment : 0 || 0), 0));

			let data = propertyCount.map((val, index) =>
				settingsStats.selectedCalculType === "RealTokens"
					? {
							realtokens: val.realtokens,
							value: parseFloat(((100 * val.realtokens) / totalProperties).toFixed(1)),
							label: `${propertyTypes[index].label} (${val.realtokens})`,
					  }
					: {
							realtokens: val.realtokens,
							value: parseFloat(((100 * val.capital) / totalCapital).toFixed(1)),
							label: `${propertyTypes[index].label} (${
								val.capital > 1000000 ? (val.capital / 1000000).toFixed(2) + "M$" : (val.capital / 1000).toFixed(2) + "k$"
							})`,
					  }
			);
			const sortedData = data.sort((a, b) => b.value - a.value);

			const chartData = {
				labels: sortedData.map((d) => d.label),
				datasets: [
					{
						data: sortedData.map((d) => d.value),
						backgroundColor: sortedData.map((_, i) => `hsl(${(i * 360) / sortedData.length}, 100%, ${theme.palette.mode === "dark"? "30%":"50%"})`),
					},
				],
			};

			setChartData(chartData);
			// console.table(data);
		}
	}, [propertyCount, tokens, settingsStats.selectedCalculType,theme.palette.mode]);

	if (!chartData) return <></>;

	const setSettingsStatsChange = (key, newValue) => {
		// console.log("setSettingsStatsChange:", key, newValue);
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false, // Permet de contrôler directement la taille
		plugins: {
			legend: {
				position: "right",
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						// return `${context.label}: ${context.raw.toFixed(1)}%`;
						return `Percentage: ${context.raw.toFixed(1)}%`;
					},
				},
			},
		},
		layout: {
			padding: {
				top: 0, // Supprime l'espace au-dessus
				bottom: 0, // Supprime l'espace en bas
			},
		},
		// cutout: "70%", // Transforme le camembert en anneau (doughnut) en définissant la taille du centre vide
	};

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: "auto", border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Properties to
				</Typography>
				<Select
					sx={{ mr: 1, minWidth: 125 }}
					value={settingsStats.selectedCalculType}
					onChange={(e) => setSettingsStatsChange("selectedCalculType", e.target.value)}
					size='small'
				>
					{settingsStats.calculType.map((p, index) => (
						<MenuItem key={index} value={p}>
							{p}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Ratio
				</Typography>
			</Box>

			<Box sx={{ p: 1, display: "flex", justifyContent: "center", m: 0 }}>
				<Doughnut data={chartData} options={options} width={550} height={350} />
			</Box>
		</Paper>
	);
}

export default PiePropertyType;
