import React, { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { useTheme } from "@mui/system";

import { Box, Typography, IconButton, Button, Avatar, Popper } from "@mui/material";
import { red } from "@mui/material/colors";

import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedCircleProgress from "../../Common/Formater/FormatedCircleProgress";

function Header(props) {
	const { realTokens, settingsWallet } = useContext(SettingsContext);
	const theme = useTheme();

	const [openLogo, setOpenLogo] = useState(false);
	const [openRent, setOpenRent] = useState(false);
	const [timerId, setTimerId] = useState(null);
	const anchorRefLogo = useRef(null);
	const anchorRefRent = useRef(null);

	const handleMouseEnterLogo = () => {
		setOpenLogo(true);
	};

	const handleMouseLeaveLogo = () => {
		setOpenLogo(false);
	};
	
	const handleMouseEnterRent = () => {
		const id = setTimeout(() => {
			setOpenRent(true);
		}, 1000);
		setTimerId(id);
	};

	const handleMouseLeaveRent = () => {
		if (timerId) {
			clearTimeout(timerId);
			setTimerId(null);
		}
		setOpenRent(false);
	};

	useEffect(() => {
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [timerId]);

	const [countdown, setCountdown] = useState(0);
	useEffect(() => {
		let timer;
		let interval;
		let timeout = 30 * 1000;
		if (countdown > 9999) setCountdown(0);
		interval = setInterval(() => {
			setCountdown((currentCountdown) => currentCountdown + 1);
		}, timeout);

		return () => {
			if (timer) clearTimeout(timer);
			if (interval) clearInterval(interval);
		};
	}, [countdown]);

	if (!realTokens) return <></>;
	let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];

	let rent = 0;
	tokens.forEach((token) => {
		// Step 1: Sort prices
		if(!token.historic) console.log("Header",token.fullName);
		const sortedPrices = token.historic.prices.sort((a, b) => new Date(a.timsync) - new Date(b.timsync));
		// Step 2: Function to find the most recent price
		function findMostRecentPrice(timsync) {
			let mostRecentPrice = null;
			for (let price of sortedPrices) {
				if (new Date(price.timsync) <= new Date(timsync)) {
					mostRecentPrice = price.price;
				} else {
					break;
				}
			}
			return mostRecentPrice;
		}
		// Step 3: Merge data
		const updatedYields = token.historic.yields.map((yieldEntry) => {
			const mostRecentPrice = findMostRecentPrice(yieldEntry.timsync);
			return {
				...yieldEntry,
				price: mostRecentPrice,
			};
		});
		// Step 3: Calculate Rent Distribution
		updatedYields.forEach((a) => {
			if (a.days_rented > 0) rent += ((a.yield * a.price * token.totalTokens) / 36500) * a.days_rented;
		});
	});

	const referalURL = "https://realt.co/ref/Frederic%20Pitot/";
	// console.log("theme.palette.mode", theme.palette.mode);
	const RealTLogo = theme.palette.mode === "dark" ? "/images/RealT_Logo_dark.svg" : "/images/RealT_Logo.svg";

	const handleReferalURLClick = () => {
		window.open(referalURL, "_blank");
	};

	// console.log("realTokens", new Date(realTokens.last_get).toLocaleDateString(), new Date(realTokens.last_update).toLocaleTimeString());
	// console.log("settingsWallet", settingsWallet);
	return (
		<Box sx={{ width: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
			<Box sx={{ width: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				<IconButton ref={anchorRefLogo} onClick={handleReferalURLClick} onMouseEnter={handleMouseEnterLogo} onMouseLeave={handleMouseLeaveLogo}>
					<Avatar
						alt={"RealTLogo"}
						src={RealTLogo}
						fontSize='small'
						sx={{ width: 200, height: 80 }}
						imgProps={{ style: { objectFit: "contain", height: "95%", width: "auto" } }} // Ensuring the image fits within the Avatar
					/>
				</IconButton>
				<Popper open={openLogo} anchorEl={anchorRefLogo.current} placement='top'>
					<Box sx={{ ml: 2, border: 3, p: 1, bgcolor: red[700] }}>
						<Typography sx={{ fontWeight: "bold" }}>Click here and start to Earn Money!</Typography>
						{/* Add additional content or styling as needed */}
					</Box>
				</Popper>
			</Box>
			<Box
				sx={{ width: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
				ref={anchorRefRent}
				onClick={handleReferalURLClick}
				onMouseEnter={handleMouseEnterRent}
				onMouseLeave={handleMouseLeaveRent}
			>
				<FormatedPrice value={rent} variant={"h3"} color={"primary.main"} fontWeight={"bold"} />
				<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
					{realTokens.last_update && (
						<Typography variant='h6' sx={{ m: 0, color: "primary.main" }}>
							{"RealT Rents Collected updated : "}
							{new Date(realTokens.last_update).toLocaleDateString()}
							{" at "}
							{new Date(realTokens.last_update).toLocaleTimeString()} 
						</Typography>
					)}
					{realTokens.timestampUpdate && (
						<FormatedCircleProgress
							value={realTokens.timestampUpdate}
							maxValue={settingsWallet.timerApiUpdate}
							colorRefresh={"error"}
							fontWeight={"bold"}
						/>
					)}
				</Box>
				{realTokens.last_get && (
					<Typography variant='h6' sx={{ m: 0, color: "primary.main" }}>
						{"Last community API received : "}
						{new Date(realTokens.last_get).toLocaleDateString()}
						{" at "}
						{new Date(realTokens.last_get).toLocaleTimeString()} 
					</Typography>
				)}
			</Box>
			<Popper open={openRent} anchorEl={anchorRefRent.current} placement='top'>
				<Box sx={{ ml: 2, border: 3, p: 1, bgcolor: red[700] }}>
					<Typography sx={{ fontWeight: "bold" }}>Click here and start to Earn Money!</Typography>
					{/* Add additional content or styling as needed */}
				</Box>
			</Popper>
			<Box sx={{ width: 250, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				<Button
					onClick={() => {
						props.SnackbarMessage(
							"Function to create a PDF isn't completed ... Use keyboard shortcut 'CTRL + P' with perso scale set to 70%",
							"warning"
						);
						props.generatePdf();
						console.log("onClick PDF ....");
					}}
				>
					Create a PDF
				</Button>
			</Box>
		</Box>
	);
}

export default Header;
