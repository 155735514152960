import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { SettingsContext } from "../../../context/settings-context";
import { Select, Box, Typography, Paper, MenuItem, Switch } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// Enregistrer les éléments nécessaires pour le graphique en secteurs
Chart.register(ArcElement, Tooltip, Legend);

function PiePropertyDiversity() {
	const { realTokens, settingsStats, setSettingsStats } = useContext(SettingsContext);
	const [tokens, setTokens] = useState(null);
	const [chartData, setChartData] = useState(null);
	const [isCity, setIsCity] = useState(false);

	// Accéder au thème MUI
	const theme = useTheme();

	useEffect(() => {
		if (realTokens) {
			let tokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
			];
			setTokens(tokens);
		}
	}, [realTokens]);

	useEffect(() => {
		if (tokens) {
			// Fonction utilitaire pour récupérer le nom de l'état
			const getStateName = (stateCode) => {
				const states = {
					AL: "Alabama",
					AK: "Alaska",
					AZ: "Arizona",
					AR: "Arkansas",
					CA: "California",
					CO: "Colorado",
					CT: "Connecticut",
					DE: "Delaware",
					FL: "Florida",
					GA: "Georgia",
					HI: "Hawaii",
					ID: "Idaho",
					IL: "Illinois",
					IN: "Indiana",
					IA: "Iowa",
					KS: "Kansas",
					KY: "Kentucky",
					LA: "Louisiana",
					ME: "Maine",
					MD: "Maryland",
					MA: "Massachusetts",
					MI: "Michigan",
					MN: "Minnesota",
					MS: "Mississippi",
					MO: "Missouri",
					MT: "Montana",
					NE: "Nebraska",
					NV: "Nevada",
					NH: "New Hampshire",
					NJ: "New Jersey",
					NM: "New Mexico",
					NY: "New York",
					NC: "North Carolina",
					ND: "North Dakota",
					OH: "Ohio",
					OK: "Oklahoma",
					OR: "Oregon",
					PA: "Pennsylvania",
					RI: "Rhode Island",
					SC: "South Carolina",
					SD: "South Dakota",
					TN: "Tennessee",
					TX: "Texas",
					UT: "Utah",
					VT: "Vermont",
					VA: "Virginia",
					WA: "Washington",
					WV: "West Virginia",
					WI: "Wisconsin",
					WY: "Wyoming",
				};
				return states[stateCode.toUpperCase()] || "Unknown State";
			};

			const calculateCounts = (isCity) => {
				return tokens.reduce((acc, token) => {
					const key = isCity
						? token.fullName.split(",")[1].trim()
						: token.fullName.split(",").length === 3
						? getStateName(token.fullName.split(",")[2].trim().slice(0, 2))
						: token.fullName.split(",")[3].trim();

					if (!acc[key]) {
						acc[key] = { nbProperties: 1, capital: token.totalInvestment };
					} else {
						acc[key].nbProperties += 1;
						acc[key].capital += token.totalInvestment;
					}
					return acc;
				}, {});
			};
			const counts = calculateCounts(isCity);

			const totalProperties = tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0), 0);
			const totalCapital = tokens.reduce((acc, data) => acc + (data.totalInvestment > 0 ? data.totalInvestment : 0), 0);

			const data = Object.entries(counts).map(([key, count]) => ({
				label: key,
				nbProperties: counts[key].nbProperties,
				capital: parseFloat((counts[key].capital / 1000000).toFixed(2)),
				value:
					settingsStats.selectedCalculType === "RealTokens" ? (100 * count.nbProperties) / totalProperties : (100 * count.capital) / totalCapital,
			}));

			// Limiter les résultats à 30 entrées
			const limitedData = data.sort((a, b) => b.value - a.value || a.label.localeCompare(b.label)).slice(0, 30);

			const chartData = {
				labels: limitedData.map((d) =>
					settingsStats.selectedCalculType === "RealTokens" ? d.label + ` (${d.nbProperties})` : d.label + ` (${d.capital}M$)`
				),
				datasets: [
					{
						data: limitedData.map((d) => d.value),
						backgroundColor: limitedData.map(
							(_, i) => `hsl(${(i * 360) / limitedData.length}, 100%, ${theme.palette.mode === "dark" ? "30%" : "50%"})`
						),
					},
				],
			};
			setChartData(chartData);
		}
	}, [tokens, settingsStats.selectedCalculType, isCity,theme.palette.mode]);

	const setSettingsStatsChange = (key, newValue) => {
		setSettingsStats((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false, // Permet de contrôler directement la taille
		plugins: {
			legend: {
				position: "right",
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						// return `${context.label}: ${context.raw.toFixed(1)}%`;
						return `Percentage: ${context.raw.toFixed(1)}%`;
					},
				},
			},
		},
		layout: {
			padding: {
				top: 0, // Supprime l'espace au-dessus
				bottom: 0, // Supprime l'espace en bas
			},
		},
		// cutout: "70%", // Transforme le camembert en anneau (doughnut) en définissant la taille du centre vide
	};

	if (!chartData) return null;

	return (
		<Paper
			elevation={3}
			sx={{
				m: 1,
				minWidth: 850,
				border: 1,
				borderColor: "primary.main",
				borderRadius: 4,
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: 1 }}>
					<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
						Properties to
					</Typography>
					<Select
						sx={{ mr: 1, minWidth: 125 }}
						value={settingsStats.selectedCalculType}
						onChange={(e) => setSettingsStatsChange("selectedCalculType", e.target.value)}
						size='small'
					>
						{settingsStats.calculType.map((p, index) => (
							<MenuItem key={index} value={p}>
								{p}
							</MenuItem>
						))}
					</Select>
					<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
						Diversity Ratio
					</Typography>
				</Box>

				<Box sx={{ pr: 1, width: 160, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
					<Typography variant='body2' sx={{ m: 1, ml: 0, color: "primary.main" }}>
						{isCity ? "Cities (30)" : "Countries"}
					</Typography>
					<Switch size='small' checked={isCity} onChange={() => setIsCity(!isCity)} name='switchCity' />
				</Box>
			</Box>

			<Box sx={{ p: 1, display: "flex", justifyContent: "center", m: 0 }}>
				<Doughnut data={chartData} options={options} width={650} height={350} />
			</Box>
		</Paper>
	);
}

export default PiePropertyDiversity;
