import React, { useContext,useEffect } from "react";
import { SettingsContext } from "../context/settings-context";

import { Box, Typography } from "@mui/material";

import LoadingWebSite from "../components/Common/LoadingWebSite";
import Header from "../components/Header/Header";

const TITLE = "Pit's BI - Help";

function Help() {
	const { settingsLoaded } = useContext(SettingsContext);
	
  useEffect(() => {
    document.title = TITLE;
 }, []);

	return (
		<Box>
			<Header />
			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box>
					<Box  sx={{ p: 15 ,
					display:'flex',
					flexDirection:'column',
					alignItems:'center',
					justifyContent:'center'}}>
						<Typography variant='h1' color='inherit' sx={{ ml: 1 }}>
							Help
						</Typography>
						<Typography variant='h4' color='inherit' sx={{ ml: 1 }}>
							Development in progress
						</Typography>
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default Help;
