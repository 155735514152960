import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client"; // Import ReactDOM
import { SettingsContext } from "../../context/settings-context";
import { MapContainer, TileLayer, useMap, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet styles
import "leaflet.markercluster"; // Import leaflet.markercluster
import "leaflet.markercluster/dist/MarkerCluster.Default.css"; // Import cluster styles
import LoadingWebSite from "../../components/Common/LoadingWebSite";
import Header from "../../components/Header/Header";

import L from "leaflet";
import { Box, Typography, Button, Avatar } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { green, red, blue, purple, orange } from "@mui/material/colors";

import FormatedPrice from "../Common/Formater/FormatedPrice";
import FormatedPercentage from "../Common/Formater/FormatedPercentage";
import TokenInfos from "../Common/TokenInfos";
import TokenNews from "../Common/TokenNews";

// Définir l'icône de style goutte d'eau
const customIcon = L.icon({
	iconUrl: "https://cdn-icons-png.flaticon.com/512/252/252025.png", // URL de l'icône goutte d'eau (modifiable)
	iconSize: [32, 32], // Taille de l'icône
	iconAnchor: [16, 32], // Point d'ancrage de l'icône (au bas de l'icône)
	popupAnchor: [0, -32], // Position du popup par rapport à l'icône
});

function generateColoredIconUrl(color) {
	const baseUrl = "./images/maps"; // Base URL de l'icône
	return `${baseUrl}/${color}.png`;
}

// Custom Hook to add Marker Clusters to the Map
const imgSize = 100;
const iconSize = 20;
const minWidth = "450px";

const MarkerClusterGroupComponent = ({ points, setOpenInfo, setOpenNews }) => {
	const { DEFAULT_PROPERTY_TYPE, settingsMap, setSettingsMap } = useContext(SettingsContext);
	const map = useMap();
	// const [openInfo, setOpenInfo] = useState(false);
	// const [openNews, setOpenNews] = useState(false);

	// Liste des couleurs pour chaque type de propriété
	const propertyColors = settingsMap.propertyColors;
	// const rentedColors = settingsMap.rentedColors;
	// const newsColors = settingsMap.newsColors;

	const settingsMapChange = (item, newValue) => {
		// console.log("settingsMapChange",item, newValue)
		setSettingsMap((prevSettings) => ({
			...prevSettings,
			[item]: newValue,
		}));
	};

	// Génération de la collection d'icônes avec différentes couleurs
	const propertyIcons = {};
	for (const [index, color] of Object.entries(propertyColors)) {
		propertyIcons[index] = L.icon({
			iconUrl: generateColoredIconUrl(color), // Génère une URL avec la couleur
			iconSize: [iconSize, iconSize], // Taille de l'icône
			iconAnchor: [iconSize / 2, iconSize], // Point d'ancrage de l'icône (au bas de l'icône)
			popupAnchor: [0, -iconSize], // Position du popup par rapport à l'icône
		});
	}

	const rentedIcons = {};
	for (const rentedColor of settingsMap.rentedColors) {
		const { index, img } = rentedColor;
		rentedIcons[index] = L.icon({
			iconUrl: img, // Génère une URL avec la couleur
			iconSize: [iconSize, iconSize], // Taille de l'icône
			iconAnchor: [iconSize / 2, iconSize], // Point d'ancrage de l'icône (au bas de l'icône)
			popupAnchor: [0, -iconSize], // Position du popup par rapport à l'icône
		});
	}

	const newsIcons = {};
	for (const newsColor of settingsMap.newsColors) {
		const { index, img } = newsColor;
		newsIcons[index] = L.icon({
			iconUrl: img, // Génère une URL avec la couleur
			iconSize: [iconSize, iconSize], // Taille de l'icône
			iconAnchor: [iconSize / 2, iconSize], // Point d'ancrage de l'icône (au bas de l'icône)
			popupAnchor: [0, -iconSize], // Position du popup par rapport à l'icône
		});
	}

	const ownedIcons = {};
	for (const ownedColor of settingsMap.ownedColors) {
		const { index, img } = ownedColor;
		ownedIcons[index] = L.icon({
			iconUrl: img, // Génère une URL avec la couleur
			iconSize: [iconSize, iconSize], // Taille de l'icône
			iconAnchor: [iconSize / 2, iconSize], // Point d'ancrage de l'icône (au bas de l'icône)
			popupAnchor: [0, -iconSize], // Position du popup par rapport à l'icône
		});
	}

	useEffect(() => {
		const markers = L.markerClusterGroup({
			maxClusterRadius: 80, // Augmentez ou diminuez cette valeur pour ajuster le groupement
			disableClusteringAtZoom: 11, // Désactive le groupement au zoom 15 ou plus
			spiderfyOnMaxZoom: false, // Active le spiderfy (dispersion des marqueurs) au maximum du zoom
			showCoverageOnHover: true, // Affiche la zone de couverture des clusters au survol
			removeOutsideVisibleBounds: true, // Supprime les clusters hors des limites visibles
		});

		points.forEach((point) => {
			let marker;
			if (settingsMap.filterByOwnedType) marker = L.marker(point.position, { icon: point.token ? ownedIcons[point.token.ownedType] : customIcon });
			if (settingsMap.filterByPropertyType)
				marker = L.marker(point.position, { icon: point.token ? propertyIcons[point.token.propertyType] : customIcon });
			if (settingsMap.filterByRentedType) marker = L.marker(point.position, { icon: point.token ? rentedIcons[point.token.rentedType] : customIcon });
			if (settingsMap.filterByNewsType) marker = L.marker(point.position, { icon: point.token ? newsIcons[point.token.newsType] : customIcon });
			// const popupContent = PopupContent(point.token,DEFAULT_PROPERTY_TYPE)

			const popupContent = document.createElement("div");
			// marker.bindPopup(popupContent);

			// ReactDOM.createRoot(popupContent).render(
			// 	<PopupPortal>
			// 		<PopupContent key={point.id} token={point.token} property_type={DEFAULT_PROPERTY_TYPE} />
			// 	</PopupPortal>
			// );

			const root = ReactDOM.createRoot(popupContent);
			root.render(
				<>
					<PopupContent
						key={point.id}
						token={point.token}
						property_type={DEFAULT_PROPERTY_TYPE}
						setOpenInfo={setOpenInfo}
						setOpenNews={setOpenNews}
					/>
				</>
			); // Use key for unique rendering
			// ReactDOM.render(<PopupContent key={point.id} token={point.token} property_type={DEFAULT_PROPERTY_TYPE} />, popupContent);
			marker.bindPopup(popupContent);
			markers.addLayer(marker);

			marker.on("popupopen", function () {
				// requestAnimationFrame(() => {
				setTimeout(() => {
					const popupTip = document.querySelector(".leaflet-popup-tip");
					if (popupTip) {
						popupTip.style.display = "none"; // Cache la flèche
					}

					const popupWrapper = document.querySelector(".leaflet-popup-content-wrapper");
					if (popupWrapper) {
						// Supprime les contraintes par défaut
						popupWrapper.style.maxWidth = "none";
						popupWrapper.style.width = "auto";
						// popupWrapper.style.minWidth = "450px"; // Largeur minimale si nécessaire
					}

					const popupContent = document.querySelector(".leaflet-popup-content");
					if (popupContent) {
						// Ajuste la largeur du contenu pour qu'il prenne la taille nécessaire
						popupContent.style.width = "auto";
						popupContent.style.minWidth = minWidth; // Largeur minimale si nécessaire
					}
				}, 200); // Utilisez un délai court pour attendre que le popup soit complètement rendu
				// });
			});

			// Ajouter un événement `dblclick` pour zoomer sur le marqueur
			marker.on("dblclick", function () {
				map.setView(marker.getLatLng(), 14, { animate: true });
				settingsMapChange("searchFullName", null);
			});
		});

		map.addLayer(markers);

		return () => {
			map.removeLayer(markers);
		};
	}, [map, points]); // Make sure to add all necessary dependencies

	return null;
};

// React component for popup content using MUI components
const PopupContent = ({ token, property_type, setOpenInfo, setOpenNews }) => {
	if (!token) return <></>;

	// Compute or modify initial values here if necessary
	const formattedPrice = parseFloat(token?.tokenPrice || 0).toFixed(2);
	const formattedYield = parseFloat(token?.annualPercentageYield || 0).toFixed(2);
	const formattedRent = (parseFloat(token.amount)* parseFloat(token?.netRentYearPerToken || 0)).toFixed(2);
	const rentedUnitsPercentage = parseFloat((100 * token.rentedUnits) / token.totalUnits).toFixed(0);
	const propertyType = property_type ? property_type.find((type) => type.index === token.propertyType) : "Unknown";
	const rentSartDate = token ? token.rentStartDate.date.slice(0, 10) : "Unknown";
	const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${token.coordinate.lat},${token.coordinate.lng}`;
	// console.log("token", token.coordinate,streetViewUrl);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					width: "auto", // Ajuste la largeur au contenu
					minWidth: minWidth, // Largeur minimale
					maxWidth: "none", // Pas de limite de largeur
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box
						component='img'
						alt='imgToken'
						src={token.imageLink[0]}
						sx={{ mx: 1, width: imgSize, height: imgSize, cursor: "pointer" }}
						onClick={() => window.open(streetViewUrl, "_blank")}
					/>
				</Box>
				<Box sx={{ mx: 0 }}>
					<Box sx={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
						<Box
							sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
							onClick={() => window.open(token.marketplaceLink, "_blank")}
						>
							<OpenInNewIcon />
						</Box>

						<Typography
							variant='h4'
							sx={{ ml: 1, fontWeight: "bold", fontSize: "1.5em" }}
							onClick={() => window.open(token.marketplaceLink, "_blank")}
						>
							{token.shortName}
						</Typography>

						<Box
							component='img'
							alt='imgToken'
							src={"./images/maps/streetview.png"}
							sx={{ ml: "auto", width: 20, height: 20 }}
							onClick={() => window.open(streetViewUrl, "_blank")}
						/>

						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} onClick={() => setOpenInfo(token)}>
							<AddchartOutlinedIcon />
						</Box>

						{token.update7.length > 0 ? (
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} onClick={() => setOpenNews(token)}>
															<AnnouncementIcon color='primary' />
							</Box>
						) : (
							token.update30.length > 0 && (
								<Box
									sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
									onClick={() => setOpenNews(token)}
								>							<AnnouncementIcon color='secondary' />
								</Box>
							)
						)}
					</Box>

					<Box sx={{ pt: 0.5, m: 0, display: "flex", flexDirection: "row" }}>
						<Typography variant='h4' sx={{ fontWeight: "normal", fontSize: "1.25em" }}>
							{token.fullName}
						</Typography>
					</Box>

					{token.amount > 0 && (
						<Box sx={{ pt: 0.25, m: 0, display: "flex", flexDirection: "row" }}>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontWeight: "normal", fontSize: "1.10em" }}>
								Holder position : {token.amount} / {token.totalTokens}
							</Typography>
						</Box>
					)}

					<Box sx={{ pt: 0.5, m: 0, display: "flex", flexDirection: "row" }}>
						<Box sx={{ m: 0 }}>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.0em" }}>
								Token Price : <strong>{formattedPrice}$</strong>
							</Typography>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.00em" }}>
								Token Yield : <strong>{formattedYield}%</strong>
							</Typography>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.00em" }}>
								type: <strong>{propertyType ? propertyType.label : "Unknown"}</strong>
							</Typography>
						</Box>
						<Box sx={{ ml: 1 }}>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.00em" }}>
								Rent start : <strong>{rentSartDate}</strong>
							</Typography>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.00em" }}>
								Rent/Year : <strong>{formattedRent}$</strong>
							</Typography>
							<Typography variant='h4' sx={{ fontStyle: "italic", fontSize: "1.00em" }}>
								Units rented :{" "}
								<strong>
									{token.rentedUnits}/{token.totalUnits}
								</strong>{" "}
								({rentedUnitsPercentage}%)
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

function VisitorsMapView() {
	const { balanceWallets, holderWallets } = useContext(SettingsContext);
	const { realTokens, settingsMap } = useContext(SettingsContext);
	const [points, setPoints] = useState([]);
	const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]);
	const [selectedTokenInfo, setSelectedTokenInfo] = useState(null);
	const [selectedTokenNews, setSelectedTokenNews] = useState(null);
	const [openInfo, setOpenInfo] = useState(false);
	const [openNews, setOpenNews] = useState(false);

	useEffect(() => {
		if (!realTokens) return;

		let tokens = [
			...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
			// ...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0),
		];

		tokens.forEach((token) => {
			token.amount = 0;
			if (holderWallets) {
				holderWallets
					.filter((holderWallet) => holderWallet.checked)
					.forEach((holderWallet) => {
						for (const wallet of Object.keys(balanceWallets)) {
							if (holderWallet.address === wallet) {
								for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
									if (chain === "eth") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList) {
											// console.log("token", chain, tokensList);
											tokensList.forEach((tokenList) => {
												if (token.ethereumContract === tokenList.token.address.toLowerCase()) {
													token.amount += parseFloat(tokenList.amount);
													// console.log("token eth" , token.shortName, token.amount);
												}
											});
										}
									}

									if (chain === "xdai") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList) {
											// console.log("token", chain, tokensList);
											tokensList.forEach((tokenList) => {
												if (token.gnosisContract === tokenList.token.address.toLowerCase()) {
													token.amount += parseFloat(tokenList.amount);
													// console.log("token xdai", token.shortName, token.amount);
												}
											});
										}
									}

									if (chain === "rmm") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList) {
											// console.log("token", chain, tokensList);
											tokensList.forEach((tokenList) => {
												if (token.gnosisContract === tokenList.reserve.underlyingAsset.toLowerCase()) {
													token.amount += parseFloat(tokenList.currentATokenBalance / 10 ** tokenList.reserve.decimals);
													// console.log("token rmm", token.shortName, token.amount);
												}
											});
										}
									}

									if (chain === "rmmv3") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList) {
											// console.log("token", chain, tokensList);
											tokensList.forEach((tokenList) => {
												if (token.gnosisContract === tokenList.token.id.toLowerCase()) {
													token.amount += parseFloat(tokenList.amount / 10 ** tokenList.token.decimals);
													// console.log("token rmmv3", token.shortName, token.amount);
												}
											});
										}
									}

									if (chain === "pool") {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList) {
											// console.log("token", chain, tokensList);
											tokensList.forEach((tokenList) => {
												if (token.gnosisContract === tokenList.pair.token0.id) {
													const liquidityTokenBalance = parseFloat(tokenList.liquidityTokenBalance);
													const totalSupply = parseFloat(tokenList.pair.totalSupply);
													const liquidity = parseFloat(tokenList.pair.token0.liquidity);
													token.amount += parseFloat((liquidityTokenBalance * liquidity) / totalSupply);
													// console.log("token0 pool", token.shortName, token.amount);
												}
												if (token.gnosisContract === tokenList.pair.token1.id) {
													const liquidityTokenBalance = parseFloat(tokenList.liquidityTokenBalance);
													const totalSupply = parseFloat(tokenList.pair.totalSupply);
													const liquidity = parseFloat(tokenList.pair.token1.liquidity);
													token.amount += parseFloat((liquidityTokenBalance * liquidity) / totalSupply);
													// console.log("token1 pool", token.shortName, token.amount);
												}
											});
										}
									}
								}
							}
						}
					});
			}
			const rentStartDate = new Date(token.rentStartDate.date);
			// console.log(rentStartDate.getTime(),Date.now())
			token.rentedType = 3;
			if (token.rentedUnits / token.totalUnits === 0) token.rentedType = 1;
			if (token.rentedUnits / token.totalUnits > 0 && token.rentedUnits / token.totalUnits < 1) token.rentedType = 2;
			if (Date.now()< rentStartDate.getTime()) token.rentedType = 1;
			token.ownedType = 3;
			if (token.amount >= 1) token.ownedType = 1;
			else if (token.amount > 0) token.ownedType = 2;
			token.newsType = 3;
			if (token.update7.length > 0) token.newsType = 1;
			else if (token.update30.length > 0) token.newsType = 2;
		});

		if (settingsMap.selectedPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === settingsMap.selectedPropertyType);
		if (settingsMap.selectedRentedType > 0) tokens = tokens.filter((token) => token.rentedType === settingsMap.selectedRentedType);
		if (settingsMap.selectedOwnedType > 0) tokens = tokens.filter((token) => token.ownedType === settingsMap.selectedOwnedType);
		if (settingsMap.selectedNewsType > 0) tokens = tokens.filter((token) => token.newsType === settingsMap.selectedNewsType);
		if (settingsMap.searchFullName) tokens = tokens.filter((data) => data.fullName.toLowerCase().includes(settingsMap.searchFullName.toLowerCase()));

		// console.log("tokens", tokens[0]);

		// Extraction des points GPS (name, lat, lng)
		const extractedPoints = tokens.map((token) => ({
			id: token.id,
			name: token.shortName,
			position: [parseFloat(token.coordinate.lat), parseFloat(token.coordinate.lng)], // Conversion des coordonnées
			token: token,
		}));

		extractedPoints.sort((a, b) => {
			const aa = a.token.initialLaunchDate;
			const bb = b.token.initialLaunchDate;
			return new Date(aa.date) - new Date(bb.date);
		});

		// Mettre à jour l'état avec les points extraits
		setPoints(extractedPoints);

		// Centrer la carte sur le premier point s'il existe
		if (extractedPoints.length > 0) setMapCenter(extractedPoints[0].position);
	}, [realTokens, settingsMap]);

	const handleSetSelectedTokenInfo = (token) => {
		// console.log("settingsMapChange",item, newValue)
		setSelectedTokenInfo(token);
		setOpenInfo(true);
	};

	const handleSetSelectedTokenNews = (token) => {
		// console.log("settingsMapChange",item, newValue)
		setSelectedTokenNews(token);
		setOpenNews(true);
	};

	return (
		<>
			{openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={selectedTokenInfo} />}
			{openNews && <TokenNews open={openNews} setOpen={setOpenNews} token={selectedTokenNews} chains={null} />}
			{points.length === 0 ? (
				<Box sx={{ textAlign: "center", marginTop: 4 }}>
					<Typography variant='h4' color='textSecondary'>
						No results found with the current filters !
					</Typography>
					<Typography variant='h5' color='textSecondary' sx={{ mt: 5 }}>
						Change your filter to see the map again ...
					</Typography>
				</Box>
			) : (
				<>
					<MapContainer center={mapCenter} zoom={3} style={{ height: "100%", width: "100%" }}>
						{/* TileLayer to display the map */}
						<TileLayer
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>

						{/* Marker Cluster Group Component to group markers dynamically based on zoom level */}
						<MarkerClusterGroupComponent points={points} setOpenInfo={handleSetSelectedTokenInfo} setOpenNews={handleSetSelectedTokenNews} />
					</MapContainer>
				</>
			)}
		</>
	);
}

export default VisitorsMapView;
