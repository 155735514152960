import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Box, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { green, red } from "@mui/material/colors";

import SelectHolderWallet from "./SelectHolderWallet";
import WidgetWallets from "./WidgetWallets";
import WidgetRealTokens from "./WidgetRealTokens";
import WidgetRents from "./WidgetRents";
import WidgetProperties from "./WidgetProperties";
import WidgetNextRonday from "./WidgetNextRonday";
import PiePropertyType from "./PiePropertyType";
import PiePropertyType2 from "../../Stats/RealTokensChart/PiePropertyType";
import BarPropertyYield from "./BarPropertyYield";
import TableTokens from "./TableTokens";
import TableHistory from "./TableHistory";
import PiePropertyDiversity from "./PiePropertyDiversity";
import WidgetInsight from "./WidgetInsight";
import WidgetRentCollected from "./WidgetRentCollected";

function NavigationButtons() {
	const { settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
	const settings = { ...settingsDashboard };
	// const [isExpandedHighlight, setIsExpandedHighlight] = useState(true);
	// const [isExpandedHistory, setIsExpandedHistory] = useState(false);
	// const [isExpandedTokens, setIsExpandedTokens] = useState(false);

	const toggleExpandHighlight = () => {
		settings.isExpandedHighlight = !settings.isExpandedHighlight;
		setSettingsDashboard(settings);
	};

	const toggleExpandHistory = () => {
		settings.isExpandedTokens = false;
		settings.isExpandedHistory = !settings.isExpandedHistory;
		setSettingsDashboard(settings);
	};

	const toggleExpandTokens = () => {
		settings.isExpandedHistory = false;
		settings.isExpandedTokens = !settings.isExpandedTokens;
		setSettingsDashboard(settings);
	};

	// console.log("TableTokens - tokensBalance", settings);

	return (
		<Box
			sx={{ width: 1, m: 0, border: 0, borderColor: red[700], borderRadius: 4, display: "flex", flexDirection: "column", justifyContent: "center" }}
		>
			<SelectHolderWallet />

			<Box sx={{ width: 1, m: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
				{/* // Button Highlight */}
				<Box sx={{ mx: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
					{settings.isExpandedHighlight && (
						<Box sx={{ width: 0.25, zIndex: 11, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
							<Button sx={{ m: 1, p: 1, width: 0.95 }} onClick={toggleExpandHighlight} variant={"contained"} startIcon={<RemoveCircleOutlineIcon />}>
								Dashboard Highlight
							</Button>
						</Box>
					)}
				</Box>

				{/* // Context Highlight */}
				<Box sx={{ mx: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
					{settings.isExpandedHighlight && (
						<>
							<Box
								sx={{
									flexWrap: "wrap",
									width: 1,
									mt: 1,
									pl: 1,
									pr: 1,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<WidgetWallets />
								<WidgetRealTokens />
								<WidgetRents />
								<WidgetNextRonday />
								<WidgetProperties />
								{/* <WidgetRentCollected /> */}
							</Box>
							<Box
								sx={{
									flexWrap: "wrap",
									width: 1,
									mt: 1,
									px: 1,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<PiePropertyType />
								<PiePropertyDiversity />
								<BarPropertyYield />
							</Box>
							<Box
								sx={{
									flexWrap: "wrap",
									width: 1,
									mt: 1,
									px: 1,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<WidgetRentCollected />
								{/* <WidgetInsight /> */}
							</Box>
						</>
					)}
				</Box>
			</Box>
			<Box sx={{ width: 1, m: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
				{/* // Button History */}
				<Box sx={{ mx: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
					{/* // Button History */}
					<Box sx={{ width: 0.25, zIndex: 11, m: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
						<Button
							sx={{ m: 1, p: 1, width: 0.95 }}
							onClick={toggleExpandHistory}
							variant={settings.isExpandedHistory ? "contained" : "text"}
							startIcon={settings.isExpandedHistory ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
						>
							Last move
						</Button>
					</Box>

					{/* // Button Highlight */}
					{!settings.isExpandedHighlight && (
						<Box sx={{ width: 0.25, zIndex: 11, m: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
							<Button sx={{ m: 1, p: 1, width: 0.95 }} onClick={toggleExpandHighlight} variant={"text"} startIcon={<AddCircleOutlineIcon />}>
								Dashboard Highlight
							</Button>
						</Box>
					)}

					{/* // Button Tokens */}
					<Box sx={{ width: 0.25, zIndex: 11, m: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
						<Button
							sx={{ m: 1, p: 1, width: 0.95 }}
							onClick={toggleExpandTokens}
							variant={settings.isExpandedTokens ? "contained" : "text"}
							startIcon={settings.isExpandedTokens ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
						>
							Wallets realTokens
						</Button>
					</Box>
				</Box>

				{/* // Context History & Tokens*/}
				<Box sx={{ mx: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
					{settings.isExpandedHistory && (
						<>
							<Box sx={{ flexWrap: "wrap", m: 0, display: "flex", alignItems: "top", justifyContent: "center" }}>
								<TableHistory />
							</Box>
						</>
					)}
					{settings.isExpandedTokens && (
						<>
							<Box sx={{ flexWrap: "wrap", m: 0, display: "flex", alignItems: "top", justifyContent: "center" }}>
								<TableTokens />
							</Box>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
}

export default NavigationButtons;
