import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../context/settings-context";
import { SnackbarContext } from "../../context/customsnackbar-context";
import { MyThemeContext } from "../../context/theme-context";
import { Web3Context } from "../../context/web3-context";

import { Badge, Box, Paper, Typography, useMediaQuery, CircularProgress } from "@mui/material";
import { green, red, pruple } from "@mui/material/colors";

// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
// import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import SalesOffersHead from "./SalesOffers/SalesOffersHead";
import WalletSalesOffersHead from "./PurchasesOffers/WalletPurchasesOffersHead"; // import TokensTableHead from "./PurchasesOffers/TokensTableHead";
import SalesPersoOffersHead from "./SalesOffers/SalesPersoOffersHead";

function SalesOffers() {
	const { web3active, web3account } = useContext(Web3Context);
	const { realTokens, holderWallets, balanceWallets, blockchainsClient, offersMarket, setOffersMarket } = useContext(SettingsContext);
	const { settingsMarket, setSettingsMarket, settingsWallet } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const [tokens, setTokens] = useState(null);
	const [sortedOffers, setSortedOffers] = useState(null);
	const [sortedWalletOffers, setSortedWalletOffers] = useState(null);
	const [sortedWalletOffersCount, setSortedWalletOffersCount] = useState(0);
	const [offers, setOffers] = useState(null);
	const [persoOffers, setPersoOffers] = useState([]);
	const [cityList, setCityList] = useState(null);
	const [coinsList, setCoinsList] = useState(null);

	const [allOffers, setAllOffers] = useState(true);
	const [walletOffers, setWalletOffers] = useState(false);
	const [settingOffers, setSettingOffers] = useState(false);
	const [helpOffers, setHelpOffers] = useState(false);
	const [shopping, setShopping] = useState(false);

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	useEffect(() => {
		if (realTokens) {
			let cptTokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0), //&& data.productType !== "equity_token"),
			];

			if (balanceWallets) {
				const testContractxDai = (token, address) => {
					if (token.gnosisContract === address || token.xDaiContract === address) return true;
					else return false;
				};
				const testContractEth = (token, address) => {
					if (token.ethereumContract === address) return true;
					else return false;
				};

				cptTokens.forEach((token) => {
					token.walletsAmount = 0;
					token.walletAmount = 0;
					token.whiteList = false;
					// token.strRentStartDate = token.rentStartDate.date.slice(0, 10);
					if (holderWallets) {
						if (holderWallets.filter((holderWallet) => holderWallet.checked)[0]) {
							holderWallets
								.filter((holderWallet) => holderWallet.checked)
								.forEach((holderWallet) => {
									if (
										balanceWallets &&
										balanceWallets[holderWallet?.address]?.tokens?.["xdai"]?.tokens?.length > 0 &&
										typeof token.walletsAmount === "number"
									) {
										balanceWallets[holderWallet.address].tokens["xdai"].tokens
											.filter((t) => testContractxDai(token, t.token.id))
											.forEach((t) => {
												// console.log("token to find", t);
												token.walletsAmount += parseFloat(t.amount);
												// console.log("Add xdai wallets token", token.shortName, t, token.walletsAmount, holderWallet.address);
											});
									}

									if (
										balanceWallets &&
										balanceWallets[holderWallet?.address]?.tokens?.["eth"]?.tokens?.length > 0 &&
										typeof token.walletsAmount === "number"
									) {
										balanceWallets[holderWallet.address].tokens["eth"].tokens
											.filter((t) => testContractEth(token, t.token.id))
											.forEach((t) => {
												token.walletsAmount += parseFloat(t.amount);
											});
									}

									if (
										balanceWallets &&
										balanceWallets[holderWallet?.address]?.tokens?.["rmm"]?.tokens?.length > 0 &&
										typeof token.walletsAmount === "number"
									) {
										if (balanceWallets[holderWallet.address].tokens["rmm"].tokens) {
											balanceWallets[holderWallet.address].tokens["rmm"].tokens
												.filter((t) => testContractxDai(token, t.reserve.underlyingAsset))
												.forEach((t) => {
													token.walletsAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
												});
										}
									}

									if (
										balanceWallets &&
										balanceWallets[holderWallet?.address]?.tokens?.["rmmv3"]?.tokens?.length > 0 &&
										typeof token.walletsAmount === "number"
									) {
										if (balanceWallets[holderWallet.address].tokens["rmmv3"].tokens) {
											balanceWallets[holderWallet.address].tokens["rmmv3"].tokens
												.filter((t) => testContractxDai(token, t.token.id))
												.forEach((t) => {
													token.walletsAmount += parseFloat(t.amount / 10 ** t.token.decimals);
												});
										}
									}

									if (
										balanceWallets &&
										balanceWallets[holderWallet?.address]?.tokens?.["pool"]?.tokens?.length > 0 &&
										typeof token.walletsAmount === "number"
									) {
										if (balanceWallets[holderWallet.address].tokens["pool"].tokens) {
											balanceWallets[holderWallet.address].tokens["pool"].tokens
												.filter((t) => testContractxDai(token, t.pair.token0.id))
												.forEach((t) => {
													token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
													// console.log("Add pool0 wallets token", token.shortName, token.walletsAmount);
												});
											balanceWallets[holderWallet.address].tokens["pool"].tokens
												.filter((t) => testContractxDai(token, t.pair.token1.id))
												.forEach((t) => {
													token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
													// console.log("Add pool1 wallets token", token.shortName, token.walletsAmount);
												});
										}
									}

									if ("whiteList" in balanceWallets[holderWallet.address]) {
										if (balanceWallets[holderWallet.address].whiteList?.tokens) {
											balanceWallets[holderWallet.address].whiteList.tokens
												.filter((t) => testContractxDai(token, t))
												.forEach((t) => {
													token.whiteList = true;
												});
										}
									}
								});
						}
					}
				});
				setTokens(cptTokens);
			}
		}
	}, [realTokens, settingsWallet.selectedWallet]);

	useEffect(() => {
		if (!tokens) return;
		const testContractxDai = (token, address) => {
			if (token.gnosisContract === address || token.xDaiContract === address) return true;
			else return false;
		};
		let offers_swapcat = offersMarket?.SwapCatOffersList?.filter((offer) => offer.token_to_sell !== null && offer.token_to_pay !== null) || [];
		offers_swapcat = offers_swapcat.map((offer) => {
			return {
				...offer,
				sc: "swapcat",
				buy_holder_address: "0x0000000000000000000000000000000000000000",
				token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_sell))[0] : null,
			};
		});
		let offers_yam =
			offersMarket?.YamOffersList?.filter(
				(offer) => offer.token_to_sell !== null && offer.token_to_pay !== null // && offer.buy_holder_address === "0x0000000000000000000000000000000000000000"
			) || [];
		offers_yam = offers_yam.map((offer) => {
			return {
				...offer,
				sc: "yam",
				token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_sell))[0] : null,
			};
		});
		let offers = offers_swapcat.concat(offers_yam);
		// console.log("offers", offers);

		// console.table(offers.filter((offer) => offer.id_offer > 65400 && offer.id_offer < 65410));
		// ------------------------------------------------------------
		// -- Calculate and filter 'offers' for PourchasesOffersHead --
		// ------------------------------------------------------------
		{
			offers.forEach((offer) => {
				if (offer.token) {
					offer.shortName = offer.token.shortName;
					offer.fullName = offer.token.fullName;
					offer.city = offer.token.fullName.split(",").map((part) => part.trim())[1];
					offer.tokenPrice = offer.token.tokenPrice;
					offer.position = offer.token.walletsAmount;
					offer.marketplaceLink = offer.token.marketplaceLink;
					offer.rent_start = offer.token.rentStartDate.date.slice(0, 10);
					offer.annualPercentageYield = offer.token.annualPercentageYield;
					offer.whiteList = offer.token.whiteList;

					offer.token_value_usd = 0;
					offer.token_to_pay_img = "./images/unknown.png";
					offer.token_to_pay_name = "unknown ???";
					const tokenToPaid = Object.values(blockchainsClient[settingsWallet.selectedBlockchain].coinList).find(
						(coin) => coin.address.toLowerCase() === offer.token_to_pay.toLowerCase()
					);
					if (!tokenToPaid) {
						offer.token_to_pay = null;
						// console.log("tokenToPaid unKnown ?", offer);
					}
					if (tokenToPaid) {
						offer.token_to_pay_img = tokenToPaid.imgToken;
						offer.token_to_pay_name = tokenToPaid.name;
						offer.token_value_usd = offer.token_value * tokenToPaid.toUSD;
					}
					offer.token_ratio = (offer.token_value_usd / offer.tokenPrice) * 1;
					offer.token_newYield = (offer.annualPercentageYield * offer.tokenPrice) / 100 / offer.token_value_usd;
					if (offer.token.productType === "loan_income") {
						offer.token_newYield =
							((offer.annualPercentageYield * offer.tokenPrice) / 100 - (offer.token_value_usd - offer.tokenPrice)) / offer.token_value_usd;
					}

					offer.token_rented = offer.token.rentedUnits / offer.token.totalUnits;
					if (!offer.token_rented) offer.token_rented = 1;
					offer.last_pricing_days = -1;
					offer.nb_pricing = 0;
					if (offer.token.historic) {
						offer.nb_pricing = offer.token.historic.prices.length - 1;
						const prisingDate = offer.token.historic.prices[offer.token.historic.prices.length - 1].timsync;
						const prisingDateObj = new Date(prisingDate);
						const currentDate = new Date();
						const timeDifference = currentDate - prisingDateObj;
						const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
						offer.last_pricing_days = daysDifference;
						if (offer.last_pricing_days < 0) offer.last_pricing_days = -1;
					}
					if (offer.token.shortName === "RWA Holdings SA") offer.token.whiteList = true;
				}
			});
			if (web3account) {
				let persoOffers = offers.filter(
					(offer) => offer.token_to_sell !== null && offer.token_to_pay !== null && offer.buy_holder_address === web3account
				);
				setPersoOffers(persoOffers);
				// console.log("persoOffers", web3account[0].toLowerCase(), persoOffers);
			}

			// console.table(offers.filter((offer) => offer.id_offer > 65400 && offer.id_offer < 65410));

			offers = offers.filter((offer) => offer.token_to_pay !== null && offer.buy_holder_address === "0x0000000000000000000000000000000000000000");
			setOffers(offers);

			let sortedOffers = JSON.parse(JSON.stringify(offers));
			sortedOffers = sortedOffers.filter((offer) => offer.token_value_usd > 0);
			if (settingsMarket.SaleOffers.unselectedCity)
				sortedOffers = sortedOffers.filter((offer) => {
					const city = offer.fullName.split(",")[1].trim();
					return !settingsMarket.SaleOffers.unselectedCity.includes(city);
				});
			if (settingsMarket.SaleOffers.unselectedCoins)
				sortedOffers = sortedOffers.filter((offer) => {
					const coin = offer.token_to_pay_name;
					// console.log(coin,offer)
					return !settingsMarket.SaleOffers.unselectedCoins.includes(coin);
				});
			if (settingsMarket.SaleOffers && "unselectPropertyType" in settingsMarket.SaleOffers)
				sortedOffers = sortedOffers.filter((offer) => {
					const propertyType = offer.token.propertyType;
					return !settingsMarket.SaleOffers.unselectPropertyType.includes(propertyType);
				});
			if (settingsMarket.SaleOffers.isPosition)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.position >= settingsMarket.SaleOffers.minPosition && offer.position < settingsMarket.SaleOffers.maxPosition
				);
			if (settingsMarket.SaleOffers.isOfferAmount)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.token_amount >= settingsMarket.SaleOffers.minOfferAmount && offer.token_amount < settingsMarket.SaleOffers.maxOfferAmount
				);
			if (settingsMarket.SaleOffers.isRatioPrice)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_ratio * 100 >= settingsMarket.SaleOffers.minRatioPrice && offer.token_ratio * 100 < settingsMarket.SaleOffers.maxRatioPrice
				);
			if (settingsMarket.SaleOffers.isRealtPrice)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.tokenPrice >= settingsMarket.SaleOffers.minRealtPrice && offer.tokenPrice < settingsMarket.SaleOffers.maxRealtPrice
				);
			if (settingsMarket.SaleOffers.isRealtYield)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.annualPercentageYield >= settingsMarket.SaleOffers.minRealtYield &&
						offer.annualPercentageYield < settingsMarket.SaleOffers.maxRealtYield
				);
			if (settingsMarket.SaleOffers.isNewPrice)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.token_value_usd >= settingsMarket.SaleOffers.minNewPrice && offer.token_value_usd < settingsMarket.SaleOffers.maxNewPrice
				);
			if (settingsMarket.SaleOffers.isNewYield)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.token_newYield * 100 >= settingsMarket.SaleOffers.minNewYield && offer.token_newYield * 100 < settingsMarket.SaleOffers.maxNewYield
				);
			if (settingsMarket.SaleOffers.isRented)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.token_rented * 100 >= settingsMarket.SaleOffers.minRented && offer.token_rented * 100 < settingsMarket.SaleOffers.maxRented
				);
			if (settingsMarket.SaleOffers.isLastPricingDays)
				sortedOffers = sortedOffers.filter(
					(offer) =>
						offer.last_pricing_days >= settingsMarket.SaleOffers.minLastPricingDays &&
						offer.last_pricing_days < settingsMarket.SaleOffers.maxLastPricingDays
				);
			if (settingsMarket.SaleOffers.isNbPricing)
				sortedOffers = sortedOffers.filter(
					(offer) => offer.nb_pricing >= settingsMarket.SaleOffers.minNbPricing && offer.nb_pricing < settingsMarket.SaleOffers.maxNbPricing
				);
			if (settingsMarket.SaleOffers.isRentStart)
				sortedOffers = sortedOffers.filter((offer) => new Date(offer.rent_start).getTime() <= new Date().getTime());
			if (settingsMarket.SaleOffers.isnotRentStart) offers = offers.filter((offer) => new Date(offer.rent_start).getTime() >= new Date().getTime());
			if (settingsMarket.SaleOffers.isWhiteList) sortedOffers = sortedOffers.filter((offer) => offer.whiteList);
			setSortedOffers(sortedOffers);
		}
		// if (sortedOffers) console.table(sortedOffers.filter((offer) => offer.id_offer > 65400 && offer.id_offer < 65410));

		// ------------------------------------------------------------
		// -- Calculate and filter 'offers' for WalletPourchasesOffersHead --
		// ------------------------------------------------------------
		// console.log("offers", offers[0]);
		{
			let sortedWalletOffersCount = 0;
			let sortedWalletOffers = JSON.parse(JSON.stringify(tokens));
			{
				sortedWalletOffers.forEach((token) => {
					token.position = token.walletsAmount;
					token.rent_start = token.rentStartDate.date.slice(0, 10);
					token.offersList = offers.filter((offer) => offer.holder_address === settingsWallet.selectedWallet && offer.shortName === token.shortName);
					sortedWalletOffersCount += token.offersList.length;
					// if(token.offersList.length>0) console.log("token.offersList", token.shortName ,token.offersList);
				});
			}
			setSortedWalletOffersCount(sortedWalletOffersCount);
			setSortedWalletOffers(sortedWalletOffers);
		}
	}, [tokens, settingsMarket.SaleOffers, offersMarket.SwapCatOffersList, offersMarket.YamOffersList, web3account]);

	useEffect(() => {
		if (!tokens) return;
		const _cityList = tokens
			.reduce((acc, token) => {
				const parts = token.fullName.split(",").map((part) => part.trim());
				let city, countryLabel;

				if (parts.length === 3 || parts.length === 4) {
					city = parts[1];
					countryLabel = parts.length === 3 ? "USA" : parts[3]; // Determine the country label
				} else {
					console.log("Create CityList error: ", token.fullName);
					city = "Error";
					countryLabel = "Error";
				}
				let cityEntry = acc ? acc.find((entry) => entry.label === city) : null;
				if (!cityEntry) {
					cityEntry = { id: acc.length, count: 0, label: city, checked: true };
					acc.push(cityEntry);
				}
				cityEntry.count += 1;
				return acc;
			}, [])
			.sort((a, b) => {
				return a.label.localeCompare(b.label);
			});
		setCityList(_cityList);
		// console.log("cityList Calculate");
	}, [tokens]);

	// console.log(offers[0]);
	useEffect(() => {
		if (!offers) return;
		const _coinsList = offers
			.reduce((acc, offer) => {
				// if(offer.token_to_pay===)
				let coinEntry = acc ? acc.find((entry) => entry.id === offer.token_to_pay) : null;
				if (!coinEntry) {
					coinEntry = { id: offer.token_to_pay, count: 0, label: offer.token_to_pay_name, img: offer.token_to_pay_img, checked: true };
					acc.push(coinEntry);
				}
				coinEntry.count += 1;
				return acc;
			}, [])
			.sort((a, b) => {
				return b.count - a.count;
			});
		setCoinsList(_coinsList);
		// console.log("coinsList Calculate");
		// console.table(_coinsList);
	}, [offers]);

	if (!realTokens)
		return (
			<Paper key='realTokens' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!holderWallets)
		return (
			<Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
						No wallet define, go to Settings !
					</Typography>
				</Box>
			</Paper>
		);
	if (!holderWallets.length > 0)
		return (
			<Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
						No wallet define, go to Settings !
					</Typography>
				</Box>
			</Paper>
		);
	if (!balanceWallets)
		return (
			<Paper key='balanceWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!tokens)
		return (
			<Paper key='tokens' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!coinsList)
		return (
			<>
				<Paper key='coinsList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
					<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
						<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
							Waiting Offers synchronisation ... Or no wallet define !
						</Typography>
					</Box>
				</Paper>
			</>
		);
	if (!cityList)
		return (
			<Paper key='cityList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!("SaleOffers" in settingsMarket))
		return (
			<Paper key='settingsMarket' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!sortedOffers)
		return (
			<Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!sortedWalletOffers)
		return (
			<Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!offersMarket.SwapCatOffersList)
		return (
			<Paper key='offersMarket.SwapCatOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);
	if (!offersMarket.YamOffersList)
		return (
			<Paper key='offersMarket.YamOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ my: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					{/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting Offers synchronisation ... Or no wallet define !
					</Typography>
				</Box>
			</Paper>
		);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<Box
				sx={{
					width: "auto",
					// flexGrow: 0.1,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 2,
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					alignItems: "center",
				}}
			>
				<StorefrontIcon
					color={allOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 0, fontSize: allOffers ? 40 : 25, cursor: allOffers ? "default" : "pointer" }}
					onClick={() => {
						setAllOffers(true);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(false);
					}}
				/>

				<Badge
					sx={{ ml: 1, mt: 2, fontSize: walletOffers ? 40 : 25, cursor: shopping ? "default" : "pointer" }}
					badgeContent={sortedWalletOffersCount}
					color='success'
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(true);
						setHelpOffers(false);
						setShopping(false);
					}}
				>
					<AccountBalanceWalletOutlinedIcon
						color={walletOffers ? "primary" : "disabled"}
						sx={{ ml: 0, mt: 0, fontSize: walletOffers ? 40 : 25, cursor: walletOffers ? "default" : "pointer" }}
						onClick={() => {
							setAllOffers(false);
							setWalletOffers(true);
							setHelpOffers(false);
							setShopping(false);
						}}
					/>
				</Badge>

				<InfoOutlinedIcon
					color={helpOffers ? "primary" : "disabled"}
					sx={{ ml: 1, mt: 2, fontSize: helpOffers ? 40 : 25, cursor: helpOffers ? "default" : "pointer" }}
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(true);
						setShopping(false);
					}}
				/>

				<Badge
					sx={{ ml: 1, mt: 5, cursor: shopping ? "default" : "pointer" }}
					badgeContent={0}
					color='success'
					onClick={() => {
						setAllOffers(false);
						setWalletOffers(false);
						setHelpOffers(false);
						setShopping(true);
					}}
				>
					<ShoppingCartIcon
						color={shopping ? "primary" : "disabled"}
						sx={{ fontSize: shopping ? 40 : 25, cursor: shopping ? "default" : "pointer" }}
						onClick={() => {
							setAllOffers(false);
							setWalletOffers(false);
							setHelpOffers(false);
							setShopping(true);
						}}
					/>
				</Badge>
			</Box>

			{allOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					{web3active && persoOffers.length > 0 && (
						<SalesPersoOffersHead key='SalesPersoOffersHead' offers={persoOffers} cityList={cityList} coinsList={coinsList} />
					)}

					<SalesOffersHead key='SalesOffersHead' offers={sortedOffers} cityList={cityList} coinsList={coinsList} />
				</Box>
			)}

			{walletOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					WalletSalesOffers SOON !!! <br />
					{/* <WalletSalesOffersHead key='WalletSalesOffersHead' offers={sortedWalletOffers} cityList={cityList} coinsList={coinsList} /> */}
				</Box>
			)}

			{helpOffers && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					helpOffers SOON !!! <br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}

			{shopping && (
				<Box
					sx={{
						flexGrow: 1,
						mx: 1,
						pr: isMatch ? 2 : 0,
						border: 0,
						borderColor: "primary.main",
						borderRadius: 2,
					}}
				>
					Shopping, It's an idea for future ... <br />
					{/* <TokensTableHead tokens={tokens} /> */}
				</Box>
			)}
		</Box>
	);
}

export default SalesOffers;
