import React, { useContext } from "react";
import { SettingsContext } from "../../../context/settings-context";

import { Avatar, Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";

function CoinHistory({ coin, archive }) {
	const { settingsWallet, blockchainsClient, setBlockchainsClient } = useContext(SettingsContext);

	const filterArchive = {};
	for (const key in archive) {
		if (!isNaN(parseInt(key))) {
			filterArchive[key] = archive[key];
		}
	}

	let blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
	if (!blockchainCoin) return <></>;

	const priceHistory = Object.keys(filterArchive).map((x) => ({
		timestamp: parseInt(x),
		balance: archive[x].balance,
		usd: archive[x].balance * archive[x].usd,
	}));

	if (blockchainCoin.history === null) {
		setBlockchainsClient((prevSettings) => ({
			...prevSettings,
			history: false,
		}));
		blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
	}
	if (blockchainCoin.usd === null) {
		setBlockchainsClient((prevSettings) => ({
			...prevSettings,
			usd: false,
		}));
		blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
	}

	// console.log("CoinHistory", coin, blockchainCoin.history,blockchainCoin.usd,blockchainCoin, );
	if (!blockchainCoin.history && !blockchainCoin.usd) return <></>;

	const yieldFormatter = (date, balanceOrYield) => {
		return `${date.toLocaleDateString()} - Value: ${balanceOrYield.toFixed(2)}`;
	};
	const maxBalances = blockchainCoin.usd ? Math.max(...priceHistory.map((x) => x.balance)) : Math.max(...priceHistory.map((x) => x.balance));
	const times = priceHistory.map((x) => new Date(x.timestamp));
	const balances = priceHistory.map((x) => parseFloat(x.balance));
	const usdValues = blockchainCoin.usd ? priceHistory.map((x) => parseFloat(x.usd)) : [];
	// const balances = blockchainCoin.usd ? priceHistory.map((x) => parseFloat(x.usd)) : priceHistory.map((x) => parseFloat(x.balance));

	// console.log("balances", coin, balances);

	const configBalanceLineChart = {
		series: [
			// { data: balances, label: blockchainCoin.usd ? "Balance (in $)" : "Balance", showMark: false }],
			{ data: balances, label: "Balance", showMark: false },
			...(blockchainCoin.usd ? [{ data: usdValues, label: "Balance (in $)", showMark: false }] : []),
		],
		height: 250,
	};
	const xAxisBalanceLineChart = {
		data: times,
		scaleType: "time",
	};

	const yieldsAndTimestamp = priceHistory
		.map((x, index) => {
			if (index > 0) {
				const deltaTime = x.timestamp - priceHistory[index - 1].timestamp;
				const deltaBalance = x.balance - priceHistory[index - 1].balance;
				const yieldFullTime = 1000 * 60 * 60 * 24 * 365;
				const value = parseFloat((100 * ((deltaBalance * yieldFullTime) / deltaTime)) / priceHistory[index - 1].balance);
				return { id: index, timestamp: new Date(x.timestamp), yield: value };
			} else return { id: index, timestamp: new Date(x.timestamp), yield: 0 };
		})
		.filter((x) => x.yield > 0)
		.filter((x) => x.yield < 50);
	const yieldstimes = yieldsAndTimestamp.map((x) => x.timestamp);
	const yields = yieldsAndTimestamp.map((x) => x.yield);
	const avgYield = yieldsAndTimestamp.length > 0 ? yieldsAndTimestamp.reduce((acc, x) => acc + x.yield, 0) / yieldsAndTimestamp.length : 0;
	const avgYields = yieldsAndTimestamp.map((x) => avgYield);

	// console.log("balances", coin, balances);
	// console.log("yieldsAndTimestamp", coin, yields, avgYields, yieldsAndTimestamp);

	const configYieldLineChart = {
		series: [
			{ data: yields, label: "Yield (in %)", showMark: false },
			{ data: avgYields, label: "Average Yield (in %)", showMark: false, color: "pink" },
		],
		height: 250,
	};
	const xAxisYieldLineChart = {
		data: yieldstimes,
		scaleType: "time",
	};

	if (blockchainCoin.history && (!maxBalances > 0 || balances.length < 4))
		return (
			<>
				{/* <Box sx={{ mx: 1, p: 0, minWidth: 250, border: 1, borderColor: "secondary.main", borderRadius: 4 }}> */}
				{/* <Box sx={{ flexWrap: "flex", m: 1, p: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<Avatar alt={blockchainCoin.name} src={blockchainCoin.imgToken} fontSize='small' sx={{ width: 50, height: 50, m: 0 }} />

					<Typography variant='h5' sx={{ my: 1, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
						{blockchainCoin.name}
					</Typography>
					<Typography variant='h5' sx={{ my: 1, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
						Token not used on RMM
					</Typography>
				</Box> */}
				{/* </Box> */}
			</>
		);

	return (
		<Box sx={{ m: 1, p: 0, minWidth: 750, border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box
				sx={{
					flexWrap: "wrap",
					mx: 1,
					p: 1,
					border: 0,
					borderColor: "primary.main",
					borderRadius: 4,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Avatar alt={blockchainCoin.name} src={blockchainCoin.imgToken} fontSize='small' sx={{ width: 50, height: 50, m: 0 }} />

				<Typography variant='h5' sx={{ my: 1, ml: 5, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
					{blockchainCoin.name}
				</Typography>
			</Box>
			{maxBalances > 0 && (
				<Box sx={{ minWidth: 500 }}>
					<LineChart
						// tooltip={{ formatter: balanceFormatter }}
						xAxis={[
							{
								...xAxisBalanceLineChart,
								tickMinStep: 3600 * 1000 * 24, // min step: 24h
							},
						]}
						{...configBalanceLineChart}
					/>
				</Box>
			)}
			{yields.length > 0 && (
				<Box sx={{ minWidth: 500 }}>
					<LineChart
						// tooltip={{ formatter: yieldFormatter }}
						xAxis={[
							{
								...xAxisYieldLineChart,
								tickMinStep: 3600 * 1000 * 24, // min step: 24h
							},
						]}
						{...configYieldLineChart}
					/>
				</Box>
			)}
		</Box>
	);
}

export default CoinHistory;
