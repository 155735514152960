import React from "react";
import { Box,Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

const DrawerComponent = (props) => {
	return (
		<Box>
			<Drawer key={"SettingsDrawerComponent"} open={props.openDrawer} anchor="right" onClose={() => props.setOpenDrawer(false)}>
				<List>
					{props.tabs.map((link, index) => (
              <React.Fragment key={link.url}>
							<ListItemButton  onClick={() => props.setOpenDrawer(false)} >
								<ListItemIcon onClick={() => props.handleTabClick(index)}>
									{link.icon ? link.icon:""}
								<ListItemText>
										<Typography variant='h4' color='inherit' sx={{ ml: 1 }}>
											{link.name}
										</Typography>
									</ListItemText>
								</ListItemIcon>
							</ListItemButton>
							<Divider />
						</React.Fragment>
					))}
				</List>
			</Drawer>
		</Box>
	);
};

export default DrawerComponent;
