import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../context/settings-context";
// import { Link } from "react-router-dom";
import { MyThemeContext } from "../context/theme-context";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Typography, IconButton, Tabs, Tab, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TokenIcon from "@mui/icons-material/Token";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

import DrawerComponent from "../components/Settings/DrawerComponent";
import Header from "../components/Header/Header";
import WebPages from "../components/Settings/WebPages";
import Wallets from "../components/Settings/Wallets";
import Common from "../components/Settings/Common";
import LoadingWebSite from "../components/Common/LoadingWebSite";

const tabs = [
	{ id: 0, name: "Common", component: <Common />, icon: <DisplaySettingsIcon sx={{ width: 45, height: 45 }} /> },
	{ id: 1, name: "Web Pages", component: <WebPages />, icon: <TokenIcon sx={{ width: 45, height: 45 }} /> },
	{ id: 2, name: "Wallets", component: <Wallets />, icon: <AccountBalanceWalletIcon sx={{ width: 45, height: 45 }} /> },
];

const TITLE = "Pit's BI - Settings";

function Settings() {
	const { settingsLoaded } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	
  useEffect(() => {
    document.title = TITLE;
 }, []);

	useEffect(() => {
		// Lire le tabValue depuis l'URL et le définir
		const params = new URLSearchParams(location.search);
		const tabFromUrl = params.get("tab");
		if (tabFromUrl !== null) {
			setTabValue(parseInt(tabFromUrl, 10));
		}
	}, [location.search]);

	const handleTabClick = (index) => {
		setTabValue(index);
		navigate(`/settings?tab=${index}`); // Mettre à jour l'URL avec le nouveau tabValue
	};

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));
	return (
		<Box>
			<Header />
			<DrawerComponent   key="SettingsDrawer" openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} handleTabClick={handleTabClick} tabs={tabs} />
			{!settingsLoaded && <LoadingWebSite />}
			{settingsLoaded && (
				<Box>
					<Box sx={{ width: 1, height: "64px" }} display={"flex"} justifyContent='center' alignItems={"center"}>
						{isMatch && (
							<IconButton color='inherit' onClick={() => setOpenDrawer(true)}>
								<MenuIcon />
								<Typography variant='h6' color='inherit' sx={{ ml: 1 }}>
									{tabs[tabValue].name}
								</Typography>
							</IconButton>
						)}
						{!isMatch && (
							<Tabs key="SettingsTabs"  value={tabValue} variant='fullWidth' textColor='inherit' indicatorColor='secondary' sx={{ width: 1, ml: "auto" }}>
								{tabs.map((tab, index) => (
										<Tab key={tab.id} tabIndex={index} label={tab.name} value={index} onClick={() => handleTabClick(index)} />
								))}
							</Tabs>
						)}
					</Box>
					<Box>{tabs.filter((tab, index) => index === tabValue).map((tab, index) => tab.component)}</Box>
				</Box>
			)}
		</Box>
	);
}

export default Settings;
