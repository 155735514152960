import React from "react";
import { Box } from "@mui/material";
import SettingsStats from "./WebPages/SettingsStats";

function WebPages() {
	return (
		<Box  sx={{ p: 5, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
			<SettingsStats />
		</Box>
	);
}

export default WebPages;
