import React, { useState } from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import Holders from "./Wallet/Holders";
import Follows from "./Wallet/Follows";
import SettingsBlockchain from "./Wallet/SettingsBlockchain";

function Wallets() {
	return (
		<Box sx={{ p: 1, flexWrap: "wrap", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<Holders />
			{/* <Follows /> */}
			<SettingsBlockchain />
		</Box>
	);
}

export default Wallets;
