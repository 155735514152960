import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";

import { Box, CircularProgress, Typography } from "@mui/material";

const FormatedCircleProgress = ({ value, maxValue, fontWeight = "normal", color = "primary", colorRefresh = "secondary" }) => {
	const theme = useTheme();
	const ratioTime = 100 - (100 * (value + maxValue - Date.now())) / maxValue;

	// console.log("FormatedCircleProgress", ratioTime, value, maxValue);

	if (ratioTime)
		if (ratioTime < 100)
			return (
				<Box
					sx={{
						ml: 1,
						p: 0,
						position: "relative", // Le conteneur a une position relative
						display: "inline-flex", // Le conteneur est inline-flex pour centrer les enfants
						alignItems: "center", // Centrer les enfants verticalement
						justifyContent: "center", // Centrer les enfants horizontalement
					}}
				>
					<CircularProgress sx={{ p: 0, position: "absolute", color:  theme.palette.mode === "dark" ?"grey.800":"grey.200" }} size='1.3rem' variant='determinate' value={100} />
					<CircularProgress sx={{ p: 0 }} size='1.3rem' variant='determinate' value={ratioTime} color={color} />
					<Typography
						sx={{
							position: "absolute", // Positionner le texte absolument pour le superposer
							fontSize: "0.7rem",
							color: "primary.main",
							top: "50%", // Centrer par rapport au haut du conteneur
							left: "50%", // Centrer par rapport à la gauche du conteneur
							transform: "translate(-50%, -50%)", // Décaler le texte pour un centrage parfait
							fontWeight: fontWeight,
						}}
					>
						{parseInt(ratioTime)}
					</Typography>
				</Box>
			);
		else return <CircularProgress sx={{ ml: 2, p: 0 }} size='1rem' color={colorRefresh} />;
	else return <></>;
};

FormatedCircleProgress.propTypes = {
	value: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired,
	fontWeight: PropTypes.string,
	color: PropTypes.node,
	colorRefresh: PropTypes.string,
};

export default FormatedCircleProgress;
